import React, { useEffect, useState } from "react";
import axios from "axios";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
// import { Navigate } from "react-router-dom";
const { Modal, ProgressBar } = require("react-bootstrap");

const CHUNK_SIZE = 1024 * 1024;

const UploadingFiles = ({
  files = [],
  show = false,
  setShow,
  parentId = null,
  app_label = null,
  model_name = null,
}) => {
  const [uploadProgress, setUploadProgress] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (
      files.length > 0 &&
      parentId != null &&
      app_label != null &&
      model_name != null
    ) {
      handleUpload();
    }
  }, [parentId, app_label, model_name, files]);

  const handleUpload = async () => {
    for (let file of files) {
      const fileId = await uploadFileInChunks(file);
      console.log(`File ${file.name} uploaded successfully with ID: ${fileId}`);
    }
    navigate("/");
  };

  const uploadFileInChunks = async (file) => {
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    let fileId = null;

    for (let i = 0; i < totalChunks; i++) {
      const start = i * CHUNK_SIZE;
      const end = Math.min(start + CHUNK_SIZE, file.size);
      const chunk = file.slice(start, end);

      if (i === 0) {
        const hash = await computeHash(file);
        fileId = await uploadFirstChunk(chunk, file.name, totalChunks, hash);
        if (totalChunks == 1) {
          await uploadNextChunk(chunk, fileId, i);
        }
      } else {
        await uploadNextChunk(chunk, fileId, i);
      }

      setUploadProgress((prev) => ({
        ...prev,
        [file.name]: Math.round(((i + 1) / totalChunks) * 100),
      }));
    }

    return fileId;
  };

  const uploadFirstChunk = async (chunk, fileName, totalChunks, hash) => {
    const formData = new FormData();
    formData.append("file", chunk);
    formData.append("file_name", fileName);
    formData.append("total_chunks", totalChunks);
    formData.append("hash", hash);
    formData.append("parentId", parentId);
    formData.append("app_label", app_label);
    formData.append("model_name", model_name);

    const response = await axios.post("/uploads/upload/first_chunk/", formData);
    return response.data.file_id;
  };

  const uploadNextChunk = async (chunk, fileId, chunkIndex) => {
    const formData = new FormData();
    formData.append("file", chunk);
    formData.append("file_id", fileId);
    formData.append("chunk_index", chunkIndex);

    await axios.post("/uploads/upload/next_chunk/", formData);
  };

  const computeHash = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const wordArray = CryptoJS.lib.WordArray.create(reader.result);
        const hash = CryptoJS.SHA256(wordArray).toString(CryptoJS.enc.Hex);
        resolve(hash);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="example-modal-sizes-title-sm"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h3>Uploading Files...</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <button onClick={handleUpload}>Upload</button> */}
        <table style={{ width: "100%", marginTop: "10px" }}>
          <thead>
            <tr>
              <th className="text-primary">File Path</th>
              <th className="text-primary">File Size (bytes)</th>
              <th className="text-primary">Progress</th>
            </tr>
          </thead>
          <tbody>
            {files.map((file, index) => (
              <tr key={index}>
                <td>{file.path}</td>
                <td>{file.size}</td>
                <td>
                  {/* <progress value={uploadProgress[file.name] || 0} max="100" /> */}
                  <ProgressBar
                    striped
                    variant="danger"
                    now={uploadProgress[file.name] || 0}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
};

export default UploadingFiles;
