import ShareModal from "components/General/ShareModal";
import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { FaRegHeart } from "react-icons/fa6";
import { MdOutlineFileUpload } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import { notify } from "utilities/notifications/Notify";

const SpaceCard = ({
  id,
  slug,
  image,
  title,
  type,
  projectId,
  project,
  area,
  setShow,
  setRoomData,
  boxShadow,
  imgHeight = "300px",
  className,
  style,
  onClick,
}) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const [showShare, setShowShare] = useState(false);
  const [hover, setHover] = useState(false);
  const [isLiked, setIsLiked] = useState(false);

  const checkRoomIfSaved = async () => {
    try {
      const response = await authActions.checkRoomIfSaved(user.id, id);
      console.log(response);
      if (response) {
        setIsLiked(true);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveRoom = async () => {
    try {
      const response = await authActions.saveRoom(user.id, id);
      if (response) {
        setIsLiked(true);
        notify("Space saved successfully", "success");
      } else {
        notify("Failed to save space, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUnsaveRoom = async () => {
    try {
      const response = await authActions.unsaveRoom(user.id, id);
      if (response) {
        setIsLiked(false);
        notify("Space unsaved successfully", "success");
      } else {
        notify("Failed to unsave space, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkRoomIfSaved();
  }, []);

  return (
    // <Card className="p-0 overflow-hidden" style={{ width: "100%", cursor: "pointer", borderRadius: 12 }} onClick={onClick}>
    //   <img className="img-fluid" variant="top" src={image} style={{ height: 230 }} />
    //   <Card.Body>
    //     <Card.Title className="geist-700" style={{ color: "#344054", fontSize: "16px" }}> {title} </Card.Title>
    //     <div className="body-text2 fw-bold" style={{ color: "#344054" }}>
    //       {type ? ( <div>Type: {type}</div> ) : area ? ( <div>Area: {area}</div> ) : project ? ( <div>Project: {project}</div> ) : ( <div /> )}
    //     </div>
    //   </Card.Body>
    // </Card>

    <>
      <Card
        className={`cursor-pointer w-100 py-0 px-0 mx-2 border-0 rounded overflow-hidden position-relative ${
          hover ? "" : "border-0"
        } ${className}`}
        style={{
          borderRadius: 6,
          boxShadow: boxShadow,
          backgroundColor: hover ? "rgba(0, 0, 0, 0.1)" : "",
          ...style,
        }}
        onClick={onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Card.Img
          className="img-fluid"
          variant="top"
          src={image}
          style={{
            maxHeight: imgHeight,
            minHeight: imgHeight,
            objectFit: "cover",
          }}
        />
        <div
          className="d-flex position-absolute"
          style={{ right: 15, top: 10 }}
        >
          <Button
            className="d-flex justify-content-center align-items-center share-like-btn"
            onClick={(e) => {
              e.stopPropagation();
              setShowShare(true);
            }}
          >
            <MdOutlineFileUpload />
          </Button>
          {user && (
            <Button
              className={`d-flex justify-content-center align-items-center share-like-btn ms-2 ${
                isLiked ? "bg-primary text-white" : "bg-white text-dark"
              }`}
              onClick={(e) => {
                e.stopPropagation();
                isLiked ? handleUnsaveRoom() : handleSaveRoom();
              }}
            >
              <FaRegHeart size={18} />
            </Button>
          )}
        </div>
        <Card.Body
          className="p-2 d-flex flex-column align-items-start"
          onClick={() => navigate(`/projects/${slug}`)}
        >
          <Card.Title
            className={`geist-700 mb-0 ${hover ? "text-primary" : ""}`}
            style={{ fontSize: "16px" }}
          >
            {title}
          </Card.Title>
          {/* <Card.Title className={`geist-700 mb-0 ${hover ? "text-primary" : ""}`} style={{ fontSize: "16px" }}> {title} </Card.Title> */}
          <div className="body-text2 geist-400 d-flex flex-column align-items-start">
            {type ? (
              <span className="ms-0">Type: {type}</span>
            ) : area ? (
              <span className="ms-0">Area: {area}</span>
            ) : (
              project && (
                <span className="ms-0">Project: {project?.project_name}</span>
              )
            )}
          </div>
        </Card.Body>
      </Card>
      <ShareModal
        show={showShare}
        onHide={() => setShowShare(false)}
        id={id}
        image={image}
        title={`${type} / ${project?.project_name}`}
        // urlPath={`/spaces/${slug}`} // #defaultUrl
      />
    </>
  );
};

export default SpaceCard;
