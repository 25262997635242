import LoginModal from "components/Auth/LoginModal";
import { Button, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { LuEyeOff, LuMail } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { verifyOtpAndLogin } from "reduxFiles/reducer/authSlices";
import * as authActions from "reduxFiles/actions/authActions";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { notify } from "utilities/notifications/Notify";

const BookConsultationForm = ({
  form,
  loading,
  setShow,
  showLoginModal,
  setShowLoginModal,
  otpInput,
  // verifyOtpLogin,
  handleSignUp,
  handleSubmitRequest,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const verifyOtpLogin = async () => {
    const formData = new FormData(form.current);
    const data = {
      username: formData.get("email"),
      email_verified_otp: formData.get("email_verified_otp"),
    };
    console.log(data);
    try {
      const response = await authActions.verfiyOtpLogin(data);
      if (response.data) {
        if (response.data?.user?.is_active) {
          dispatch(verifyOtpAndLogin(response.data));
        } else {
          notify("User is not active", "error");
        }
        if (response.data?.user.id) {
          handleSubmitRequest(response.data.user.id);
        }
        // navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ minWidth: medium ? 400 : 300 }}>
      <Form ref={form} onSubmit={handleSignUp}>
        <Form.Group className="mb-3">
          <Form.Label>
            Name<sup style={{ color: "red" }}>*</sup>
          </Form.Label>
          <Form.Control
            className="border-dark"
            name="full_name"
            required
            placeholder="Full name"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="text-gray-900">
            Company Email<sup style={{ color: "red" }}>*</sup>
          </Form.Label>
          <InputGroup>
            <InputGroup.Text
              className="border-dark"
              style={{ backgroundColor: "white" }}
            >
              <LuMail />
            </InputGroup.Text>
            <Form.Control
              className="border-dark border-start-0 px-2"
              type="email"
              name="email"
              placeholder="example@gmail.com"
              required
            />
          </InputGroup>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="text-gray-900">
            Phone Number<sup style={{ color: "red" }}>*</sup>
          </Form.Label>
          <div className="d-flex">
            <InputGroup className="">
              <InputGroup.Text
                className="border-dark geist-600"
                style={{ backgroundColor: "white" }}
              >
                +91
              </InputGroup.Text>
              <Form.Control
                className="border-dark border-start-0 px-2"
                placeholder="Phone number"
                name="mobile"
                required
              />
            </InputGroup>
            {/* <Button
              className="border-0 py-2 text-white ms-3"
              style={{ background: "#5DC067" }}
            >
              Confirmed
            </Button> */}
          </div>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            Pincode<sup style={{ color: "red" }}>*</sup>
          </Form.Label>
          <Form.Control
            className="border-dark"
            name="pincode"
            required
            placeholder="Pincode"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="mb-1">Password</Form.Label>
          <InputGroup>
            <InputGroup.Text className="bg-white border-dark">
              <LuEyeOff size={20} />
            </InputGroup.Text>
            <Form.Control
              name="password"
              className=" border-start-0 p-2 px-0"
              type="password"
              placeholder="Password"
              pattern="^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$"
              title="Password must contain at least 1 uppercase letter, 1 digit, and be at least 8 characters long"
            />
          </InputGroup>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label className="mb-1">Confirm Password</Form.Label>
          <InputGroup>
            <InputGroup.Text className="bg-white border-dark">
              <LuEyeOff size={20} />
            </InputGroup.Text>
            <Form.Control
              name="confirm_password"
              className="border-start-0 p-2 px-0"
              type="password"
              placeholder="Confirm Password"
              pattern="^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$"
              title="Password must contain at least 1 uppercase letter, 1 digit, and be at least 8 characters long"
            />
          </InputGroup>
        </Form.Group>
        {otpInput && (
          <Form.Group className="my-3">
            <Form.Label>
              OTP<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <Row className="">
              <Col xs="6">
                <Form.Control
                  name="email_verified_otp"
                  placeholder="Enter otp"
                />
              </Col>
              <Col xs="3">
                <Button
                  variant="success"
                  className="w-100 text-white"
                  onClick={verifyOtpLogin}
                >
                  Verify
                </Button>
              </Col>
              <Col xs="3">
                <Button className="text-white w-100">Resend</Button>
              </Col>
            </Row>
            <Form.Text className="text-success">
              OTP has been sent to the email address
            </Form.Text>
          </Form.Group>
        )}
        <div className="text-center mb-2">
          <Button
            type="submit"
            disabled={loading}
            className="text-white w-100 mb-2"
          // onClick={(e) => handleSignUp(e)}
          >
            {loading && (
              <Spinner animation="border" size="sm" className="me-2" />
            )}
            {isAuthenticated ? "Request a Free Call Back" : "Verify"}
          </Button>
          <div className="body-text2">
            Already have an account?{" "}
            <span
              className="text-primary geist-700"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowLoginModal(true);
              }}
            >
              Login
            </span>
          </div>
        </div>
      </Form>
      <LoginModal
        showLoginModal={showLoginModal}
        setShowLoginModal={setShowLoginModal}
      />
    </div>
  );
};

export default BookConsultationForm;
