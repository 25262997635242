import React, { useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import "./drop-file-input.css";
import { FaUpload, FaXmark } from "react-icons/fa6";
import { RiUpload2Fill } from "react-icons/ri";
import { Button, Card, Image } from "react-bootstrap";

const DropFileInput = ({
  type = "",
  files = [],
  onFileChange,
  allowMultiple = true,
  showFiles = true,
  className,
  style,
  allowOnlyImages = false,
}) => {
  const wrapperRef = useRef(null);
  const [fileList, setFileList] = useState(files);

  const onDrop = (acceptedFiles) => {
    const updatedList = allowMultiple
      ? [...fileList, ...acceptedFiles]
      : [acceptedFiles[0]];
    setFileList(updatedList);
    onFileChange(updatedList);
  };

  const fileRemove = (file) => {
    const updatedList = fileList.filter((f) => f !== file);
    setFileList(updatedList);
    onFileChange(updatedList);
  };

  useEffect(() => {
    setFileList(files);
  }, [files]);

  return (
    <>
      {((!allowMultiple && fileList.length === 0) || allowMultiple) && (
        <Dropzone
          onDrop={onDrop}
          accept={
            allowOnlyImages
              ? {
                "image/*": [".png", ".gif", ".jpeg", ".jpg", ".webp"],
              }
              : {}
          }
        >
          {({ getRootProps, getInputProps, isDragActive, open }) => (
            <div
              className={`drop-file-input position-relative d-flex justify-content-center align-items-center border border-dashed ${className}`}
              style={{
                width: "100%",
                height: 240,
                borderRadius: 8,
                ...style,
              }}
              onClick={open}
            >
              <div
                className="drop-file-input__label d-flex flex-column justify-content-center align-items-center h-100"
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <div className="text-center w-100">
                    Drop the files here ...
                  </div>
                ) : (
                  <div className="text-center">
                    <RiUpload2Fill size={24} className="text-dark mb-3" />
                    <p className="body-text2">
                      Drop all files here or{" "}
                      <u className="text-primary">click to upload</u>
                      {type === "property" &&
                        <div className="d-flex flex-column body-text3 text-gray-600">
                          <span>Images (.jpg/.jpeg/.png)</span>
                          <span>Autocad drawings (.dwg)</span>
                          <span>Sketchup models (.skp)</span>
                        </div>
                      }
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </Dropzone>
      )}
      {showFiles && fileList.length > 0 && (
        <div className="drop-file-preview mt-3">
          {fileList.map((item, index) => (
            <div
              key={index}
              className="drop-file-preview__item d-flex justify-content-between align-items-center w-100"
            >
              <div
                className="rounded overflow-hidden border me-2"
                style={{ height: 76, maxWidth: 100 }}
              >
                <img
                  src={URL.createObjectURL(item)}
                  alt=""
                  className="img-fluid"
                  style={{ objectFit: "cover", width: 100, height: 76 }}
                />
              </div>
              <div className="w-100">{item.name}</div>
              <div
                onClick={() => fileRemove(item)}
                className="drop-file-preview__item__del cursor-pointer bg-gray text-center rounded-circle ms-2"
              >
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ width: 40, height: 40 }}
                >
                  <div className="w-100 mb-1">
                    <FaXmark size={18} className="text-dark" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default DropFileInput;
