import { frame_layout, turnkey_complete_service } from "assets";
import { Button, Card, Col, Row } from "react-bootstrap";
import { FaRegStar } from "react-icons/fa";

const ConsultationRequirement = ({
  className,
  processEventKey,
  setProcessEventKey,
  setServiceType,
}) => {
  return (
    <Row className={`d-flex flex-wrap ${className}`}>
      <Col lg={6}>
        <Card
          className="p-0"
          style={{ boxShadow: "none", position: "relative", height: "100%" }}
        >
          <Button
            className="d-flex align-items-center position-absolute text-secondary bg-info border-0 geist-600"
            style={{ top: "10px", left: "10px" }}
          >
            <FaRegStar className="me-1" /> Top Rated
          </Button>
          <Card.Img
            className="img-fluid"
            variant="top"
            src={turnkey_complete_service}
            style={{ height: 230, objectFit: "fill" }}
          />
          <Card.Body className="d-flex flex-column justify-content-between">
            <div className="mb-3">
              <Card.Title className="geist-600" style={{ fontSize: "16px" }}>
                Turnkey (Complete) Interior Solution
              </Card.Title>
              <Card.Text className="body-text2">
                Complete hassle-free execution of your home interiors from
                ideation to handover, while maintaining transparency every step
                of the way.
              </Card.Text>
            </div>
            <Button
              className={`geist-600 text-white w-100`}
              onClick={() => {
                setServiceType("turnkey");
                setProcessEventKey("project_details");
              }}
            >
              Continue (Recommended)
            </Button>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={6} className="d-flex flex-column flex-grow">
        <Card className="flex-grow p-0" style={{ boxShadow: "none" }}>
          <Card.Img
            className="img-fluid"
            variant="top"
            src={frame_layout}
            style={{ height: 230, objectFit: "fill" }}
          />
          <Card.Body className="d-flex flex-column justify-content-between">
            <div className="mb-3">
              <Card.Title className="geist-600" style={{ fontSize: "16px" }}>
                Customised Interior Drawings
              </Card.Title>
              <Card.Text className="body-text2">
                Final construction drawings and architect support till release
                of final drawings. Project execution not included. Suitable for
                people looking only for architect support.
              </Card.Text>
            </div>
            <Button
              className={`geist-600 text-white w-100`}
              onClick={() => {
                setServiceType("customised");
                setProcessEventKey("project_details");
              }}
            >
              Continue
            </Button>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ConsultationRequirement;
