import { Button, Card, Col, Row } from "react-bootstrap";

const ProductListCard = ({
  id,
  title,
  product_type,
  brand_name,
  cost,
  description,
  image,
  onClick,
  className,
  style,
}) => {
  return (
    <Card
      className={`p-0 ${className}`}
      style={{ width: "100%", cursor: "pointer", ...style }}
      onClick={onClick}
    >
      <Card.Img
        className="img-fluid"
        variant="top"
        src={image}
        style={{ maxHeight: "170px", minHeight: "170px" }}
      />
      <Card.Body>
        <Card.Title
          className="geist-700"
          style={{ color: "#344054", fontSize: "16px" }}
        >
          {title} / {product_type}
        </Card.Title>
        <div className="body-text2">
          <div>Brand: {brand_name}</div>
          <div>Cost: {cost}</div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ProductListCard;
