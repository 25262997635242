import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import DropFileInput from "components/DragInput/DropFileInput";
import ReactSelect from "react-select";
import LoadingOverlay from "components/General/LoadingOverlay";
import { notify } from "utilities/notifications/Notify";
import * as authActions from "reduxFiles/actions/authActions";
import { FaCircleMinus, FaXmark } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa";

function AddProduct() {
  const navigate = useNavigate();
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const { status } = useParams();
  const user = useSelector((state) => state.auth.user);

  const projectInfoRef = useRef();
  // add product file
  const steps = ["Add Product", "Add Project", "Preview"];
  const [productTypes, setProductTypes] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);

  // const fetchProjectTypes = async () => {
  //   const response = await authActions.fetchProjectTypes();
  //   try {
  //     if (response.data) {
  //       setProjectTypes(response.data?.results);
  //     } else {
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const fetchProductTypes = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchProductTypes();
      console.log(response?.data);
      if (response.data) {
        setProductTypes(response?.data);
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  // const fetchProducts = async () => {
  //   try {
  //     const response = await authActions.fetchProducts();
  //     if (response.data) {
  //       setProducts(response.data?.results);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const onSelectProduct = (files) => {
    console.log(files);
    setProcessing(true);
    const currentFiles = new Set(selectedProducts.map((item) => item.file));

    const result = files.filter((file) => !currentFiles.has(file));
    const temp = result?.map((item) => {
      return {
        file: item,
        title: "",
        product_type: "",
        description: "",
        cost: "",
        size: "",
      };
    });
    setSelectedProducts(temp);
    setProcessing(false);
  };

  const onRemoveRoomFile = (index) => {
    const temp = [...selectedProducts];
    const products = [...temp.slice(0, index), , ...temp.slice(index + 1)];
    setSelectedProducts(products);
  };

  const handleGoToPreview = () => {
    // setCurrentStep(3);
    if (selectedProducts?.length > 0) {
      setCurrentStep(3);
    } else {
      notify("Atleast one product is required", "error");
    }
    // handleSubmission();
  };

  const handleProductsData = () => {
    const formData = new FormData(projectInfoRef.current);
    let isError;
    let data = {};
    formData.forEach((value, key) => {
      if (!value) {
        isError = true;
      } else {
        data[key] = value;
      }
    });
    if (isError) {
      notify("Please enter in all the required fields!");
      return;
    }
    console.log(data);
    setCurrentStep(2);
  };

  const handleSubmission = async () => {
    setLoading(true);
    const data = {};
    console.log(data);
    const formData = new FormData();
    // formData.append("project_data", JSON.stringify(data));
    formData.append("user", user.id);
    try {
      const response = await authActions.createProject(formData);
      if (response.status === 201) {
        notify("Products created successfully!", "success");
        navigate("/");
      }
    } catch (error) {
      notify(error.response.data.msg || "An error occured!", "error", 1);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (status) {
      setCurrentStep(2);
    }
  }, [status]);

  useEffect(() => {
    // fetchProjectTypes();
    fetchProductTypes();
  }, []);

  return (
    <>
      <Container
        className="position-relative mt-5 pt-4 px-0"
        style={{ minHeight: "60vh" }}
      >
        {processing && (
          <LoadingOverlay isLoading={processing} setLoading={setProcessing} />
        )}

        {/* <div className="d-flex flex-column flex-lg-row my-md-4"> */}
        <Row className="my-md-4">
          <Col lg={3} xxl={2}>
            <div className="d-flex justify-content-center justify-content-lg-start">
              <div className="stepper d-flex flex-lg-column mt-2 mt-md-4 mt-lg-3 ml-2 mb-1 px-3 px-lg-0">
                <div className="d-flex align-items-center mb-1 me-3">
                  <div className="d-flex">
                    <div className="d-flex flex-lg-column pr-4 align-items-center">
                      <div
                        className={`p-2 text-center rounded-btn mb-1 ${
                          currentStep === 1
                            ? "bg-primary text-white"
                            : currentStep === 2 || currentStep === 3
                            ? "bg-success text-white"
                            : "bg-faint"
                        }`}
                        // style={{ width: 36, height: 36 }}
                      >
                        {currentStep === 1 ? (
                          "1"
                        ) : (
                          <FaCheck size={12} className="mb-1" />
                        )}
                      </div>
                      {large && <div className="py-4 line h-100" />}
                    </div>
                    <div
                      className={`ms-2 pt-2 pt-xl-0 geist-600 body-title ${
                        currentStep !== 1 ? "text-success" : ""
                      }`}
                    >
                      Add Product
                    </div>
                  </div>
                  {/* {!large && (<div className="border mb-2 mx-2" style={{ height: 1, width: 50 }} />)} */}
                </div>
                <div className="d-flex align-items-center mb-1 me-3">
                  <div className="d-flex">
                    <div className="d-flex flex-column pr-4 align-items-center">
                      <div
                        className={`rounded-btn py-2 px-3 mb-1 ${
                          currentStep === 2
                            ? "bg-primary text-white"
                            : currentStep === 3
                            ? "bg-success text-white"
                            : "bg-faint"
                        }`}
                      >
                        {currentStep === 1 || currentStep === 2 ? (
                          "2"
                        ) : (
                          <FaCheck size={12} className="mb-1" />
                        )}
                      </div>
                      {large && <div className="py-4 line h-100" />}
                    </div>
                    <div
                      className={`ms-2 pt-2 pt-xl-0 geist-600 body-title ${
                        currentStep !== 1 && currentStep !== 2
                          ? "text-success"
                          : ""
                      }`}
                    >
                      Add Project
                    </div>
                  </div>
                  {/* {!large && (<div className="border mb-2 mx-2" style={{ height: 1, width: 50 }} />)} */}
                </div>
                <div className="d-flex align-items-center mb-1 me-3">
                  <div className="d-flex">
                    <div className="d-flex flex-column pr-4 align-items-center">
                      <div
                        className={`rounded-btn py-2 px-3 mb-1 ${
                          currentStep === 3
                            ? "bg-primary text-white"
                            : "bg-faint"
                        }`}
                      >
                        3
                      </div>
                      <div className="py-4 line h-100 d-none"></div>
                    </div>
                    <div className="ms-2 pt-2 pt-xl-0 geist-600 body-title">
                      Preview
                    </div>
                  </div>
                  {/* {!large && (<div className="border mb-2 mx-2" style={{ height: 1, width: 50 }} />)} */}
                </div>
              </div>
            </div>
          </Col>
          {/* <div className="p-3 rounded" style={{ flex: "1 1 auto" }}> */}
          <Col lg={9} xxl={10} className="rounded p-2">
            <div
              className="bg-faint p-3 m-2 m-lg-0 mb-4"
              style={{ borderRadius: 16 }}
            >
              {currentStep === 1 && (
                <div>
                  <div className="d-flex flex-column flex-sm-row justify-content-between">
                    <div className="geist-700 mb-2">
                      <h2 className="fw-bold">Add Product ({currentStep}/3)</h2>
                    </div>
                    {small && (
                      <div>
                        <Button
                          className="bg-gunmetal border-gray-200 text-dark me-2 me-md-3"
                          onClick={() => navigate(-1)}
                        >
                          Cancel <FaXmark size={18} className="mb-1" />
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className="mt-4">
                    <Form ref={projectInfoRef} className="mb-3">
                      {selectedProducts?.map((item, index) => {
                        return (
                          <Row className="border-bottom border-gray pb-3 mb-3">
                            <Col lg={4}>
                              <div className="border rounded overflow-hidden mb-3">
                                <img
                                  src={
                                    item?.file
                                      ? URL.createObjectURL(item?.file)
                                      : null
                                  }
                                  alt=""
                                  className="img-fluid w-100"
                                  style={{ height: 200, objectFit: "cover" }}
                                />
                              </div>
                            </Col>
                            <Col lg={8}>
                              <Form.Group className="mb-2">
                                <Form.Label style={{ fontWeight: 500 }}>
                                  Product Name
                                  <sup style={{ color: "red" }}>*</sup>
                                </Form.Label>
                                <Form.Control
                                  name="title"
                                  defaultValue={selectedProducts[index]?.title}
                                  className="border-dark"
                                />
                              </Form.Group>
                              <Row>
                                <Col lg={6}>
                                  <Form.Group className="mb-2">
                                    <Form.Label style={{ fontWeight: 500 }}>
                                      Property Type
                                      <sup style={{ color: "red" }}>*</sup>
                                    </Form.Label>
                                    <div className="">
                                      {productTypes?.length > 0 ? (
                                        <ReactSelect
                                          name="project_type"
                                          isMulti={false}
                                          isDisabled={productTypes != null}
                                          options={productTypes}
                                          getOptionLabel={(e) =>
                                            e.property_name
                                          }
                                          getOptionValue={(e) => e.id}
                                          defaultValue={productTypes.find(
                                            (e) => e.id === item
                                          )}
                                          onChange={(e) => {
                                            if (e?.id) {
                                            }
                                          }}
                                          isClearable
                                          placeholder="Select project type"
                                          className="bg-white border border-dark rounded focus-none"
                                        />
                                      ) : (
                                        <div>
                                          Please wait while we are loading the
                                          project types...
                                        </div>
                                      )}
                                    </div>
                                  </Form.Group>
                                </Col>
                                <Col lg={6}>
                                  <Form.Group className="mb-2">
                                    <Form.Label style={{ fontWeight: 500 }}>
                                      Product Size
                                      <sup style={{ color: "red" }}>*</sup>
                                    </Form.Label>
                                    <Form.Control
                                      type="number"
                                      name="size"
                                      placeholder=""
                                      defaultValue={
                                        selectedProducts[index]?.size
                                      }
                                      className="border-dark"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="mb-2">
                                    <Form.Label style={{ fontWeight: 500 }}>
                                      Product cost
                                      <sup style={{ color: "red" }}>*</sup>
                                    </Form.Label>
                                    <div className="border border-dark rounded">
                                      <InputGroup className="border-0">
                                        <Form.Control
                                          type="number"
                                          name="cost"
                                          placeholder="499"
                                          defaultValue={
                                            selectedProducts[index]?.cost
                                          }
                                          className="border-0"
                                          // onChange={(e) => {}}
                                        />
                                        <InputGroup.Text className="border-0">
                                          Rs
                                        </InputGroup.Text>
                                      </InputGroup>
                                    </div>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Form.Group className="mb-2">
                                <Form.Label style={{ fontWeight: 500 }}>
                                  Product Description
                                  <sup style={{ color: "red" }}>*</sup>
                                </Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={3}
                                  name="description"
                                  placeholder="Describe the project..."
                                  defaultValue={
                                    selectedProducts[index]?.description
                                  }
                                  className="border-dark"
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        );
                      })}
                    </Form>
                    <div className="mb-3">
                      <DropFileInput
                        allowMultiple
                        showFiles={false}
                        className="bg-white border-dark d-block"
                        files={() =>
                          selectedProducts?.filter((item) => item.file)
                        }
                        onFileChange={(files) => onSelectProduct(files)}
                      />
                    </div>
                    <div>
                      <Button
                        className="text-white"
                        onClick={handleProductsData}
                      >
                        Go to Add Project
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {currentStep === 2 && (
                <div>
                  <div className="d-flex flex-column flex-md-row justify-content-between mb-2 mb-lg-0">
                    <div className="geist-700 mb-2">
                      <h2 className="fw-bold">Add Project ({currentStep}/3)</h2>
                    </div>
                    {small && (
                      <div>
                        <Button
                          className="bg-gunmetal border-gray-200 text-dark me-3"
                          onClick={() => navigate(-1)}
                        >
                          Cancel <FaXmark size={18} className="mb-1" />
                        </Button>
                      </div>
                    )}
                  </div>
                  <Row>
                    {!loading ? (
                      <Col xl={9}>
                        <div style={{ minHeight: 240 }}>
                          <div className="mb-3"></div>
                        </div>
                      </Col>
                    ) : (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ minHeight: 240 }}
                      >
                        <Spinner />
                      </div>
                    )}
                  </Row>
                  <Row className="">
                    <Col xl={9}>
                      <div>
                        <Row
                          xs={2}
                          sm={2}
                          md={3}
                          lg={3}
                          xl={4}
                          className="pt-2 pb-2"
                        ></Row>
                      </div>
                    </Col>
                  </Row>
                  <div>
                    <Button
                      className="bg-gunmetal border-gray-200 text-dark me-3"
                      style={{ width: 120 }}
                      onClick={() => setCurrentStep(1)}
                    >
                      Back
                    </Button>
                    <Button
                      className="text-white"
                      onClick={() => {
                        handleGoToPreview();
                      }}
                    >
                      Go to Preview
                    </Button>
                  </div>
                </div>
              )}
              {currentStep === 3 && (
                <div>
                  <div className="d-flex flex-column flex-lg-row justify-content-between">
                    <div className="geist-700 mb-2">
                      <h2 className="fw-bold">Preview ({currentStep}/3)</h2>
                    </div>
                    {small && (
                      <div>
                        <Button
                          className="bg-gunmetal border-gray-200 text-dark me-3"
                          style={{ width: 120 }}
                          onClick={() => navigate(-1)}
                        >
                          Cancel <FaXmark size={18} className="mb-1" />
                        </Button>
                      </div>
                    )}
                  </div>
                  {/* main component */}
                  <div className="mb-4"></div>

                  <div className="d-flex">
                    <Button
                      className="bg-gunmetal border-gray-200 text-dark me-3"
                      style={{ width: 120 }}
                      onClick={() => setCurrentStep(2)}
                    >
                      Back
                    </Button>
                    <Button
                      className="text-white"
                      style={{ width: 120 }}
                      onClick={handleSubmission}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AddProduct;
