import { useEffect, useState } from "react";
import { user_pfp } from "assets";
import ProfessionalCard from "components/Cards/ProfessionalCard";
import ProjectCard from "components/Cards/ProjectCard";
import SpaceCard from "components/Cards/SpaceCard";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { LuPencilLine } from "react-icons/lu";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import SpacesSliderView from "pages/Projects/SpacesSliderView";
import { useMediaQuery } from "react-responsive";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

const Library = () => {
  const navigate = useNavigate();
  // const small = useMediaQuery({ query: "(min-width: 576px)" });
  // const medium = useMediaQuery({ query: "(min-width: 768px)" });
  // const large = useMediaQuery({ query: "(min-width: 992px)" });
  // const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const user = useSelector((state) => state.auth.user);

  return (
    <Container fluid className="page-padding px-0">
      <section className="bg-faint border-bottom">
        <Container className="d-flex flex-column pt-4">
          <div className="d-flex align-items-center pb-4">
            <div className="geist-600" style={{ fontSize: "30px" }}>
              <div className="text-center text-md-start">{user?.full_name}</div>
              <Button
                className="text-white"
                onClick={() => navigate("/user/profile#edit")}
              >
                <LuPencilLine /> Edit Profile
              </Button>
            </div>
            <div className="ms-auto">
              <img
                style={{ borderRadius: "50%", objectFit: "cover" }}
                width={150}
                height={150}
                src={user_pfp}
                alt="avatar"
              />
            </div>
          </div>
        </Container>
      </section>
      <LibrarySection />
    </Container>
  );
};

export const LibrarySection = () => {
  // const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const user = useSelector((state) => state.auth.user);

  const [allProjects, setAllProjects] = useState([]);
  const [allRooms, setAllRooms] = useState([]);
  const [allProfessionals, setAllProfessionals] = useState([]);

  const [projects, setProjects] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [professionals, setProfessionals] = useState([]);

  const [showAllProjects, setShowAllProjects] = useState(false);
  const [showAllRooms, setShowAllRooms] = useState(false);
  const [showAllProfessionals, setShowAllProfessionals] = useState(false);

  const [loading, setLoading] = useState(false);
  const [showSpaceView, setShowSpaceView] = useState(false);
  const [activeSpaceIndex, setActiveSpaceIndex] = useState(0);
  const [roomData, setRoomData] = useState(null);

  const itemsInRow = xlarge ? 3 : large ? 3 : medium ? 2 : 1;
  const professionalInCol = 2;

  useEffect(() => {
    if (showAllProjects) {
      setProjects(allProjects);
    } else {
      setProjects(allProjects?.slice(0, itemsInRow));
    }
  }, [allProjects, showAllProjects]);

  useEffect(() => {
    console.log(allRooms);
    if (showAllRooms) {
      setRooms(allRooms);
    } else {
      setRooms(allRooms?.slice(0, itemsInRow));
    }
  }, [allRooms, showAllRooms]);

  useEffect(() => {
    if (showAllProfessionals) {
      setProfessionals(allProfessionals);
    } else {
      setProfessionals(allProfessionals?.slice(0, professionalInCol));
    }
  }, [allProfessionals, showAllProfessionals]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchSavedDataByUser(user.id);
      if (response.data) {
        setAllProjects(response.data["saved_projects"]);
        setAllRooms(response.data["saved_rooms"]);
        setAllProfessionals(response.data["saved_professionals"]);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log(rooms);
  }, [rooms]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container className="py-4">
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "50vh" }}
        >
          <Spinner />
        </div>
      ) : (
        <div>
          <div className="mb-3">
            <div className="geist-600 mb-2" style={{ fontSize: "30px" }}>
              Saved Projects {!loading ?? projects.length}
            </div>
            <Row>
              {projects?.length > 0 ? (
                projects?.map((project, index) => (
                  <Col
                    key={index}
                    lg={4}
                    md={6}
                    className="d-flex justify-content-around my-3"
                  >
                    <ProjectCard
                      {...project}
                      id={project.id}
                      project_name={project?.project_data?.project_name}
                      image={project?.project_data?.rooms[0]?.media[0]?.file}
                      // image={project?.project_data?.image}
                      // type={`${project?.project_data?.bhk}, Block ${project?.project_data?.block}, ${project?.project_data?.project_area}`}
                      type={`${project?.project_data?.bhk}, ${project?.project_data?.project_area}`}
                      architect={project?.project_data?.company_name}
                      location={project?.project_data?.property_data?.location}
                      builders={
                        project?.project_data?.property_data?.builder_data
                          ?.company_name
                      }
                      className="mx-0"
                    />
                  </Col>
                ))
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center bg-faint rounded"
                  style={{ height: 160 }}
                >
                  No projects available
                </div>
              )}
            </Row>
            {allProjects?.length > itemsInRow && (
              <div
                className="geist text-primary fw-bold"
                onClick={() => setShowAllProjects(!showAllProjects)}
              >
                View all{" "}
                {showAllProjects ? (
                  <BiChevronUp size={24} />
                ) : (
                  <BiChevronDown size={24} />
                )}
              </div>
            )}
          </div>
          <div className="mb-3">
            <div className="geist-600 mb-2" style={{ fontSize: "30px" }}>
              Saved Spaces {!loading ?? rooms.length}
            </div>
            <Row>
              {rooms?.length > 0 ? (
                rooms?.map((room, index) => (
                  <Col
                    key={index}
                    lg={4}
                    md={6}
                    className="d-flex justify-content-around my-3"
                  >
                    <SpaceCard
                      {...room?.room_data}
                      id={room.id}
                      project={room?.room_data?.project}
                      image={room?.room_data?.media[0]?.file}
                      title={`${room?.room_data?.room_type_data?.title}/${room?.room_data?.project_data?.property_name}`}
                      // type={`${room?.room_data?.project_data.bhk}, Block ${room?.room_data?.project_data.block}, ${room?.room_data?.project_data.project_area}`}
                      type={`${room?.project_data?.bhk}, ${room?.project_data?.project_area}`}
                      setShow={setShowSpaceView}
                      setRoomData={setRoomData}
                    />
                  </Col>
                ))
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center bg-faint rounded"
                  style={{ height: 160 }}
                >
                  No spaces available
                </div>
              )}
            </Row>
            <SpacesSliderView
              ativeRoomIndex={activeSpaceIndex}
              setActiveRoomIndex={setActiveSpaceIndex}
              show={showSpaceView}
              setShow={setShowSpaceView}
              roomData={roomData}
            />
            {allRooms?.length > itemsInRow && (
              <div
                className="geist text-primary fw-bold"
                onClick={() => setShowAllRooms(!showAllRooms)}
              >
                View all{" "}
                {showAllRooms ? (
                  <BiChevronUp size={24} />
                ) : (
                  <BiChevronDown size={24} />
                )}
              </div>
            )}
          </div>
          <div className="mb-3">
            <div className="geist-600 mb-2" style={{ fontSize: "30px" }}>
              Saved Professionals {!loading ?? professionals.length}
            </div>
            <Row>
              <Col>
                {professionals?.length > 0 ? (
                  professionals?.map((professional, index) => (
                    <ProfessionalCard
                      key={index}
                      {...professional}
                      name={professional.professional_data?.full_name}
                      // slug,
                      company_name={
                        professional?.architect_data?.company_name ??
                        professional?.builder_data?.company_name ??
                        ""
                      }
                      location={
                        professional?.architect_data?.location ??
                        professional?.builder_data?.location ??
                        ""
                      }
                      rating={
                        professional?.architect_data?.rating ??
                        professional?.builder_data?.rating ??
                        ""
                      }
                      project_count={
                        professional?.architect_data?.project_count ??
                        professional?.builder_data?.project_count ??
                        ""
                      }
                      expertise={
                        professional?.architect_data?.expertise ??
                        professional?.builder_data?.expertise ??
                        "-"
                      }
                      project_names={
                        professional?.architect_data?.project_names ??
                        professional?.builder_data?.project_names ??
                        ""
                      }
                      // // project_img,
                      type={professional.professional_data?.user_type}
                      profile_img={
                        professional.professional_data?.profile_picture
                      }
                    // onClick={() =>
                    //   navigate(`/professionals/${slug}/${prof.slug}`)
                    // }
                    />
                  ))
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center bg-faint rounded"
                    style={{ height: 160 }}
                  >
                    No professional available
                  </div>
                )}
              </Col>
            </Row>
            {allProfessionals?.length > professionalInCol && (
              <div
                className="geist text-primary fw-bold"
                onClick={() => setShowAllProfessionals(!showAllProfessionals)}
              >
                View all{" "}
                {showAllProfessionals ? (
                  <BiChevronUp size={24} />
                ) : (
                  <BiChevronDown size={24} />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </Container>
  );
};

export default Library;
