import React, { useEffect, useState } from "react";
import { logout } from "reduxFiles/reducer/authSlices";
import {
  Button,
  Container,
  Form,
  Image,
  InputGroup,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CiSearch } from "react-icons/ci";
import LoginModal from "components/Auth/LoginModal";
import InspirationMegaMenu from "./InspirationMegaManu";
import BrandMegaMenu from "./BrandMegaMenu";
import ProfessionalMegaMenu from "./ProfessionalMegaMenu";
import * as authActions from "reduxFiles/actions/authActions";
import { interiokeys_logo } from "assets";
import { notify } from "utilities/notifications/Notify";

const NavLink = ({ className, title, route }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param1 = params.length > 0 ? params[0] : "";

  const [hover, setHover] = useState(null);

  return (
    <Nav.Link
      className={`py-2 ${param1 === route ? "border-primary" : ""
        } ${className}`}
      onClick={() => {
        navigate(`/${route}`);
      }}
      onMouseEnter={() => setHover(route)}
      onMouseLeave={() => setHover(null)}
      style={{
        color:
          hover || hover == ""
            ? hover === route
              ? "red"
              : "black"
            : param1 === route
              ? "red"
              : "black",
      }}
    >
      {title}
    </Nav.Link>
  );
};

const NavBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const params = location.pathname.split("/").filter((item) => item !== "");
  // const param1 = params.length > 0 ? params[0] : "";
  const param2 = params.length > 1 ? params[1] : "";

  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const isLargeScreen = useMediaQuery({
    query: "(min-width: 992px) and (max-width: 1300px)",
  });

  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showForm, setShowForm] = useState("login");
  const [isHomeOwner, setIsHomeOwner] = useState(true);
  const [accHover, setAccHover] = useState(false);
  const [professionalTypes, setProfessionalTypes] = useState([]);
  const [projects, setProjects] = useState([]);
  const [roomTypes, setRoomTypes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [brandCategories, setBrandCategories] = useState([]);

  const [showInspirations, setShowInspirations] = useState(false);
  const [showBrands, setShowBrands] = useState(false);
  const [showProfessionals, setShowProfessionals] = useState(false);

  // const [showAccountDropdown, setShowAccountDropdown] = useState(false);

  // const toggleAccountDropdown = () => {
  //   setAccHover(!accHover);
  //   setShowAccountDropdown(!showAccountDropdown);
  // };

  const fetchRecommendedProjects = async () => {
    try {
      const response = await authActions.fetchRecommendedProjects();
      if (response.data) {
        setProjects(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const fetchSpacesTypesData = async () => {
  //   try {
  //     const response = await authActions.fetchRoomTypes();
  //     if (response.data) {
  //       setProjects(response.data?.results);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const fetchRoomTypesWithCategories = async () => {
    try {
      const response = await authActions.fetchRoomTypesWithCategories();
      if (response.data) {
        console.log(response.data);
        setRoomTypes(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRecommendedBrands = async () => {
    try {
      const response = await authActions.fetchRecommendedBrands();
      if (response.data) {
        setBrands(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBrandsWithCategories = async () => {
    try {
      const response = await authActions.fetchBrandsWithCategories();
      if (response.data) {
        console.log(response.data);
        setBrandCategories(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProfessionalTypes = async () => {
    try {
      const response = await authActions.fetchProfessionalTypes();
      if (response.data) {
        setProfessionalTypes(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogout = async () => {
    try {
      const response = await authActions.logout({ user: user });
      if (response) {
        dispatch(logout());
        notify("Logged out successfully!", "success")
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   setShowInspirations(false);
  //   setShowBrands(false);
  //   setShowProfessionals(false);
  // }, [queryParams]);

  useEffect(() => {
    fetchRecommendedProjects();
    // fetchSpacesTypesData();
    fetchRecommendedBrands();
    fetchBrandsWithCategories();
    fetchRoomTypesWithCategories();
    fetchProfessionalTypes();
  }, []);

  return (
    <>
      <Navbar expand="lg" fixed="top" className="bg-white">
        {/* <Navbar expand="lg" fixed="top" className="bg-white shadow-sm"> */}
        <Container fluid className="px-4 pe-lg-1 pe-xxl-4 py-2">
          <Navbar.Brand href="/">
            <Image
              src={interiokeys_logo}
              alt="Interiokeys logo"
              style={{ maxWidth: !isLargeScreen ? 150 : 100 }}
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="navbarScroll"
            className="focus-none p-1 border-0"
          >
            {/* <CgMenu size={medium ? 28 : small ? 24 : 22} color="#fff" /> */}
          </Navbar.Toggle>
          <Navbar.Collapse
            id="navbarScroll"
            className={
              large ? "d-flex justify-content-end" : "bg-extradark pb-4"
            }
          >
            <Nav.Item
              className={`${"border rounded border-gray me-3 me-lg-2 me-xxl-3"}`}
              style={{ width: !isLargeScreen ? 160 : 100 }}
            >
              <Form.Select
                className="navbar-select input-group"
                aria-label="Default select example"
              >
                <option>Jaipur</option>
                <option value="1">Bangalore</option>
                <option value="2">Hyderabad</option>
                <option value="3">Delhi</option>
                <option value="3">Chandigarh</option>
                <option value="3">Mumbai</option>
              </Form.Select>
            </Nav.Item>

            <Nav
              className={`body-text2 ${large
                ? "d-flex justify-content-end align-items-center geist"
                : "d-flex flex-column my-2 my-lg-0 geist"
                }`}
              navbarScroll
              style={{ fontWeight: 600, width: "100%" }}
            >
              {(!user || user.user_type === "customer") && (
                <div
                  className={
                    large
                      ? "d-flex justify-content-between align-items-center w-100"
                      : "d-flex flex-column"
                  }
                // style={{ whiteSpace: "nowrap" }}
                >
                  <Nav.Item className="d-flex flex-grow">
                    <Form.Group className="d-flex w-100">
                      <InputGroup>
                        <InputGroup.Text
                          className="pe-2 px-lg-1 px-xxl-2"
                          style={{
                            border: 0,
                            borderLeft: "1px solid #98A2B3",
                            borderTop: "1px solid #98A2B3",
                            borderBottom: "1px solid #98A2B3",
                            borderRadius: "8px 0px 0px 8px",
                            backgroundColor: "white",
                          }}
                        >
                          <CiSearch size={20} />
                        </InputGroup.Text>
                        <Form.Control
                          size="sm"
                          placeholder="Search for projects, images, etc"
                          className="py-2 ps-0"
                          style={{
                            border: 0,
                            width: isLargeScreen && 120,
                            borderRight: "1px solid #98A2B3",
                            borderTop: "1px solid #98A2B3",
                            borderBottom: "1px solid #98A2B3",
                            borderRadius: "0px 8px 8px 0px",
                          }}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Nav.Item>
                  <NavDropdown
                    title="Inspirations"
                    show={showInspirations}
                    onClick={() => {
                      setShowInspirations(!showInspirations);
                      setShowBrands(false);
                      setShowProfessionals(false);
                    }}
                    className="ms-2 me-2 mx-lg-0 ms-xxl-4 me-xxl-2"
                    style={{ position: "inherit" }}
                  >
                    <InspirationMegaMenu
                      projects={projects}
                      roomTypes={roomTypes}
                    />
                  </NavDropdown>

                  <NavDropdown
                    title="Brands"
                    show={showBrands}
                    onClick={() => {
                      setShowBrands(!showBrands);
                      setShowInspirations(false);
                      setShowProfessionals(false);
                    }}
                    className="ms-2 mx-lg-0 ms-xxl-2"
                    style={{ position: "inherit" }}
                  >
                    <BrandMegaMenu
                      brands={brands}
                      brandCategories={brandCategories}
                    />
                  </NavDropdown>

                  <NavDropdown
                    title="Professionals"
                    show={showProfessionals}
                    onClick={() => {
                      setShowProfessionals(!showProfessionals);
                      setShowInspirations(false);
                      setShowBrands(false);
                    }}
                    className="ms-2 mx-lg-0 ms-xxl-2"
                    style={{ position: "inherit" }}
                  >
                    <ProfessionalMegaMenu
                      professionalTypes={professionalTypes}
                    />
                  </NavDropdown>
                  <NavLink
                    title="Why Choose us?"
                    route="why-choose-us"
                    className="ms-2 me-4 mx-lg-0 ms-xxl-2 me-xxl-4"
                  />
                  <Nav.Item>
                    <Button
                      variant="primary"
                      className="mt-3 mt-lg-0 px-3 py-2"
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        border: 0,
                        fontSize: 14,
                        borderRadius: 3,
                      }}
                      onClick={() => navigate("/consultation")}
                    >
                      Book Consultation
                    </Button>
                  </Nav.Item>
                </div>
              )}
              {user?.user_type === "builder" && (
                <div className="d-flex align-items-center">
                  <Nav.Item className="text-secondary">
                    <Nav.Link href="/builder/properties">
                      My Properties
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Button
                      variant="primary"
                      className="ms-2 p-2"
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        border: 0,
                        fontSize: 14,
                        borderRadius: 3,
                      }}
                      onClick={() => navigate("/builder/addproperty")}
                    >
                      Add New Property
                    </Button>
                  </Nav.Item>
                </div>
              )}
              {user?.user_type === "architect" && (
                <div className="d-flex align-items-center">
                  <Nav.Item className="text-secondary">
                    <Nav.Link href="/architect/product-library">
                      <span className={`${param2 === 'product-library' ? "text-primary" : "text-gray-900"}`}>Product Library</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/architect/projects">
                      <span className={`${param2 === 'projects' ? "text-primary" : "text-gray-900"}`}>Property Listing</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Button
                      variant="primary"
                      className="ms-2 p-2"
                      style={{
                        fontWeight: "600",
                        color: "#fff",
                        border: 0,
                        fontSize: 14,
                        borderRadius: 3,
                      }}
                      onClick={() => navigate("/architect/addproject")}
                    >
                      Add Project
                    </Button>
                  </Nav.Item>
                </div>
              )}
              {user?.user_type === "brand" && (
                <div className="d-flex align-items-center">
                  <Nav.Item className="text-secondary">
                    <Nav.Link href="/brand/product-library">
                      Product Library
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Button
                      variant="primary"
                      className="ms-2 p-2 text-white border-0 rounded"
                      style={{ fontWeight: "600", fontSize: 14 }}
                      onClick={() => navigate("/brand/addproduct")}
                    >
                      Add Product
                    </Button>
                  </Nav.Item>
                  <Nav.Item>
                    <Button
                      variant="primary"
                      className="ms-2 p-2 text-white border-0 rounded"
                      style={{ fontWeight: "600", fontSize: 14 }}
                      onClick={() => navigate("/brand/addproject")}
                    >
                      Add Project
                    </Button>
                  </Nav.Item>
                </div>
              )}
              <Nav.Item className="d-flex">
                {!isAuthenticated ? (
                  // <Button
                  //   variant="info"
                  //   className="ms-lg-3 mt-3 mt-lg-0 px-3 py-2 text-secondary border-0 rounded"
                  //   onClick={() => {
                  //     setShowLoginModal(!showLoginModal);
                  //   }}
                  //   style={{ fontSize: 14, fontWeight: "600" }}
                  // >
                  //   Join/Login
                  // </Button>
                  <NavDropdown
                    title="Login As"
                    className="d-flex flex-column justify-content-center ms-lg-3 me-xxl-0 mt-3 mt-lg-0 px-2 text-secondary bg-info"
                    align={{ lg: "end" }}
                    style={{
                      minWidth: !large && 160,
                      minHeight: !medium && 32,
                      borderRadius: 3,
                    }}
                  >
                    <NavDropdown.Item
                      onClick={() => {
                        setIsHomeOwner(true);
                        setShowLoginModal(true);
                      }}
                      className={`body-text2 py-2 px-xxl-2 d-flex justify-content-end ${accHover === "homeowner" ? "text-primary" : "text-gray-900"
                        }`}
                      onMouseEnter={() => setAccHover("homeowner")}
                      onMouseLeave={() => setAccHover(null)}
                    >
                      Homeowner
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => {
                        setIsHomeOwner(false);
                        setShowLoginModal(true);
                      }}
                      className={`border-top body-text2 py-2 px-xxl-2 d-flex justify-content-end ${accHover === "professional" ? "text-primary" : "text-gray-900"
                        }`}
                      onMouseEnter={() => setAccHover("professional")}
                      onMouseLeave={() => setAccHover(null)}
                    >
                      Professional
                    </NavDropdown.Item>
                  </NavDropdown>
                ) : (
                  <NavDropdown
                    title={user?.full_name}
                    className="d-flex flex-column justify-content-center ms-lg-3 me-xxl-0 mt-3 mt-lg-0 px-2 text-secondary bg-gunmetal"
                    align={{ lg: "end" }}
                    style={{
                      minWidth: !large && 160,
                      minHeight: !medium && 32,
                      borderRadius: 3,
                    }}
                  >
                    <NavDropdown.Item
                      onClick={() =>
                        navigate(
                          `${user.user_type === "customer"
                            ? "/user/profile"
                            : user.user_type === "builder"
                              ? "/builder/account/profile"
                              : "/architect/account/profile"
                          }`
                        )
                      }
                      className={`body-text2 py-2 px-xxl-2 d-flex justify-content-end ${accHover === "profile" ? "text-primary" : "text-gray-900"
                        }`}
                      onMouseEnter={() => setAccHover("profile")}
                      onMouseLeave={() => setAccHover(null)}
                    >
                      View Profile
                    </NavDropdown.Item>
                    {user.user_type === "customer" && (
                      <NavDropdown.Item
                        onClick={() => navigate("/user/library")}
                        className={`body-text2 py-2 px-xxl-2 d-flex justify-content-end ${accHover === "library" ? "text-primary" : "text-gray-900"
                          }`}
                        onMouseEnter={() => setAccHover("library")}
                        onMouseLeave={() => setAccHover(null)}
                      >
                        Inspiration Library
                      </NavDropdown.Item>
                    )}
                    {user.user_type === "customer" && (
                      <NavDropdown.Item
                        onClick={() => navigate("/user/projecttracker")}
                        className={`body-text2 py-2 px-xxl-2 d-flex justify-content-end ${accHover === "tracker" ? "text-primary" : "text-gray-900"
                          }`}
                        onMouseEnter={() => setAccHover("tracker")}
                        onMouseLeave={() => setAccHover(null)}
                      >
                        Project Tracker
                      </NavDropdown.Item>
                    )}
                    {user.user_type === "builder" && (
                      <NavDropdown.Item
                        onClick={() => navigate("/builder/properties")}
                        className={`body-text2 py-2 px-xxl-2 d-flex justify-content-end ${accHover === "properties"
                          ? "text-primary"
                          : "text-gray-900"
                          }`}
                        onMouseEnter={() => setAccHover("properties")}
                        onMouseLeave={() => setAccHover(null)}
                      >
                        My Properties
                      </NavDropdown.Item>
                    )}
                    {(user.user_type === "architect" ||
                      user?.user_type === "brand") && (
                        <NavDropdown.Item
                          onClick={() =>
                            navigate(`/${user.user_type}/product-library`)
                          }
                          className={`body-text2 py-2 px-xxl-2 d-flex justify-content-end ${accHover === "properties"
                            ? "text-primary"
                            : "text-gray-900"
                            }`}
                          onMouseEnter={() => setAccHover("properties")}
                          onMouseLeave={() => setAccHover(null)}
                        >
                          Product Library
                        </NavDropdown.Item>
                      )}
                    {user.user_type !== "customer" && (
                      <NavDropdown.Item
                        onClick={() =>
                          navigate(
                            user.user_type === "builder"
                              ? "/builder/account/settings"
                              : `/${user.user_type}/account/settings`
                          )
                        }
                        className={`body-text2 py-2 px-xxl-2 d-flex justify-content-end ${accHover === "settings"
                          ? "text-primary"
                          : "text-gray-900"
                          }`}
                        onMouseEnter={() => setAccHover("settings")}
                        onMouseLeave={() => setAccHover(null)}
                      >
                        Settings
                      </NavDropdown.Item>
                    )}
                    <NavDropdown.Item
                      onClick={handleLogout}
                      className={`border-top body-text2 py-2 px-xxl-2 d-flex justify-content-end ${accHover === "logout" ? "text-primary" : "text-gray-900"
                        }`}
                      onMouseEnter={() => setAccHover("logout")}
                      onMouseLeave={() => setAccHover(null)}
                    >
                      Sign Out
                    </NavDropdown.Item>
                  </NavDropdown>
                )}
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <LoginModal
        showLoginModal={showLoginModal}
        setShowLoginModal={setShowLoginModal}
        isHomeOwner={isHomeOwner}
        setIsHomeOwner={setIsHomeOwner}
      />
    </>
  );
};

export default NavBar;
