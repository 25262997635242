import Slider from "react-slick";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import * as authActions from "reduxFiles/actions/authActions";
import { useSelector } from "react-redux";

const BookingConfirmation = ({ show }) => {
  const navigate = useNavigate();
  const small = useMediaQuery({ query: "(max-width: 768px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const [projects, setProjects] = useState([]);

  const fetchRecommendedProjects = async () => {
    // setLoading(true);
    try {
      const response = await authActions.fetchRecommendedProjects();
      if (response.data) {
        setProjects(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    // setLoading(false);
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 200,
    slidesToShow: 2,
    slidesToScroll: 2,
    nextArrow: null,
    prevArrow: null,
  };

  useEffect(() => {
    fetchRecommendedProjects();
  }, []);

  return (
    <Modal size="lg" show={show}>
      <Modal.Body className="text-center geist p-5">
        <div className="h2 geist-700 text-primary">
          {user?.full_name ? (
            <span className="text-capitalize">{`Hello ${user?.full_name}`}</span>
          ) : (
            "Hello,"
          )}{" "}
          We've booked your free consultation!
        </div>
        <div className="mb-2">
          We will get in touch with you soon! Until then, you can browse through
          our collection of projects!
        </div>
        {projects?.length > 0 && (
          <div className="slider-container my-4">
            <Slider {...settings}>
              {projects?.map((project, index) => (
                <div key={index} className="position-relative">
                  <div
                    className="rounded overflow-hidden p-2"
                    style={{ height: medium ? 300 : 200 }}
                  >
                    <img
                      src={project?.rooms[0]?.media[0]?.file}
                      alt={project?.project_name}
                      className="img-fluid h-100 rounded"
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                  <div
                    className="d-flex body-text2 geist-600 bg-gray position-absolute rounded p-2"
                    style={{ zIndex: 1, bottom: 16, right: 16 }}
                    // width: "35%", bottom: "2%", right: "16%",
                  >
                    {project?.project_name}
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        )}
        <Button
          className="w-100 text-white py-2"
          onClick={() => navigate("/projects")}
        >
          Check our Project Designs!
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default BookingConfirmation;
