import { Card } from "react-bootstrap";
// import { FaStar } from "react-icons/fa6";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

const BrandCard = ({
  id,
  slug,
  companyName,
  image,
  brandCategory,
  warranty,
  rating,
  averagePricing,
}) => {
  const navigate = useNavigate();
  // const small = useMediaQuery({ query: "(min-width: 576px)" });
  // const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  // const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  return (
    <Card
      key={id}
      className="card-primary-hover d-flex p-4 flex-row text-start my-4 border"
      style={{ boxShadow: "none" }}
      onClick={() => navigate(`/brands/${slug}`)}
    // onMouseEnter={() => {}}
    // onMouseLeave={() => {}}
    >
      <Card.Img
        className="bg-white w-50 border p-md-5"
        variant="top"
        src={image}
        // width={large ? 300 : 240}
        height={large ? 200 : 150}
        alt="Brand Img"
        style={{ objectFit: "contain", maxWidth: 300 }}
      />
      <Card.Body className="w-100">
        <Card.Title className="h4 geist-700 text-dark">
          {companyName}
        </Card.Title>

        <div className="body-text2 geist-600">{brandCategory}</div>
        <div>
          <span className="body-text2 geist-600">Warranty: </span>
          {warranty ?? "-"}
        </div>
        <div className="body-text2 geist-600 d-flex align-items-center">
          Ratings: {rating}
          {/* {rating ? (<span> {rating} <FaStar color="#D95656" /></span>) : ("Unavailable")} */}
        </div>
        <div>
          <span className="body-text2 geist-600">Average Pricing: </span>{" "}
          {averagePricing ?? "-"}
        </div>
      </Card.Body>
    </Card>
  );
};

export default BrandCard;
