import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { FaTrash, FaXmark } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import PreviewPropertyCard from "components/Cards/PreviewPropertyCard";
import { useSelector } from "react-redux";
import * as authActions from "reduxFiles/actions/authActions";
import { notify } from "utilities/notifications/Notify";
import { useMediaQuery } from "react-responsive";
import DropFileInput from "components/DragInput/DropFileInput";
import UploadingFiles from "components/DragInput/UploadingFiles";

function AddProperty() {
  const navigate = useNavigate();
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const user = useSelector((state) => state.auth.user);

  const [currentStep, setCurrentStep] = useState(1);
  const propertyInfoRef = useRef();
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [projectTypes, setProjectTypes] = useState([]);
  const [layouts, setLayouts] = useState([
    { id: 1, layout: "", area: "" },
    // layout_file: null
  ]);

  // const [constructionStatuses, setConstructionStatuses] = useState([]);
  // const [selectedFiles, setSelectedFiles] = useState([]);

  const [isFileUploading, setIsFileUploading] = useState(false);
  const [parentId, setParentId] = useState(null);

  const [selectedPropertyImage, setSelectedPropertyImage] = useState(null);
  const [completeProjectFiles, setCompleteProjectFiles] = useState([]);

  const constructionStatuses = [
    { id: 1, title: "Registered" },
    { id: 2, title: "In Construction" },
    { id: 3, title: "Completed" },
  ];

  const fetchProjectTypes = async () => {
    const response = await authActions.fetchProjectTypes();
    try {
      if (response.data) {
        setProjectTypes(response.data?.results);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePropertyImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedPropertyImage(file);
    }
  };

  // const handleCompleteProjectFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     setCompleteProjectFiles(file);
  //   }
  // };
  // const handleFileButtonClick = (blockId, layoutId) => {
  //   if (blockId && layoutId) {
  //     document
  //       .getElementById(`fileInput-block-${blockId}-layout-${layoutId}`)
  //       .click();
  //   } else {
  //     document.getElementById("fileInput").click();
  //   }
  // };

  // const handleMultiFileButtonClick = () => {
  //   document.getElementById("fileInput").click();
  // };

  // const handleCompleteProjectMultiFileChange = (event) => {
  //   const files = Array.from(event.target.files);
  //   setSelectedFiles(files);
  // };

  const addLayout = () => {
    setLayouts([
      ...layouts,
      {
        id: layouts.length + 1,
        layout: "",
        area: "",
        // layout_file: null
      },
    ]);
  };

  const deleteLayout = (index) => {
    setLayouts([...layouts.slice(0, index), ...layouts.slice(index + 1)]);
  };

  const handleInputChange = (layoutId, field, value) => {
    setLayouts(
      layouts.map((layout) =>
        layout.id === layoutId ? { ...layout, [field]: value } : layout
      )
    );
  };
  const joinLayoutString = () => {
    let formattedProperties = [];
    layouts.forEach((layout) =>
      formattedProperties.push(`${layout.layout} (${layout.area})`)
    );
    console.log(formattedProperties);
    return formattedProperties.join(", ");
  };

  const handlePropertyDetails = () => {
    const formData = new FormData(propertyInfoRef.current);
    let isError;
    let data = {};
    formData.forEach((value, key) => {
      if (!value || !selectedPropertyImage) {
        isError = true;
      } else {
        data[key] = value;
      }
    });

    if (isError) {
      notify("Please enter in all the required fields!");
      return;
    }
    console.log(data);
    setPropertyDetails(data);
    setCurrentStep(2);
  };

  const onPropertyFileChange = (files) => {
    console.log(files);
  };

  const handlePropertySubmit = async () => {
    // const propertyData = { property: propertyDetails, resources: blocks };
    const propertyData = {
      property: propertyDetails,
      layouts: layouts,
    };
    const formData = new FormData();
    // blocks.forEach((block) => {
    //   block.layouts.forEach((layout) => {
    //     formData.append(
    //       `fileInput-block-${block.name}-layout-${layout.id}`
    //       // layout.layout_file
    //     );
    //   });
    // });
    formData.append("user", user.id);
    formData.append("data", JSON.stringify(propertyData));
    console.log(selectedPropertyImage);
    formData.append("property_img", selectedPropertyImage);
    try {
      const response = await authActions.createProperty(formData);
      if (response.data) {
        notify("Property Created!", "success");
        setIsFileUploading(true);
        setParentId(response.data.id);
        // navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProjectTypes();
  }, []);

  return (
    <Container fluid className="mt-5 pt-4 px-0 d-flex flex-column justify-content-center align-items-center" style={{ minHeight: "60vh" }}>
      <div className="w-100 bg-info d-flex justify-content-center align-items-center">
        <h1 className="h2 text-primary geist-600 py-3">
          Add a new Property
        </h1>
      </div>
      <div className="d-flex flex-column flex-lg-row my-md-4" style={large ? { maxWidth: 1000 } : {}}>
        <div className="d-flex justify-content-center">
          <div className="d-flex flex-lg-column mt-2 mt-md-4 mt-lg-3 ml-2 mb-1 px-3 px-lg-0">
            <div className="d-flex align-items-center mb-4 me-2">
              <div
                className={`p-1 text-center rounded-btn ${currentStep === 1
                  ? "bg-black text-white"
                  : currentStep === 2 || currentStep === 3
                    ? "bg-success text-white"
                    : "bg-faint"
                  }`}
              >
                {currentStep === 1 ? "1" : <FaCheck size={12} />}
              </div>
              <div
                className={`ms-2 geist-500 body-text1 ${currentStep !== 1 ? "text-success" : ""
                  }`}
              >
                Property Details
              </div>
            </div>
            <div className="d-flex align-items-center mb-4 me-2">
              <div
                className={`p-1 text-center rounded-btn ${currentStep === 2
                  ? "bg-black text-white"
                  : currentStep === 3
                    ? "bg-success text-white"
                    : "text-gunmetal border"
                  }`}
              >
                {currentStep === 1 || currentStep === 2 ? (
                  "2"
                ) : (
                  <FaCheck size={12} />
                )}
              </div>
              <div
                className={`ms-2 geist-500 body-text1 ${currentStep !== 1 && currentStep !== 2 ? "text-success" : currentStep === 2 ? "" : "text-gunmetal"
                  }`}
              >
                Property Files
              </div>
            </div>
            <div className="d-flex align-items-center mb-4 me-2">
              <div
                className={`p-1 text-center rounded-btn ${currentStep === 3 ? "bg-black text-white" : "text-gunmetal border"
                  }`}
              >
                3
              </div>
              <div className={`ms-2 geist-500 body-text1 ${currentStep === 3 ? "text-gray-900" : "text-gunmetal"}`}>Property Preview</div>
            </div>
          </div>
        </div>
        <div className="p-3">
          <div className="p-3 border" style={{ borderRadius: 6 }}>
            <UploadingFiles
              show={isFileUploading}
              files={completeProjectFiles}
              setShow={setIsFileUploading}
              parentId={parentId}
              app_label={"property"}
              model_name={"property"}
            />
            {currentStep === 1 && (
              <div style={large ? { minWidth: 600 } : {}}>
                <h4 className="fw-bold">{currentStep}. Property Details</h4>
                <div className="mt-4">
                  <Form ref={propertyInfoRef}>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 body-text2 geist-500">
                        Property Name<sup style={{ color: "red" }}>*</sup>
                      </Form.Label>
                      <Form.Control
                        required
                        className="py-2 body-text2"
                        type="text"
                        name="property_name"
                        placeholder="Enter property name"
                        defaultValue={propertyDetails?.property_name}
                        style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 body-text2 geist-500">
                        Number of Flats<sup style={{ color: "red" }}>*</sup>
                      </Form.Label>
                      <Form.Control
                        required
                        className="py-2 body-text2"
                        type="text"
                        placeholder="No. of flats"
                        name="flats"
                        defaultValue={propertyDetails?.flats}
                        style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 body-text2 geist-500">
                        Property Year<sup style={{ color: "red" }}>*</sup>
                      </Form.Label>
                      <Form.Control
                        required
                        className="py-2 body-text2"
                        type="text"
                        placeholder="Property year"
                        name="property_year"
                        defaultValue={propertyDetails?.property_year}
                        style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 body-text2 geist-500">
                        Location<sup style={{ color: "red" }}>*</sup>
                      </Form.Label>
                      <Form.Control
                        required
                        className="py-2 body-text2"
                        type="text"
                        placeholder="Enter location"
                        name="location"
                        defaultValue={propertyDetails?.location}
                        style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 body-text2 geist-500">
                        Project Type<sup style={{ color: "red" }}>*</sup>
                      </Form.Label>
                      <Form.Select
                        required
                        className="py-2 body-text2"
                        type="text"
                        placeholder="Enter location"
                        name="project_type"
                        defaultValue={propertyDetails?.project_type}
                        style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                      >
                        <option value="">Select</option>
                        {projectTypes?.map((type, index) => (
                          <option key={index} value={type.id}>
                            {type.title}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 body-text2 geist-500">
                        Construction Status<sup style={{ color: "red" }}>*</sup>
                      </Form.Label>
                      <Form.Select
                        required
                        className="py-2 body-text2"
                        type="text"
                        placeholder="Select Status"
                        name="construction_status"
                        defaultValue={propertyDetails?.construction_status}
                        style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                      >
                        <option value="">Select</option>
                        <option value="UNDER_CONSTRUCTION">
                          Under Construction
                        </option>
                        <option value="COMPLETED">Completed</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 body-text2 geist-500">
                        Property Image<sup style={{ color: "red" }}>*</sup>
                      </Form.Label>
                      {/* <DropFileInput
                        allowMultiple={false}
                        allowOnlyImages={true}
                        className="bg-white border-dark"
                        onFileChange={(files) =>
                          setSelectedPropertyImage(files[0])
                        }
                      /> */}
                      {!selectedPropertyImage ? (
                        <Form.Control
                          required
                          type="file"
                          className="py-2 body-text2"
                          name="property_img"
                          defaultValue={selectedPropertyImage}
                          onChange={handlePropertyImageChange}
                          style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                        // defaultValue={propertyDetails?.property_image}
                        />
                      ) : (
                        <div className="drop-file-preview__item d-flex justify-content-between align-items-start w-100">
                          <div
                            className="rounded overflow-hidden border me-2"
                          // style={{ height: 76, maxWidth: 100 }}
                          >
                            <img
                              src={URL.createObjectURL(selectedPropertyImage)}
                              alt=""
                              className="img-fluid"
                              style={{
                                objectFit: "cover",
                                height: 76,
                                // width: 100,
                              }}
                            />
                          </div>
                          <div className="w-100">
                            {selectedPropertyImage?.name}
                          </div>
                          <div
                            onClick={() => setSelectedPropertyImage(null)}
                            className="drop-file-preview__item__del cursor-pointer bg-gray text-center rounded-circle ms-2"
                          >
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{ width: 40, height: 40 }}
                            >
                              <div className="w-100 mb-1">
                                <FaXmark size={18} className="text-dark" />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Form.Group>
                  </Form>
                  <div className="d-flex">
                    <Button
                      className="d-flex align-items-center text-white body-text2 me-2"
                      style={{ borderRadius: 3 }}
                      onClick={handlePropertyDetails}
                    >
                      Continue
                    </Button>
                    <Button
                      className="bg-transparent border-gray-200 text-gray-900 body-text2"
                      style={{ borderRadius: 3 }}
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {currentStep === 2 && (
              <div style={large ? { minWidth: 600 } : {}}>
                <h4 className="fw-bold">{currentStep}. Add Property Files</h4>
                <div className="my-4">
                  {layouts.map((layout, index) => (
                    // <div className="d-flex align-items-end d-md-block">
                    <div key={index}>
                      <Row className="w-100 mt-2 me-2">
                        <Col xs={5} md={5} className="ps-3">
                          <Form.Group className="mb-3 mb-md-0">
                            <Form.Label className="mb-1 body-text2 geist-500">
                              Layout Type<sup style={{ color: "red" }}>*</sup>
                            </Form.Label>
                            <Form.Control
                              required
                              className="py-2 body-text2"
                              type="text"
                              name="layout"
                              placeholder="2BHK"
                              defaultValue={layout.layout}
                              style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                              onChange={(e) =>
                                handleInputChange(
                                  // block.id,
                                  layout.id,
                                  e.target.name,
                                  e.target.value
                                )
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={6} md={6} className="px-1">
                          <Form.Group className="mb-3 mb-sm-0">
                            <Form.Label className="mb-1 body-text2 geist-500">
                              Layout Area<sup style={{ color: "red" }}>*</sup>
                            </Form.Label>
                            <InputGroup
                              className="border-0"
                              style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                            >
                              <Form.Control
                                required
                                className="py-2 body-text2"
                                type="text"
                                name="area"
                                placeholder="200"
                                defaultValue={layout.area}
                                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                                onChange={(e) =>
                                  handleInputChange(
                                    // block.id,
                                    layout.id,
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              />
                              <InputGroup.Text className="body-text2 border-0 px-1">
                                Sq. Ft.
                              </InputGroup.Text>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        {/* <Col sm={6} md={3} className="d-flex align-items-end">
                            <input
                              type="file"
                              id={`fileInput-block-${block.name}-layout-${layout.id}`}
                              style={{ display: "none" }}
                              name={`layout_file`}
                              onChange={(e) => handleInputChange(block.id, layout.id, e.target.name, e.target.value)}
                            />
                            <div>
                              <Button
                                className="w-100 text-dark bg-gunmetal border-gray-200"
                                onClick={() => handleFileButtonClick(block.name, layout.id)}
                              >
                                <GrAttachment className="me-2" />
                                {layout.layout_file ? layout.layout_file.name : "Attach File"}
                              </Button>
                            </div>
                          </Col> */}
                        <Col xs={1} md={1} className="d-flex jsutify-content-end">
                          {index > 0 && (
                            <div className="d-flex align-items-end">
                              <Button
                                className="d-flex align-items-center rounded-btn bg-gray py-2"
                                onClick={() =>
                                  // deleteLayout(blockIndex, index)
                                  deleteLayout(index)
                                }
                              >
                                <FaTrash size={18} />
                              </Button>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </div>
                  ))}
                  <div
                    className="mt-2 ms-2 body-text3 geist-500 cursor-pointer"
                    onClick={() => addLayout()}
                  >
                    <span style={{ borderBottom: "1px solid #000000" }}>Add another layout</span>
                  </div>
                </div>
                <div>
                  <div className="geist-600">Property Files<sup style={{ color: "red" }}>*</sup></div>
                  <div className="text-gray-600 body-text3 mb-2">Add all layout files relevant to designers here. Try to label them according to standards.</div>
                  <DropFileInput
                    type="property"
                    files={completeProjectFiles}
                    allowMultiple
                    className="bg-white border-dark"
                    onFileChange={(files) => setCompleteProjectFiles(files)}
                  />
                  {/* <div>
                        <input
                          type="file"
                          id="fileInput"
                          style={{ display: "none" }}
                          multiple
                          onChange={handleCompleteProjectMultiFileChange}
                        />
                        <Button
                          className="w-100 text-dark bg-gunmetal border-gray-200 py-2"
                          onClick={handleMultiFileButtonClick}
                        >
                          <GrAttachment className="me-2" />
                          {selectedFiles.length > 0
                            ? selectedFiles.map((file) => file.name).join(", ")
                            : "Attach Complete Project Files"}
                        </Button>
                      </div> */}
                </div>
                <div className="mt-4 d-flex">
                  <Button
                    className="d-flex align-items-center text-white body-text2 me-2"
                    style={{ borderRadius: 3 }}
                    onClick={() => {
                      if (layouts[0].layout && layouts[0].area) {
                        setCurrentStep(3);
                      } else {
                        notify("Atleast one layout is required", "error");
                      }
                    }}
                  >
                    Continue
                  </Button>
                  <Button
                    className="bg-transparent border-gray-200 text-gray-900 body-text2"
                    style={{ borderRadius: 3 }}
                    onClick={() => setCurrentStep(1)}
                  >
                    Back
                  </Button>
                </div>
              </div >
            )}
            {currentStep === 3 && (
              <div style={large ? { minWidth: 600 } : {}}>
                <h4 className="fw-bold">{currentStep}. Property Preview</h4>
                <PreviewPropertyCard
                  property_name={propertyDetails?.property_name}
                  builder={user?.full_name}
                  project_type={
                    projectTypes.find(
                      (type) => type.id === propertyDetails?.project_type
                    )?.title
                  }
                  flats={propertyDetails?.flats}
                  // layout_types={joinLayoutString()}
                  layout_types={layouts}
                  location={propertyDetails?.location}
                  property_year={propertyDetails?.property_year}
                  construction_status={propertyDetails?.construction_status}
                  property_img={
                    selectedPropertyImage
                      ? URL.createObjectURL(selectedPropertyImage)
                      : ""
                  }
                  setCurrentStep={setCurrentStep}
                />
                <div className="mt-4 d-flex">
                  <Button
                    className="d-flex align-items-center text-white body-text2 me-2"
                    style={{ borderRadius: 3 }}
                    onClick={handlePropertySubmit}
                  >
                    Submit Property
                  </Button>
                  <Button
                    className="bg-transparent border-gray-200 text-gray-900 body-text2"
                    style={{ borderRadius: 3 }}
                    onClick={() => setCurrentStep(2)}
                  >
                    Back
                  </Button>
                </div>
              </div>
            )
            }
          </div >
        </div >
      </div >
    </Container >
  );
}

export default AddProperty;
