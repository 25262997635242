import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";

const InspirationMegaMenu = ({ projects, roomTypes }) => {
  const navigate = useNavigate();

  return (
    <div className="body-text2 geist pt-2" style={{ padding: "0px 50px 30px" }}>
      {projects?.length > 0 && (
        <div className="mb-2">
          <div
            className="d-flex px-0 align-items-center geist-600 mb-2 nav-link"
            // onClick={() => navigate("/projects")}
          >
            <a className="nav-link geist-600 geist-600 p-0" href="/projects">
              All Projects <MdKeyboardArrowRight size={24} />
            </a>
          </div>
          <div className="d-flex overflow-scroll scrollbar-none">
            {projects?.slice(0, 4)?.map((item, index) => (
              <div
                key={index}
                className="d-flex flex-column align-items-center cursor-pointer me-4"
                onClick={() => navigate(`/projects/${item.slug}`)}
              >
                <div
                  className="bg-gray rounded overflow-hidden h-100"
                  style={{ width: 128, height: 100 }}
                >
                  <img
                    src={item?.rooms[0]?.media[0]?.file}
                    alt={item?.project_name}
                    style={{ width: 128, height: 100 }}
                  />
                </div>
                <div className="body-text3 nav-link">{item?.project_name}</div>
              </div>
            ))}
          </div>
        </div>
      )}
      {roomTypes && (
        <div>
          <div className="d-flex px-0 align-items-center geist-600">
            <a className="nav-link geist-600 fw-bold p-0" href="/spaces">
              All Spaces <MdKeyboardArrowRight size={24} />
            </a>
          </div>
          <div className="body-text3 d-flex overflow-scroll scrollbar-none">
            {Object.keys(roomTypes)?.map((type, index) => {
              return (
                <div key={index} className="me-5">
                  <div className="body-text2 geist-600 my-2">{type}</div>
                  {roomTypes[type]?.map((item, index) => (
                    <div
                      key={index}
                      className="my-2 hover cursor-pointer"
                      onClick={() => navigate(`/spaces?room-type=${item.slug}`)}
                    >
                      {item.title}
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default InspirationMegaMenu;
