import { ad_1, ad_2 } from "assets";
import * as authActions from "reduxFiles/actions/authActions";
import ProfessionalCard from "components/Cards/ProfessionalCard";
import Pagination from "components/Pagination";
import { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Outlet, useNavigate, useParams } from "react-router-dom";

const ArchitectsList = () => {
  const navigate = useNavigate();
  const { detailedSlug } = useParams();

  const [architects, setArchitects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchArchitects = async (page) => {
    setLoading(true);
    try {
      const response = await authActions.fetchArchitectsPagewise(page);
      if (response.data) {
        setArchitects(response.data?.results);
        setTotalPages(Math.ceil(response.data.count / 12));
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchArchitects(currentPage);
  }, [currentPage]);

  if (detailedSlug) {
    return <Outlet />;
  }

  // useEffect(() => {
  //   if (detailedSlug) {
  //     return <Outlet />;
  //   }
  // }, [detailedSlug]);

  return (
    <Container className="page-padding">
      <section className="d-flex flex-column alignt-items-center">
        <div>
          <div className="h2 geist-600">Our Architects</div>
          <div className="geist">
            We have architects with 15+ years of experience and with 500+
            completed projects
          </div>
        </div>
        <Row className="mb-4">
          <Col md={8}>
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "50vh" }}
              >
                <Spinner />
              </div>
            ) : architects?.length > 0 ? (
              <div className="d-flex flex-column align-items-center w-100">
                {architects?.map((architect, index) => (
                  <ProfessionalCard
                    key={index}
                    {...architect}
                    type={"Architect"}
                    profile_img={architect.user_data?.profile_picture}
                    onClick={() =>
                      navigate(`/professionals/architects/${architect.slug}`)
                    }
                  />
                ))}
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center bg-faint rounded"
                style={{ height: 160 }}
              >
                No architects available
              </div>
            )}
          </Col>
          <Col md={4}>
            <div className="my-4">
              <img className="img-fluid" src={ad_1} alt="Ads" />
            </div>
            <div>
              <img className="img-fluid" src={ad_2} alt="Ads" />
            </div>
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default ArchitectsList;
