import { frame_layout } from "assets";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import InteriorRequirement from "./InteriorRequirement";
import ConsultationRequirement from "./ConsultationRequirement";
import BookConsultationForm from "./BookConsultationForm";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BookingConfirmation from "./BookingConfirmation";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "utilities/notifications/Notify";
import * as authActions from "reduxFiles/actions/authActions";
import { FaStar } from "react-icons/fa6";
import ProjectReferenceCard from "components/Order/ProjectReferenceCard";
import SpaceReferenceCard from "components/Order/SpaceReferenceCard";
import ArchitectReferenceCard from "components/Order/ArchitectReferenceCard";

const Consultation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  console.log(state);
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const form = useRef(null);

  const { refType, refId } = useParams();
  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const [projectType, setProjectType] = useState(""); // for project details only
  const [processEventKey, setProcessEventKey] = useState(
    state?.servicetype ? "project_details" : "requirements"
  );
  const [serviceType, setServiceType] = useState("");
  const [requirement, setRequirement] = useState("");
  // const [requirementType, setRequirementType] = useState(state?.message || null);

  const [selectedRoomType, setSelectedRoomType] = useState([]);
  const [floorPlan, setFloorPlan] = useState("");
  // const [referenceData, setReferenceData] = useState(null);
  const [show, setShow] = useState(false);
  const [area, setArea] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [otpInput, setOtpInput] = useState(false);

  // const fetchData = async () => {
  //   try {
  //     if (refType === "architect") {
  //       const response = await authActions.fetchArchitectsBySlug(refId);
  //       if (response.data) {
  //         setReferenceData(response.data);
  //       }
  //     } else {
  //       const response = await authActions.fetchProjectsByArchitect(refId);
  //       if (response.data) {
  //         const filteredData = referenceData?.find(
  //           (item) => parseInt(refId) === item.id
  //         );
  //         setReferenceData(filteredData);
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleSubmitRequest = async (userId) => {
    setIsLoading(true);
    // const formData = new FormData(form.current);
    const data = {
      user: userId,
      // quoted_amount:
      service_type: serviceType, // turnkey or customised
      requirement: requirement, // complete_home or selected_rooms
      selected_rooms: selectedRoomType.toString, // textfield for now
      project_type: projectType, // charfield: apartment, villa etc
      area: area,
      floorplan: floorPlan, // BHK choices
      type: refType,
      architect: state?.architect?.id,
      project: state?.project?.id,
      room: state?.room?.id,
    };

    console.log(data);
    try {
      const response = await authActions.requestConsultationCallback(data);
      if (response.data) {
        // notify("Book consultation request submitted successfully", "success");
        // navigate("/");
        clearStates();
        setShow(true);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData(form.current);
    if (formData.get("confirm_password") !== formData.get("password")) {
      notify("Passwords did't match!");
      setIsLoading(false);
      return;
    }
    // formData.append("user_type", serviceType);
    formData.append("user_type", "customer");
    let data = {};
    formData.forEach((value, key) => (data[key] = value));
    console.log(data);
    try {
      const response = await authActions.signup(data);
      if (response.data) {
        setOtpInput(true);
      } else if (response?.msg) {
        notify(response.msg);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleContinue = () => {
    // const formData = new FormData(form.current);
    // if(formData.get(""))
    if (projectType) {
      if (projectType === "apartment" || projectType == "villa") {
        if (requirement && floorPlan) {
          if (requirement == "selected_rooms") {
            if (selectedRoomType?.length > 0) {
              isAuthenticated
                ? handleSubmitRequest(user.id)
                : setProcessEventKey("book");
            } else {
              notify("Please select room types for selected rooms", "error");
            }
          } else {
            isAuthenticated
              ? handleSubmitRequest(user.id)
              : setProcessEventKey("book");
          }
        } else {
          notify("Please select both requirement and floor plan", "error");
        }
      } else {
        if (area && area !== 0 && area !== "0") {
          isAuthenticated
            ? handleSubmitRequest(user.id)
            : setProcessEventKey("book");
        } else {
          notify("Please select area", "error");
        }
      }
    } else {
      notify("Please select type of project", "error");
    }
  };

  const clearStates = () => {
    setProjectType("");
    setProcessEventKey("requirements");
    setServiceType("");
    setRequirement("");
    setSelectedRoomType([]);
    setFloorPlan("");
    // setReferenceData(null);
    // setShow(false);
    setArea(0);
    setIsLoading(false);
    setShowLoginModal(false);
    setOtpInput(false);
  };

  useEffect(() => {
    if (state?.servicetype) setServiceType(state.serviceType);
  }, [state]);

  useEffect(() => {
    if ((refId, refType)) {
      console.log("refId, refType: ", refId, refType);
      // fetchData();
    }
  }, [refId, refType]);

  return (
    <>
      <Container fluid className="page-padding p-0">
        <div className="bg-lightpastel py-5 border">
          <div className="h2 geist-600 text-center">
            {`${
              isAuthenticated ? `Hey ${user.full_name},` : ""
            } The jorney to your dream interiors starts here`}
          </div>
        </div>
        <Container>
          <section className="d-flex flex-column align-items-center py-5 mb-5">
            <div
              className="d-flex flex-column align-items-center"
              style={{ width: xlarge ? "72%" : large ? "90%" : "100%" }}
            >
              {state?.room && (
                <div className="d-flex flex-column w-100">
                  <div className="fw-bold mb-2">
                    Selected Inspiration(Space)
                  </div>
                  <SpaceReferenceCard room={state.room} className="bg-white" />
                </div>
              )}
              {state?.project && (
                <div className="d-flex flex-column w-100">
                  <div className="fw-bold mb-2">
                    Selected Inspiration(Project)
                  </div>
                  <ProjectReferenceCard
                    project={state.project}
                    className="bg-white"
                  />
                </div>
              )}
              {state?.architect && (
                <div className="d-flex flex-column w-100">
                  <div className="fw-bold mb-2">Selected Architect</div>
                  <ArchitectReferenceCard
                    architect={state.architect}
                    className="bg-white"
                  />
                </div>
              )}
              <div className="d-flex flex-column align-items-start flex-lg-row justify-content-lg-center align-items-lg-center geist-600 w-100">
                <div
                  className="d-flex flex-column item text-center p-2"
                  style={{
                    color: `${
                      processEventKey === "requirements"
                        ? "#D95656"
                        : processEventKey === "book"
                        ? "#68C858"
                        : "#68C858"
                    }`,
                  }}
                >
                  1. Requirements
                </div>
                <div className="d-none d-lg-inline-block line" />
                <div
                  className="d-flex flex-column item text-center p-2"
                  style={{
                    color: `${
                      processEventKey === "requirements"
                        ? "#667085"
                        : processEventKey == "project_details"
                        ? "#D95656"
                        : "#68C858"
                    }`,
                  }}
                >
                  2. Project Details
                </div>
                {!isAuthenticated && (
                  <div className="d-flex align-items-center">
                    <div className="d-none d-lg-inline-block line" />
                    <div
                      className="d-flex flex-column item text-center p-2"
                      style={{
                        color: `${
                          processEventKey === "book" ? "#D95656" : "#667085"
                        }`,
                      }}
                    >
                      3. Book Free Consultation
                    </div>
                  </div>
                )}
              </div>
              <div>
                <div>
                  {processEventKey === "requirements" && (
                    <div className="my-3">
                      <div className="geist-600 body-text mb-3 px-2">
                        What are you looking for?
                      </div>
                      <ConsultationRequirement
                        className="my-2 mb-4"
                        processEventKey={processEventKey}
                        setProcessEventKey={setProcessEventKey}
                        setServiceType={setServiceType}
                      />
                    </div>
                  )}
                </div>
                <div>
                  {processEventKey === "project_details" && (
                    <div className="my-3">
                      <div className="card p-2 p-md-3 pt-4 pb-5 px-lg-4 my-3">
                        <Row>
                          <Col
                            xs={{ order: 2, span: 12 }}
                            lg={{ order: 1, span: 6 }}
                          >
                            <div className="geist-600 text-start mb-2">
                              Type of Project
                            </div>
                            <div className="mb-3 px-2">
                              <Row className="mb-2 align-items-center">
                                <Col xs={6} className="px-2 mb-2">
                                  <Button
                                    className={`geist-600 w-100 ${
                                      projectType === "apartment"
                                        ? "bg-primary text-white"
                                        : "bg-gray"
                                    }`}
                                    onClick={() => setProjectType("apartment")}
                                  >
                                    Apartment
                                  </Button>
                                </Col>
                                <Col xs={6} className="px-2 mb-2">
                                  <Button
                                    className={`geist-600 w-100 ${
                                      projectType === "independent_land"
                                        ? "bg-primary text-white"
                                        : "bg-gray"
                                    }`}
                                    onClick={() =>
                                      setProjectType("independent_land")
                                    }
                                  >
                                    Independent Land
                                  </Button>
                                </Col>
                                <Col xs={4} className="px-2 mb-2">
                                  <Button
                                    className={`geist-600 w-100 ${
                                      projectType === "office"
                                        ? "bg-primary text-white"
                                        : "bg-gray"
                                    }`}
                                    onClick={() => setProjectType("office")}
                                  >
                                    Offices
                                  </Button>
                                </Col>
                                <Col xs={5} className="px-2 mb-2">
                                  <Button
                                    className={`geist-600 w-100 ${
                                      projectType === "showroom"
                                        ? "bg-primary text-white"
                                        : "bg-gray"
                                    }`}
                                    onClick={() => setProjectType("showroom")}
                                  >
                                    Showrooms
                                  </Button>
                                </Col>
                                <Col xs={3} className="px-2 mb-2">
                                  <Button
                                    className={`geist-600 w-100 ${
                                      projectType === "villa"
                                        ? "bg-primary text-white"
                                        : "bg-gray"
                                    }`}
                                    onClick={() => setProjectType("villa")}
                                  >
                                    Villas
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                            <Row className="px-1">
                              {(projectType && projectType === "apartment") ||
                              projectType === "villa" ? (
                                <InteriorRequirement
                                  setRequirement={setRequirement}
                                  floorPlan={floorPlan}
                                  setFloorPlan={setFloorPlan}
                                  setSelectedRoomType={setSelectedRoomType}
                                />
                              ) : projectType ? (
                                <Col>
                                  <Form.Group
                                    className="d-flex align-items-center px-2 pt-1 bg-light border rounded"
                                    controlId="formRange"
                                  >
                                    <Form.Label className="geist-700 me-2">
                                      Area: {area} Sft
                                    </Form.Label>
                                    <div className="">
                                      <input
                                        type="range"
                                        style={{ width: 150, color: "#d95656" }}
                                        min="0"
                                        max="10000"
                                        step={50}
                                        value={area}
                                        onChange={(event) =>
                                          setArea(event.target.value)
                                        }
                                      />
                                    </div>
                                  </Form.Group>
                                </Col>
                              ) : (
                                ""
                              )}
                            </Row>
                          </Col>
                          <Col
                            xs={{ order: 1, span: 12 }}
                            lg={{ order: 2, span: 6 }}
                          >
                            <div className="d-flex flex-column">
                              <img
                                className="img-card"
                                src={frame_layout}
                                alt="Frame Layout"
                              />
                              <div className="body-text2 geist-400 my-2">
                                Our 600+ design experts use state-of-the-art 3D
                                design technology, to ensure that you get the
                                perfect designs for your home.
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="w-100 d-flex justify-content-start">
                        <Button
                          className="bg-gray geist-600"
                          style={{ width: 100 }}
                          onClick={() => setProcessEventKey("requirements")}
                        >
                          Back
                        </Button>
                        {isAuthenticated ? (
                          <Button
                            variant="primary"
                            className="geist-600 text-white ms-2"
                            style={{ width: 100 }}
                            onClick={() => handleSubmitRequest(user.id)}
                          >
                            Submit
                          </Button>
                        ) : (
                          <Button
                            variant="primary"
                            className="geist-600 text-white ms-2"
                            style={{ width: 100 }}
                            onClick={handleContinue}
                          >
                            Continue
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  {processEventKey === "book" && (
                    <div>
                      <div className="card p-3 my-3">
                        <BookConsultationForm
                          form={form}
                          setShow={setShow}
                          loading={isLoading}
                          showLoginModal={showLoginModal}
                          setShowLoginModal={setShowLoginModal}
                          otpInput={otpInput}
                          // verifyOtpLogin={verifyOtpLogin}
                          handleSubmitRequest={handleSubmitRequest}
                          handleSignUp={handleSignUp}
                        />
                      </div>
                      <div>
                        <Button
                          className="bg-gray geist-600"
                          onClick={() => setProcessEventKey("project_details")}
                        >
                          Back
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </Container>
        <BookingConfirmation show={show} setShow={setShow} />
      </Container>
    </>
  );
};

export default Consultation;
