import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { FaStar } from "react-icons/fa";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import Slider from "react-slick";

import Banners from "./Banners";
import ServiceCard from "./ServiceCard";
import ProjectCard from "components/Cards/ProjectCard";
import TestimonialCard from "components/Cards/TestimonialCard";
import VerticalSlider from "./VerticalSlider";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import { SlBadge } from "react-icons/sl";
import TopInspirations from "./TopInspirations";

import { ArchitectsForDisplay, HowItWorks } from "components";

const Home = () => {
  // const xsmall = useMediaQuery({ query: "(max-width: 576px)" });
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  // const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const navigate = useNavigate();
  const targetRef = useRef(null);

  const [brands, setBrands] = useState(null);
  const [projects, setProjects] = useState(null);
  const [recommendedProjects, setRecommendedProjects] = useState([]);
  const [recommendedArchitects, setRecommendedArchitects] = useState([]);
  const [clientTestimonials, setClientTestimonials] = useState(null);
  const [architectTestimonials, setArchitectTestimonials] = useState(null);
  const [builderTestimonials, setBuilderTestimonials] = useState(null);
  const [brandTestimonials, setBrandTestimonials] = useState(null);

  const card_info = [
    {
      id: 1,
      title: "Project Inspiration",
      description: "Our designs are made for existing and upcoming properties/ builders. Ready to execute!"
    },
    {
      id: 2,
      title: "No intermediaries",
      description: "Interiokeys maintains a direct connection between itself, the craftsmen, manufacturers and the end consumers."
    },
    {
      id: 3,
      title: " Cost Reduction by 20-30%",
      description: "Ready-made designs and multiple brands working directly to reduce interior costs."
    }
  ]

  const fetchBrands = async () => {
    try {
      const response = await authActions.fetchRecommendedBrands();
      if (response.data) {
        setBrands(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProjects = async () => {
    try {
      const response = await authActions.fetchRecommendedProjects();
      if (response.data) {
        setProjects(response.data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRecommendedProjects = async () => {
    // setLoading(true);
    try {
      const response = await authActions.fetchRecommendedProjects();
      if (response.data) {
        setRecommendedProjects(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    // setLoading(false);
  };

  const fetchRecommendedArchitects = async () => {
    // setLoading(true);
    try {
      const response = await authActions.fetchRecommendedArchitects();
      if (response.data) {
        setRecommendedArchitects(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    // setLoading(false);
  };

  const fetchTestimonialsWithType = async () => {
    try {
      const response = await authActions.fetchTestimonialsWithType();
      if (response.data) {
        setClientTestimonials(response.data?.client_testimonials);
        setArchitectTestimonials(response.data?.architect_testimonials);
        setBuilderTestimonials(response.data?.builder_testimonials);
        setBrandTestimonials(response.data?.brand_testimonials);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBrands();
    fetchProjects();
    fetchRecommendedProjects();
    fetchRecommendedArchitects();
    fetchTestimonialsWithType();
  }, []);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + " custom-next-arrow"}
        style={{ ...style, display: "flex", right: -15, zIndex: 1 }}
        onClick={onClick}
      >
        <MdKeyboardArrowRight size={35} />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + " custom-prev-arrow"}
        style={{ ...style, display: "flex", left: -15, zIndex: 1 }}
        onClick={onClick}
      >
        <MdKeyboardArrowLeft size={35} />
      </div>
    );
  }

  const sliderSettings = {
    infinite: true,
    speed: 500,
    cssEase: "linear",
    slidesToShow: large ? 3 : small ? 2 : 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <Container fluid className="page-padding px-0">
      <Container
        fluid
        className="mb-0 p-0 d-flex flex-column justify-content-center align-items-center text-center"
        style={{ minHeight: "100vh" }}
      >
        <Container className="p-0">
          <div className="h1 px-lg-5 geist-600">
            {/* “Collection of <span className="geist-700">real interior designs</span> for new properties of leading builders” */}
            “Discover the Finest Executable Designs for India's Premium Spaces, Crafted by Top Designers”
          </div>
          <div className="text-gray-600 body-title geist-400 my-3">
            {/* Select from a collection of top-notch designs created by our professionals for your home interiors */}
            Explore High-Quality Designs Featuring Top Brands, Expertly Tailored to Your Unique Taste
          </div>
          <Button
            className="text-white mb-5"
            style={{ borderRadius: 3 }}
            onClick={() => navigate("/projects")}
          >
            Explore Inspiration!
          </Button>
          {projects && (
            <Banners
              items={projects?.map((item) => ({
                id: item.id,
                title: item?.project_name,
                image: item?.rooms[0]?.media[0]?.file,
              }))}
              speed={5000}
              type="project"
              height={large ? 320 : medium ? 220 : 200}
              width={large ? 550 : medium ? 335 : 375}
            />
          )}
          <div className="d-flex justify-content-center my-3 w-100">
            {card_info.map((info) => {
              return (
                <Card
                  key={info.id}
                  className={`${info.id === 2 ? "mx-3" : ""} bg-transparent shadow-none`}
                  style={{ border: "1px solid #E8EAED", width: large ? 550 : medium ? 335 : 375 }}
                >
                  <h4 className="geist-600 text-gray-900">
                    {info.title}
                  </h4>
                  <div className="text-gray-600">
                    {info.description}
                  </div>
                </Card>
              )
            })}
          </div>
        </Container>
        <div
          className="h4 geist-600 w-100 mt-3"
          style={{
            // borderTop: "1px solid #D0D5DD",
            paddingTop: "10px",
          }}
        >
          Here's What We Do!
        </div>
        <Button
          variant="primary"
          className="d-flex justify-content-center align-items-center text-center mt-1 p-2"
          style={{ borderRadius: "50% " }}
          ref={targetRef}
          onClick={() =>
            targetRef.current?.scrollIntoView({ behavior: "smooth" })
          }
        >
          <IoIosArrowDown size={24} color="white" />
        </Button>
      </Container>
      <Container
        className="d-flex flex-column justify-content-center align-items-center text-center mb-5"
        style={{ minHeight: "90vh" }}
      >
        <div
          // ref={targetRef}
          className="h2 text-center mx-md-5 geist-600"
          style={{ padding: "0 10%" }}
        >
          “We collaborate with the best brands to create real home interior designs”
          {/* “We collaborate with the{" "}<span className="geist-700">best brands</span> to give you{" "}<span className="geist-700">10k+ real home interior designs”</span> */}
        </div>
        <div className="text-gray-600 body-title geist-400 my-3">
          {/* Premium quality products for your home interiors at reasonable prices! */}
          Reducing premium interior cost by 20-30% to give you the best brands at great prices!
        </div>
        <div>
          <Button
            className="geist-600 text-white mb-4"
            style={{ borderRadius: 3 }}
            onClick={() => navigate("/projects")}
          >
            See our Real Inspirations!
          </Button>
        </div>
        {brands && (
          <Banners
            items={brands?.map((item) => ({
              id: item.id,
              title: item?.company_name,
              image: item?.image,
            }))}
            speed={2000}
            type="brand"
          />
        )}
        <TopInspirations recommendedProjects={recommendedProjects} />
      </Container>
      <ArchitectsForDisplay recommendedArchitects={recommendedArchitects} />
      <HowItWorks />
      <Container className="p-0 d-flex justify-content-center">
        <div className="text-start w-95" style={{ padding: "0 2%" }}>
          <div className="h2 geist-600">Our Services</div>
          <div className="body-title geist-400">
            At Interiokeys, you can{" "}
            <span className="geist-600">
              {" "}
              choose from the 2 types of services provided.
            </span>{" "}
            We recommend going Turnkey!
          </div>
          <ServiceCard />
        </div>
      </Container>
      <Container className="d-flex flex-column justify-content-center align-items-center text-center">
        <div>
          <div className="h2 geist-600">
            Trending Projects in your City!
          </div>
          <Button
            variant="info"
            className="d-inline-flex align-items-center geist-600 text-primary py-2 px-3 my-3"
            style={{ borderRadius: 3, cursor: "default" }}
          >
            <FaStar size={20} className="me-2" />
            Interiokeys Recommended
          </Button>
        </div>
        {projects && (
          <div className="w-100 my-4">
            <Slider {...sliderSettings}>
              {projects?.map((project, index) => (
                <div key={index} className="d-flex justify-content-center p-1">
                  <ProjectCard
                    id={project.id}
                    slug={project.slug}
                    project_name={project.project_name}
                    image={project.rooms[0]?.media[0]?.file}
                    type={`${project.bhk}, ${project.project_area}`}
                    architect={project?.company_name}
                    builders={project.property_data?.builder_data?.company_name}
                    boxShadow="none"
                  />
                </div>
              ))}
            </Slider>
          </div>
        )}
        <Button
          className="body-title geist-600 text-white py-2 px-3 mt-5"
          style={{ borderRadius: 3 }}
          onClick={() => navigate("/projects")}
        >
          Inspirations From Your City
        </Button>
      </Container>
      <Container className="d-flex justify-content-center align-items-center">
        <div className="w-100 mb-5">
          <div className="h2 text-start geist-600 mb-3">
            Client Testimonials!
          </div>
          <Row className="">
            {clientTestimonials && clientTestimonials?.length > 0 ? (
              clientTestimonials?.map((testimonial, index) => (
                <Col key={index} md={4} className="mb-3">
                  <TestimonialCard testimonial={testimonial} />
                </Col>
              ))
            ) : (
              <div
                className="d-flex justify-content-center align-items-center bg-faint rounded"
                style={{ height: 140 }}
              >
                No testimonials available
              </div>
            )}
          </Row>
        </div>
      </Container>
      <Container className="d-flex justify-content-center align-items-center">
        <div className="w-100 mb-5">
          <div className="h2 text-start geist-600 mb-3">
            Partner Testimonials!
          </div>
          <Row>
            <Col
              lg={4}
              md={4}
              className="p-0 d-flex flex-column justify-content-center align-items-center"
            >
              {builderTestimonials && (
                <VerticalSlider items={builderTestimonials} rtl={false} />
              )}
              <span className="geist-600 mt-4">Builders</span>
            </Col>
            <Col
              lg={4}
              md={4}
              className="p-0 d-flex flex-column justify-content-center align-items-center"
            >
              {architectTestimonials && (
                <VerticalSlider items={architectTestimonials} rtl={true} />
              )}
              <span className="geist-600 mt-4">Architects</span>
            </Col>
            <Col
              lg={4}
              md={4}
              className="p-0 d-flex flex-column justify-content-center align-items-center"
            >
              {brandTestimonials && (
                <VerticalSlider items={brandTestimonials} rtl={false} />
              )}
              <span className="geist-600 mt-4">Brands</span>
            </Col>
          </Row>
          {/* <Row className="d-flex justify-content-between">
            {brandTestimonials && brandTestimonials?.length > 0 ? (
              brandTestimonials?.map((testimonial, index) => (
                <Col key={index} md={4} className="mb-3">
                  <TestimonialCard testimonial={testimonial} />
                </Col>
              ))
            ) : (
              <div
                className="d-flex justify-content-center align-items-center bg-faint rounded"
                style={{ height: 140 }}
              >
                No testimonials available
              </div>
            )}
          </Row> */}
        </div>
      </Container>
    </Container>
  );
};

export default Home;
