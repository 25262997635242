import { ad_1, interiokeys_logo, professional_icon } from "assets";
import * as authActions from "reduxFiles/actions/authActions";
import PropertyCard from "components/Cards/PropertyCard";
import Pagination from "components/Pagination";
import { useEffect, useState } from "react";
import { Button, Col, Container, Image, Modal, Row, Spinner } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaBuildingColumns } from "react-icons/fa6";
import { useMediaQuery } from "react-responsive";

const BuilderProperties = () => {
  const user = useSelector((state) => state.auth.user);
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const isLargeScreen = useMediaQuery({
    query: "(min-width: 992px) and (max-width: 1300px)",
  });

  const [loading, setLoading] = useState(false);
  const [activeProperty, setActiveProperty] = useState(true);
  const [properties, setProperties] = useState([]);
  const [thisPropertyId, setThisPropertyId] = useState(null);
  const [archivedProperties, setArchivedProperties] = useState([]);
  const [count, setCount] = useState(0)
  const [archivedCount, setArchivedCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [archiveModal, setArchiveModal] = useState(false);
  const navigate = useNavigate();

  const fetchActiveProperties = async (page) => {
    setLoading(true);
    try {
      const response = await authActions.fetchPropertiesByBuilderUser(user.id, page);
      if (response.data) {
        setProperties(response.data?.results);
        setCount(response.data.count)
        setTotalPages(Math.ceil(response.data.count / 12));
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchArchivedProperties = async (page) => {
    setLoading(true);
    try {
      const response = await authActions.fetchArchivedPropertiesByBuilderUser(user.id, page);
      if (response.data) {
        setArchivedProperties(response.data?.results);
        setArchivedCount(response.data.count)
        setTotalPages(Math.ceil(response.data.count / 12));
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const requestArchive = async (propertyId) => {
    try {
      const response = await authActions.archivePropertyRequest(propertyId, { archive_request: true });
      if (response.data) {
        setArchiveModal(false);
        fetchActiveProperties();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchActiveProperties(currentPage);
    fetchArchivedProperties(currentPage);
  }, []);

  useEffect(() => {
    if (activeProperty) {
      fetchActiveProperties(currentPage);
    } else {
      fetchArchivedProperties(currentPage);
    }
  }, [currentPage, activeProperty]);

  return (
    <Container
      fluid
      className="mt-4 pt-5 px-0"
      style={{ minHeight: "100vh" }}
    >
      <div className="bg-faint border-0">
        <Container className="py-4">
          <div className="h3 geist-600">{user?.full_name} Properties ({count})</div>
          <div className="body-title">
            All your properties will be listed here.
          </div>
        </Container>
      </div>
      <Container className="py-3">
        <div className="body-text1 pt-5 d-flex">
          <div className="me-2 cursor-pointer" onClick={() => setActiveProperty(true)}>
            <div className={`my-1 mx-2 geist-600 ${activeProperty ? "text-primary" : "text-gunmetal"}`}>Listed Properties ({count})</div>
            <div className={`mt-1 w-100 ${activeProperty ? "bg-primary" : "bg-gunmetal"}`} style={{ height: 2 }} />
          </div>
          <div className="me-2 cursor-pointer" onClick={() => setActiveProperty(false)}>
            <div className={`my-1 mx-2 geist-600 ${activeProperty ? "text-gunmetal" : "text-primary"}`}>Archived Properties ({archivedCount})</div>
            <div className={`mt-1 w-100 ${activeProperty ? "bg-gunmetal" : "bg-primary"}`} style={{ height: 2 }} />
          </div>
          {medium && <div style={{ flexGrow: 1 }}>
            <div className="my-1 text-white" style={{ userSelect: "none" }}>0</div>
            <div className="bg-gunmetal mt-1 w-100" style={{ height: 2 }} />
          </div>}
        </div>
        <Row>
          <Col lg={9}>
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "50vh" }}
              >
                <Spinner />
              </div>
            ) : (
              <>
                {activeProperty ?
                  properties.length > 0 ?
                    <>
                      {properties?.map((property, index) => (
                        <PropertyCard
                          key={index}
                          {...property}
                          builder={property.builder_data.company_name}
                          project_type={property.project_type_data.title}
                          setArchiveModal={setArchiveModal}
                          setThisPropertyId={setThisPropertyId}
                        />
                      ))}
                      <div className="d-flex justify-content-center">
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          setCurrentPage={setCurrentPage}
                        />
                      </div>
                    </>
                    :
                    <div className="py-4 d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '70vh' }}>
                      <div className="h2 geist-600">Hey, {user?.full_name} Builders!</div>
                      <div
                        className="d-flex justify-content-center align-items-center my-3"
                        style={{ backgroundColor: "#E8EAED", width: 100, height: 86, borderRadius: 6 }}
                      >
                        {/* <Image src={professional_icon} style={{ width: 50 }} /> */}
                        <FaBuildingColumns size={30} />
                      </div>
                      <div className="h3 geist-600">Start by adding your first property.</div>
                      <div className="body-title text-gray-600">
                        Add your first property so that designers can start creating inspiration!
                      </div>
                      <div className="mt-3">
                        <Button
                          className="d-flex align-items-center text-white"
                          style={{ borderRadius: 3 }}
                          onClick={() => navigate("/builder/addproperty")}
                        >
                          <FaPlus className="me-2" /> Add Property
                        </Button>
                      </div>
                    </div>
                  :
                  archivedProperties.length > 0 ?
                    <>
                      {archivedProperties?.map((property, index) => (
                        <PropertyCard
                          key={index}
                          {...property}
                          builder={property.builder_data.company_name}
                          project_type={property.project_type_data.title}
                          setActiveProperty={setActiveProperty}
                        />
                      ))}
                      <div className="d-flex justify-content-center">
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          setCurrentPage={setCurrentPage}
                        />
                      </div>
                    </>
                    :
                    <div className="py-4 d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '70vh' }}>
                      <div className="h2 geist-600">Hey, {user?.full_name} Builders!</div>
                      <div
                        className="d-flex justify-content-center align-items-center my-3"
                        style={{ backgroundColor: "#E8EAED", width: 100, height: 86, borderRadius: 6 }}
                      >
                        {/* <Image src={professional_icon} style={{ width: 50 }} /> */}
                        <FaBuildingColumns size={30} />
                      </div>
                      <div className="h3 geist-600">There are no Archived properties.</div>
                      <div className="body-title text-gray-600">
                        Add your first property so that designers can start creating inspiration!
                      </div>
                      <div className="mt-3">
                        <Button
                          className="d-flex align-items-center text-white"
                          style={{ borderRadius: 3 }}
                          onClick={() => navigate("/builder/addproperty")}
                        >
                          <FaPlus className="me-2" /> Add Property
                        </Button>
                      </div>
                    </div>
                }
              </>
            )}
          </Col>
          <Col lg={3}>
            <div className="my-4 position-relative">
              <div className="w-100">
                <img className="img-fluid w-100" src={ad_1} alt="Ads" />
              </div>
              <div className="position-absolute" style={{ top: 10, right: 10 }}>
                <Button variant="info" className="text-secondary fw-bold">
                  AD
                </Button>
              </div>
            </div>
            {/* <div className="my-4 position-relative" style={{ backgroundColor: "#F1F3F4", minHeight: '50vh', borderRadius: 6 }} >
              <div className="position-absolute" style={{ top: 10, right: 10 }}>
                <Button variant="info" className="text-secondary fw-bold">
                  AD
                </Button>
              </div>
            </div> */}
          </Col>
        </Row>
      </Container>
      <Modal
        className="d-flex justify-content-center align-items-center"
        dialogClassName="custom-width-modal"
        show={archiveModal}
        onHide={() => {
          setArchiveModal(false);
        }}
      >
        <div className="py-3 px-4">
          <Modal.Body className="p-0">
            <div className="d-flex flex-column justify-content-center align-items-center text-center">
              <div
                className="geist-700 text-gray-900"
                style={{ fontSize: "30px", color: "black" }}
              >
                <Image
                  src={interiokeys_logo}
                  alt="Interiokeys logo"
                  style={{ maxWidth: !isLargeScreen ? 180 : 150 }}
                />
              </div>
              <div className="body-text2 text-gray-600">
                Request Archive
              </div>
              <div className="body-text1 text-gray-800 py-4">
                Do you want to send a request to Archive this Property?
              </div>
              <div className="d-flex">
                <Button
                  className="d-flex align-items-center text-white body-text2 me-2"
                  style={{ borderRadius: 3 }}
                  onClick={() => {
                    requestArchive(thisPropertyId);
                  }}
                >
                  Send
                </Button>
                <Button
                  className="bg-transparent border-gray-200 text-gray-900 body-text2"
                  style={{ borderRadius: 3 }}
                  onClick={() => setArchiveModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </Container>
  );
};

export default BuilderProperties;
