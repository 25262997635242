import ProductListCard from "components/Cards/ProductListCard";
import Pagination from "components/Pagination";
import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { CiSearch } from "react-icons/ci";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";

const ProductLibrary = () => {
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param1 = params.length > 0 ? params[0] : "";

  const user = useSelector((state) => state.auth.user);

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchProducts = async (page) => {
    setLoading(true);
    try {
      const response = await authActions.fetchProductsPagewise(page);
      if (response.data) {
        setProducts(response.data?.results);
        setTotalPages(Math.ceil(response.data.count / 12));
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchProductsByBrand = async (page) => {
    setLoading(true);
    try {
      const response = await authActions.fetchProductsByBrandUser(
        page,
        user?.id
      );
      if (response.data) {
        setProducts(response.data?.results);
        setTotalPages(Math.ceil(response.data.count / 12));
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (param1 === "architect") {
      fetchProducts(currentPage);
    } else if (param1 === "brand") {
      fetchProductsByBrand(currentPage);
    }
  }, [currentPage, param1, user]);

  return (
    <div className="page-padding pb-4">
      <Container>
        <div className="body-title geist-700 mb-2">
          {products?.length} Branded products available
        </div>
        <Form.Group>
          <InputGroup>
            <InputGroup.Text
              style={{
                border: 0,
                borderLeft: "1px solid #98A2B3",
                borderTop: "1px solid #98A2B3",
                borderBottom: "1px solid #98A2B3",
                borderRadius: "8px 0px 0px 8px",
                backgroundColor: "white",
              }}
            >
              <CiSearch />
            </InputGroup.Text>
            <Form.Control
              className="py-2 ps-0"
              style={{
                border: 0,
                borderRight: "1px solid #98A2B3",
                borderTop: "1px solid #98A2B3",
                borderBottom: "1px solid #98A2B3",
                borderRadius: "0px 8px 8px 0px",
              }}
              size="sm"
              placeholder="Search for projects, images, etc"
            />
          </InputGroup>
        </Form.Group>
        <Row className="my-4">
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "50vh" }}
            >
              <Spinner />
            </div>
          ) : products?.length > 0 ? (
            products.map((product, index) => (
              <Col
                key={index}
                lg={3}
                md={6}
                sm={12}
                className="d-flex flex-collumn mb-3"
              >
                <ProductListCard
                  {...product}
                  image={
                    product.media[0]?.file ?? product.product_type_data?.image
                  }
                  product_type={product.product_type_data?.title}
                  className="border flex-grow"
                />
              </Col>
            ))
          ) : (
            <div
              className="d-flex justify-content-center align-items-center bg-faint rounded"
              style={{ height: 160 }}
            >
              No products available
            </div>
          )}
        </Row>
        <div className="d-flex justify-content-center w-100">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </Container>
    </div>
  );
};

export default ProductLibrary;
