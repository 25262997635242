import { interiokeys_logo } from "assets";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";

const Footer = () => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const navigate = useNavigate();

  const [projects, setProjects] = useState([]);
  const [rooms, setRooms] = useState([]);
  // const [roomSubtypes, setRoomSubtypes] = useState([]);
  const [roomCategories, setRoomCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  // const [professionals, setProfessionals] = useState();
  const [professionalTypes, setProfessionalTypes] = useState([]);

  const fetchProjects = async () => {
    try {
      const response = await authActions.fetchRecommendedProjects();
      if (response.data) {
        setProjects(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const fetchRoomSubtypes = async () => {
  //   try {
  //     const response = await authActions.fetchRoomSubtypes();
  //     if (response.data) {
  //       setRoomSubtypes(response.data?.results);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const fetchRoomCategories = async () => {
    try {
      const response = await authActions.fetchRoomCategoriesData();
      if (response.data) {
        setRoomCategories(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBrands = async () => {
    try {
      const response = await authActions.fetchRecommendedBrands();
      if (response.data) {
        setBrands(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProfessionalTypes = async () => {
    try {
      const response = await authActions.fetchProfessionalTypes();
      if (response.data) {
        setProfessionalTypes(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const fetchProfessionals = async () => {
  //   const response = await authActions.fetchRecommendedProfessionalTypes();
  //   if (response.data) {
  //     setProfessionals(response.data?.results);
  //   }
  // };

  useEffect(() => {
    fetchProjects();
    // fetchRoomSubtypes();
    fetchRoomCategories();
    fetchBrands();
    fetchProfessionalTypes();
  }, []);

  return (
    <footer className="footer">
      <Container>
        <Row className="justify-content-between py-5">
          <Col>
            <div className="h3 geist-700">
              <Image
                src={interiokeys_logo}
                alt="Interiokeys logo"
                style={{ maxWidth: 150 }}
              />
            </div>
            <div className="mt-4">
              <div className="body-title geist-700 text-primary">
                Want to understand how we execute a project?
              </div>
              <div className="geist body-text1 mb-2">
                Get our free PDF sent to your Email ID to understand step by
                step all the phases of interior project we take up.
              </div>
              <div className="body-text2 d-flex flex-row border rounded p-1 mb-3 bg-white">
                <Form.Control
                  className="body-text2 geist w-50 flex-grow border-0"
                  size="sm"
                  placeholder="name@gmail.com"
                  style={{
                    padding: "10px",
                  }}
                ></Form.Control>
                <Button size="sm" className="text-white" style={{ width: 100 }}>
                  Send PDF
                </Button>
              </div>
            </div>
            <div className="body-title geist-700 mt-2">
              <div className="mb-2">Follow us on</div>
              <div>
                <FaFacebook size={24} color="#D95656" />
                <FaInstagram className="mx-3" size={24} color="#D95656" />
                <FaYoutube className="me-3" size={24} color="#D95656" />
                <FaLinkedin size={24} color="#D95656" />
              </div>
            </div>
            <Row className="mt-3 body-text1">
              <Col>
                <div className="geist-700 text-primary">Company</div>
                <div>
                  <div className="cursor-pointer">About us</div>
                  <div className="cursor-pointer">Contact us</div>
                  <div className="cursor-pointer">Terms & Privacy</div>
                  <div className="cursor-pointer">Our Blog</div>
                  <div className="cursor-pointer">Careers</div>
                  <div className="cursor-pointer">Copyright & Trademark</div>
                </div>
              </Col>
              <Col>
                <div className="geist-700 text-primary">Partner With Us</div>
                <div>
                  <div className="cursor-pointer">Join as Professional</div>
                  <div className="cursor-pointer">Join as Builder</div>
                  <div className="cursor-pointer">Join as Brand Partner</div>
                </div>
              </Col>
              <Col>
                <div className="geist-700 text-primary">Services</div>
                <div>
                  <div className="geist-500">Book Free Consultation</div>
                  <div className="cursor-pointer">See Real Inspirations</div>
                  <div className="cursor-pointer">Buy Project Drawings</div>
                </div>
              </Col>
            </Row>
          </Col>
          {small && (
            <Col className="body-text1 d-flex justify-content-end">
              <Row>
                <Col>
                  <div className="text-primary geist-700 mb-1">
                    Inspirations
                  </div>
                  <div className="mb-3">
                    <div
                      className="text-primary geist-600 mb-2"
                      onClick={() => navigate("/projects")}
                    >
                      Projects
                    </div>
                    {projects?.map((item, index) => (
                      <div key={index} className="cursor-pointer">
                        {item?.project_name}
                      </div>
                    ))}
                  </div>
                  <div>
                    <div
                      className="text-primary geist-600 mb-2"
                      onClick={() => navigate("/spaces")}
                    >
                      Spaces
                    </div>
                    {/* {rooms?.map((item, index) => (
                    <div key={index}>{item?.title}</div>
                  ))} */}
                    {roomCategories?.map((item, index) => (
                      <div
                        key={index}
                        className="cursor-pointer"
                        onClick={() =>
                          navigate(`/spaces?room-caterogry=${item.slug}`)
                        }
                      >
                        {item?.title}
                      </div>
                    ))}
                  </div>
                </Col>
                <Col>
                  <div
                    className="text-primary geist-700 mb-2"
                    onClick={() => navigate("/brands")}
                  >
                    Brands
                  </div>
                  {brands?.map((item, index) => (
                    <div
                      key={index}
                      className="cursor-pointer"
                      onClick={() => navigate(`/brands/${item.slug}`)}
                    >
                      {item?.company_name}
                    </div>
                  ))}
                </Col>
                <Col>
                  <div
                    className="text-primary geist-700 mb-2"
                    onClick={() => navigate("/professionals")}
                  >
                    Professionals
                  </div>
                  {professionalTypes?.map((item, index) => (
                    <div
                      key={index}
                      className="cursor-pointer"
                      onClick={() => navigate(`/professionals/${item.slug}`)}
                    >
                      {item?.title}
                    </div>
                  ))}
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
