import { default_pfp, user_pfp } from "assets";
import AccountEditForm from "components/Forms/AccountEditForm";
import { useSelector } from "react-redux";

const ProfessionalPersonalProfile = () => {
  const user = useSelector((state) => state.auth.user);

  return (
    <div>
      <div className="d-flex flex-column bg-faint border-bottom">
        <div className="d-flex align-items-center pb-4">
          <div>
            <img
              style={{
                border: "1px solid black",
                borderRadius: "50%",
                objectFit: "cover",
              }}
              width={150}
              height={150}
              src={user?.profile_picture || default_pfp}
              alt="avatar"
            />
          </div>
          <div className="geist-600 ms-5" style={{ fontSize: "30px" }}>
            {user?.name}
          </div>
        </div>
      </div>
      <div className="py-4">
        <AccountEditForm />
      </div>
    </div>
  );
};

export default ProfessionalPersonalProfile;
