import React from "react";

const SpaceReferenceCard = ({ room, className, style }) => {
  return (
    <div
      className={`d-flex rounded border p-2 mb-3 ${className}`}
      style={style}
    >
      <div className="me-3">
        <img
          className="img-fluid rounded overflow-hidden"
          variant="top"
          src={room?.media[0]?.file}
          style={{ maxHeight: 120, minHeight: 120, objectFit: "cover" }}
        />
      </div>
      <div className="body-text2 pe-3">
        <div className="geist-700 mb-0" style={{ fontSize: 18 }}>
          {`${room?.room_type_data?.title}${
            room?.project_data?.property_name
              ? `/${room?.project_data?.property_name}`
              : ""
          }`}
        </div>
        <div className="geist-500 d-flex flex-column align-items-start">
          {room?.project_data && (
            <span className="ms-0">
              Type:{" "}
              {`${room?.project_data.bhk}, ${room?.project_data.project_area}`}
            </span>
          )}
        </div>
        <div className="geist-500 d-flex flex-column align-items-start">
          {room?.room_data && (
            <span className="ms-0">Area: {room?.room_data.area}</span>
          )}
        </div>
        <div className="geist-500 d-flex flex-column align-items-start">
          {room?.project_data && (
            <span className="ms-0">
              Project: {room?.project_data?.project_name}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default SpaceReferenceCard;
