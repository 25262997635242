import React from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";

const BrandMegaMenu = ({ brands, brandCategories }) => {
  const navigate = useNavigate();

  return (
    <div className="body-text2 geist pt-2" style={{ padding: "0px 50px 30px" }}>
      {brands && (
        <div className="mb-2">
          <div className="d-flex px-0 align-items-center geist-600 mb-2 nav-link">
            <a className="nav-link geist-600 fw-bold p-0" href="/brands">
              All Brands <MdKeyboardArrowRight size={24} />
            </a>
          </div>
          <div className="d-flex overflow-scroll scrollbar-none">
            {brands.map((item, index) => (
              <div
                key={index}
                className="d-flex flex-column align-items-center cursor-pointer me-5"
                style={{ maxWidth: 100 }}
                onClick={() => navigate(`/brands/${item.slug}`)}
              >
                <img src={item?.image} alt="" className="img-fluid" />
                <div className="body-text3 nav-link">{item.name}</div>
              </div>
            ))}
          </div>
        </div>
      )}
      {brandCategories && (
        <div>
          <div className="px-0 geist-600 fw-bold mb-2">
            Timber, Plywood and Laminates
          </div>
          <div
            className="body-text3 d-flex overflow-scroll scrollbar-none"
            style={{ whiteSpace: "nowrap" }}
          >
            {Object.keys(brandCategories)?.map((category, index) => {
              return (
                <div key={index} className="me-5">
                  <div className="body-text2 fw-bold geist-600 my-2">
                    {category}
                  </div>
                  {brandCategories[category]?.map((item, index) => (
                    <div
                      key={index}
                      className="my-2 hover cursor-pointer"
                      onClick={() => navigate(`/brands/${item.slug}`)}
                    >
                      {item?.company_name}
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default BrandMegaMenu;
