import React from "react";
import { FaStar } from "react-icons/fa6";

const ArchitectReferenceCard = ({ architect, className, style }) => {
  return (
    <div
      className={`d-flex rounded border p-2 mb-3 ${className}`}
      style={style}
    >
      <div className="me-3">
        <img
          className="img-fluid rounded overflow-hidden"
          variant="top"
          src={architect?.user_data?.profile_picture}
          style={{ maxHeight: 120, minHeight: 120, objectFit: "cover" }}
        />
      </div>
      <div className="body-text2 pe-3">
        <div className="geist-700" style={{ fontSize: 18 }}>
          {architect?.user_data?.full_name}
        </div>
        <div className="geist-500">{architect?.company_name}</div>
        <div className="geist-500">{architect?.location}</div>
        <div className="geist-500 d-flex align-items-center">
          Ratings:{" "}
          {architect?.rating ? (
            <div className="d-flex align-items-center">
              {architect?.rating} <FaStar color="#D95656" className="ms-1" />
            </div>
          ) : (
            "Unavailable"
          )}
        </div>
        <div
          className="geist-500 d-flex flex-column align-items-start"
          style={{ textOverflow: "ellipsis" }}
        >
          {architect?.project_names ?? `${architect?.project_names} /`}{" "}
          Architect
          <div className="geist-400 d-flex flex-column align-items-start">
            {architect?.architect && (
              <span className="ms-0">Architect: {architect?.architect}</span>
            )}
          </div>
          <div className="geist-400 d-flex flex-column align-items-start">
            {architect?.builders && (
              <span className="ms-0">Builders: {architect?.builders}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArchitectReferenceCard;
