import React, { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import ListedProperties from "./ListedProperties";
import MyProjects from "./MyProjects";
import { Outlet, useParams } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import { useSelector } from "react-redux";

const ArchitectProjects = () => {
  const { propertyId } = useParams();
  const user = useSelector((state) => state.auth.user);

  const [isProperty, setIsProperty] = useState(true);
  const [properties, setProperties] = useState([]);
  const [propertyCount, setPropertyCount] = useState(0);
  const [projects, setProjects] = useState([]);
  const [projectCount, setProjectsCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchArchitectProjects = async () => {
    try {
      const response = await authActions.fetchProjectsByArchitectUser(user.id);
      if (response.data) {
        setProjects(response.data?.results);
        setProjectsCount(response.data?.count);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchArchitectProjects();
  }, []);

  if (propertyId) {
    return <Outlet />;
  }

  return (
    <Container
      fluid
      className="mt-4 pt-5 px-0"
      style={{ minHeight: "100vh" }}
    >
      <div className="bg-faint border-0">
        <Container className="py-4">
          {isProperty ?
            <>
              <div className="h3 geist-600">Property Listing ({propertyCount})</div>
              <div className="body-title">
                See all the projects available to create designs!
              </div>
            </>
            :
            <>
              <div className="h3 geist-600">My Projects ({projectCount})</div>
              <div className="body-title">
                See all the your created designs here!
              </div>
            </>
          }
        </Container>
      </div>
      <Container className="py-3">
        <div className="body-text1 pt-5 d-flex">
          <div className="me-2 cursor-pointer" onClick={() => setIsProperty(true)}>
            <div className={`my-1 mx-2 geist-600 ${isProperty ? "text-primary" : "text-gunmetal"}`}>Listed Properties ({propertyCount})</div>
            <div className={`mt-1 w-100 ${isProperty ? "bg-primary" : "bg-gunmetal"}`} style={{ height: 2 }} />
          </div>
          <div className="me-2 cursor-pointer" onClick={() => setIsProperty(false)}>
            <div className={`my-1 mx-2 geist-600 ${isProperty ? "text-gunmetal" : "text-primary"}`}>My Projects ({projectCount})</div>
            <div className={`mt-1 w-100 ${isProperty ? "bg-gunmetal" : "bg-primary"}`} style={{ height: 2 }} />
          </div>
          <div style={{ flexGrow: 1 }}>
            <div className="my-1 text-white" style={{ userSelect: "none" }}>0</div>
            <div className="bg-gunmetal mt-1 w-100" style={{ height: 2 }} />
          </div>
        </div>
        {isProperty ?
          <ListedProperties
            companyName={user.full_name}
            properties={properties}
            setProperties={setProperties}
            setPropertyCount={setPropertyCount}
          />
          :
          <MyProjects
            companyName={user.full_name}
            projects={projects}
            setProjects={setProjects}
            loading={loading}
            setLoading={setLoading}
          />
        }
      </Container>
    </Container>
  );
};

export default ArchitectProjects;
