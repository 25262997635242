import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import PropertyDetailsForm from "pages/Builder/Property/PropertyDetailsForm";
import * as authActions from "reduxFiles/actions/authActions";
import { notify } from "utilities/notifications/Notify";

const AddPropertyModal = ({
  show,
  setShow,
  setCustomProperty,
  setShowAddProperty,
}) => {

  const propertyInfoRef = useRef();
  const [projectTypes, setProjectTypes] = useState([]);

  const fetchProjectTypes = async () => {
    try {
      const response = await authActions.fetchProjectTypes();
      if (response.data) {
        setProjectTypes(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePropertySubmit = async () => {
    const formData = new FormData(propertyInfoRef.current);
    let isError = "";
    let data = {};
    formData.forEach((value, key) => {
      //   if (!value || !selectedPropertyImage) {
      if (!value) {
        isError = true;
      } else {
        data[key] = value;
      }
    });
    if (isError) {
      notify("Please enter in all the required fields!");
      return;
    }
    console.log(data);
    const propertyData = {
      property: data,
      layouts: [{ layout: data?.layout, area: data?.layout_area }],
    };
    // formData.append("data", JSON.stringify(propertyData));
    // formData.append("property_image", selectedPropertyImage);
    const finalFormData = new FormData();
    finalFormData.append("data", JSON.stringify(propertyData));
    try {
      const response = await authActions.createProperty(finalFormData);
      if (response.data) {
        notify("Property Created!", "success");
        setShowAddProperty(false);
        setCustomProperty(response.data);
        setShow(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProjectTypes();
  }, []);

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Container>
        <Modal.Header closeButton>
          <Modal.Title className="geist-700">Add New Property</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form ref={propertyInfoRef}>
              <PropertyDetailsForm
                projectTypes={projectTypes}
              />
              <Row>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-1 body-text2 geist-500">
                      Layout<sup style={{ color: "red" }}>*</sup>
                    </Form.Label>
                    <Form.Control
                      required
                      className="py-2 body-text2"
                      type="text"
                      placeholder="2BHK"
                      name="layout"
                      style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                    />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-1 body-text2 geist-500">
                      Layout Area<sup style={{ color: "red" }}>*</sup>
                    </Form.Label>
                    <div style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}>
                      <InputGroup className="border-0">
                        <Form.Control
                          required
                          className="py-2 body-text2 border-0"
                          type="text"
                          name="layout_area"
                        />
                        <InputGroup.Text className="body-text2 border-0">
                          Sq Ft
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <div className="my-2">
                <Button
                  className="text-white body-text2 w-100"
                  style={{ borderRadius: 3 }}
                  onClick={handlePropertySubmit}
                >
                  Add Property
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Container>
    </Modal>
  );
};

export default AddPropertyModal;
