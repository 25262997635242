import { useRef } from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";

// items should contain image and title
const Banners = ({ items, speed, type, width, height }) => {
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  let sliderRef = useRef(null);
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: speed,
    autoplaySpeed: speed,
    cssEase: "linear",
    slidesToShow: large ? (type === "project" ? 3 : 6) : 1,
    slidesToScroll: 1,
  };

  const truncateTitle = (title) => {
    if (title.length <= 15) {
      return title;
    }
    return `${title.substring(0, 13)}...`;
  };

  return (
    <Container>
      {type === "brand" ? (
        <Row className="mb-4">
          <Slider
            ref={(slider) => {
              sliderRef = slider;
            }}
            {...settings}
          >
            {items.map((item, index) => (
              <Col key={index} className="pe-3">
                <Card className="card d-flex justify-content-center align-items-center my-2 p-3">
                  <img
                    src={item?.image}
                    alt={item?.title}
                    height={type === "project" ? height : ""}
                    width={type === "project" ? width : ""}
                  />
                </Card>
              </Col>
            ))}
          </Slider>
        </Row>
      ) : (
        <Row className="mb-4">
          <Slider
            ref={(slider) => {
              sliderRef = slider;
            }}
            {...settings}
          >
            {items.map((item, index) => (
              <Col key={index} className="pe-3">
                <Card
                  className="position-relative border-0 overflow-hidden p-0"
                  style={{ height: 290 }}
                >
                  <Image
                    src={item?.image}
                    alt={item?.title}
                    height="100%"
                    width="100%"
                  />
                  <div
                    className="position-absolute p-2 rounded geist-600 body-title"
                    style={{
                      zIndex: 1,
                      bottom: 7,
                      right: 7,
                      width: "35%",
                      background: "#FFFFFFBF",
                    }}
                  >
                    {truncateTitle(item?.title)}
                  </div>
                </Card>
              </Col>
            ))}
          </Slider>
        </Row>
      )}
    </Container>
  );
};

export default Banners;
