import { Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ConsultaionCard from "./ConsultationCard";
import { FaRegHeart } from "react-icons/fa6";
import { useEffect, useState } from "react";
import ShareModal from "components/General/ShareModal";
import { MdOutlineFileUpload } from "react-icons/md";
import { RiShare2Line } from "react-icons/ri";
import * as authActions from "reduxFiles/actions/authActions";
import { notify } from "utilities/notifications/Notify";
import { useSelector } from "react-redux";

const ProjectCard = ({
  id,
  slug,
  project_name,
  image,
  type,
  architect,
  location,
  builders,
  isConsultation,
  boxShadow,
  imgHeight = "300px",
  className,
  style,
}) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const [showShare, setShowShare] = useState(false);
  const [hover, setHover] = useState(false);
  const [isLiked, setIsLiked] = useState(false);

  const checkProjectIfSaved = async () => {
    try {
      const response = await authActions.checkProjectIfSaved(user.id, id);
      if (response) {
        setIsLiked(true);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveProject = async () => {
    try {
      const response = await authActions.saveProject(user.id, id);
      if (response.data) {
        setIsLiked(true);
        notify("Project saved successfully", "success");
      } else {
        notify("Failed to save project, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUnsaveProject = async () => {
    try {
      const response = await authActions.unsaveProject(user.id, id);
      if (response) {
        setIsLiked(false);
        notify("Project unsaved successfully", "success");
      } else {
        notify("Failed to unsave project, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkProjectIfSaved();
  }, []);

  if (isConsultation) {
    return <ConsultaionCard />;
  }

  return (
    <Card
      className={`cursor-pointer w-100 py-0 px-0 mx-2 border rounded overflow-hidden position-relative ${
        hover ? "" : "border-0"
      } ${className}`}
      style={{
        boxShadow: boxShadow,
        backgroundColor: hover ? "rgba(0, 0, 0, 0.1)" : "",
        ...style,
      }}
      onClick={() => navigate(`/projects/${slug}`)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Card.Img
        className="img-fluid"
        variant="top"
        src={image}
        style={{
          maxHeight: imgHeight,
          minHeight: imgHeight,
          objectFit: "cover",
        }}
      />
      <div
        className="d-flex position-absolute"
        style={{ zIndex: 999, right: 15, top: 10 }}
      >
        <Button
          className="d-flex justify-content-center align-items-center share-like-btn"
          onClick={(e) => {
            e.stopPropagation();
            setShowShare(true);
          }}
        >
          <MdOutlineFileUpload />
        </Button>
        {user && (
          <Button
            className={`d-flex justify-content-center align-items-center share-like-btn ms-2 ${
              isLiked ? "bg-primary text-white" : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
              isLiked ? handleUnsaveProject() : handleSaveProject();
            }}
          >
            <FaRegHeart size={18} />
          </Button>
        )}
      </div>
      <Card.Body
        className="p-2 d-flex flex-column align-items-start"
        onClick={() => navigate(`/projects/${slug}`)}
      >
        <Card.Title
          className={`geist-700 mb-1 ${hover ? "text-primary" : ""}`}
          style={{ fontSize: "16px" }}
        >
          {project_name ?? `${project_name} /`}, {type}
        </Card.Title>
        <div className="body-text2 geist-400 d-flex flex-column align-items-start">
          <span className="ms-0">Architect: {architect ?? "-"}</span>
          <span className="ms-0">Builders: {builders ?? "-"}</span>
        </div>
      </Card.Body>
      <ShareModal
        show={showShare}
        onHide={() => setShowShare(false)}
        id={id}
        image={image}
        title={`${project_name} / ${type}`}
        urlPath={`/projects/${slug}`}
      />
    </Card>
  );
};

export default ProjectCard;
