import ProductEnquiryForm from "components/Forms/ProductEnquiryForm";
import { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";

const ProductCard = ({
  id,
  productId,
  title,
  brand_name,
  description,
  image,
  productTypeImage,
  isEnquiry = false,
  isLast,
  className,
  style,
}) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <div
        className={`d-flex ${isLast ? "mb-5" : "mb-2"} ${className}`}
        style={style}
      >
        <div>
          <div
            className="bg-faint rounded overflow-hidden"
            style={{ width: 120, height: 120 }}
          >
            <img
              className="img-fluid w-100"
              src={image ?? productTypeImage}
              alt={title}
              style={{ objectFit: "cover", width: 120, height: 120 }}
            />
          </div>
        </div>
        <div className="d-flex flex-column w-100 ps-3">
          <div className="mb-2">
            <div className="body-text2 geist-600">{title}</div>
            <div className="body-text3 geist-600">{brand_name}</div>
            <div className="body-text3 ">{description}</div>
          </div>
          <div>
            {!isEnquiry && (
              <div className="text-end">
                <Button
                  size="sm"
                  className="bg-gray"
                  onClick={() => setShow(true)}
                >
                  Enquire
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal show={show} centered onHide={() => setShow(false)}>
        <Modal.Header className="border-0" closeButton />
        <Modal.Body>
          <ProductEnquiryForm productId={id} setShow={setShow} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProductCard;
