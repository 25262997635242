import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { FaSave } from "react-icons/fa";
import { LuEye, LuEyeOff, LuMail, LuPencilLine } from "react-icons/lu";

import * as authActions from "reduxFiles/actions/authActions";
import { userUpdate } from "reduxFiles/reducer/authSlices";
import { notify } from "utilities/notifications/Notify";
import { MdClose } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { FaLock } from "react-icons/fa6";

function AccountEditForm() {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const [isEdit, setIsEdit] = useState(false);
  const [isPasswordEdit, setIsPasswordEdit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const form = useRef(null);
  const passwordForm = useRef(null);

  const handleUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData(form.current);
    formData.append("id", user.id);
    let data = {};
    formData.forEach((value, key) => (data[key] = value));
    try {
      const response = await authActions.updateProfile(data.id, data);
      if (response.data) {
        notify("Profile Updated Successfully!", "success");
        dispatch(userUpdate(response.data));
        setIsEdit(false);
      } else {
        notify("Error while updating profile!", "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    const formData = new FormData(passwordForm.current);
    formData.append("id", user.id);
    let data = {};
    formData.forEach((value, key) => (data[key] = value));
    if (formData.get('password') === formData.get('confirm_password')) {
      try {
        const response = await authActions.updateProfile(data.id, data);
        if (response.data) {
          notify("Password Updated Successfully!", "success");
          dispatch(userUpdate(response.data));
          setIsPasswordEdit(false);
        } else {
          notify("Error while updating password!", "error");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      notify("The confirm password doesn't match the new password!", "error");
    }
  };

  useEffect(() => {
    if (location.hash !== "") {
      if (location.hash === "#edit") {
        setIsEdit(true);
      }
    }
  }, [location]);

  return (
    <>
      <Form ref={form} onSubmit={handleUpdate}>
        <Row className="p-2 py-3 p-md-4">
          <Col
            lg={6}
            className={`${isEdit ? "bg-faint p-4" : ""}`}
            style={{ borderRadius: 15 }}
          >
            {user?.user_type !== "customer" ? (
              <div className="mb-4">
                <div className="h4 geist-600">Contact Information</div>
                <div className="body-text2">
                  This information will not be displayed on your profile.
                </div>
              </div>
            ) : (
              <div className={`mb-4 ${isEdit ? "mb-2 h4 geist-600" : "d-none"}`}>
                Account Details
              </div>
            )}
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 body-text2 geist-500">
                Name<sup style={{ color: "red" }}>*</sup>
              </Form.Label>
              <Form.Control
                required
                type="text"
                readOnly={!isEdit}
                className={`body-text2 py-2 px-3 ${isEdit ? "" : "bg-faint text-gray-600"
                  }`}
                name="full_name"
                defaultValue={user?.full_name}
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 body-text2 geist-500">
                Company Email<sup style={{ color: "red" }}>*</sup>
              </Form.Label>
              <InputGroup style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}>
                <InputGroup.Text
                  className={`d-flex justify-content-center border-0 ${isEdit ? "" : "bg-faint text-gray-600"
                    }`}
                >
                  <LuMail size={20} />
                </InputGroup.Text>
                <Form.Control
                  readOnly={!isEdit}
                  className={`border-0 py-2 px-0 ${isEdit ? "" : "bg-faint text-gray-600"
                    }`}
                  type="email"
                  name="email"
                  defaultValue={user?.email}
                  placeholder="example@gmail.com"
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 body-text2 geist-500">
                Phone Number<sup style={{ color: "red" }}>*</sup>
              </Form.Label>
              <InputGroup style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}>
                <InputGroup.Text
                  className={`border-0 body-text2 geist-500 ${isEdit ? "" : "bg-faint text-gray-600"
                    }`}
                >
                  +91
                </InputGroup.Text>
                <Form.Control
                  readOnly={!isEdit}
                  className={`border-0 body-text2 py-2 px-0 ${isEdit ? "" : "bg-faint text-gray-600"
                    }`}
                  placeholder="Phone number"
                  name="mobile"
                  defaultValue={user?.mobile}
                  required
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 body-text2 geist-500">
                Location<sup style={{ color: "red" }}>*</sup>
              </Form.Label>
              <Form.Control
                required
                type="text"
                readOnly={!isEdit}
                className={`body-text2 py-2 px-3 ${isEdit ? "" : "bg-faint text-gray-600"
                  }`}
                name="location"
                defaultValue={user?.location}
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
              />
            </Form.Group>
            {!isEdit && (
              <Button className="text-white" onClick={() => setIsEdit(true)}>
                <LuPencilLine /> Edit Profile
              </Button>
            )}
          </Col>
        </Row>
        {isEdit && (
          <div className="px-2 my-2 d-flex">
            <Button
              className="bg-gray text-gray-900 me-3"
              onClick={() => setIsEdit(false)}
            >
              Cancel
            </Button>
            <Button
              className="text-white d-flex justify-content-center align-items-center"
              type="submit"
            >
              <FaSave size={18} className="me-2" />
              Save Profile
            </Button>
          </div>
        )}
      </Form>
      {isPasswordEdit ?
        <Form ref={passwordForm} onSubmit={handleChangePassword}>
          <Row className="p-2 py-3 p-md-4">
            <Col lg={6} className="bg-faint p-4" style={{ borderRadius: 15 }}>
              {isPasswordEdit && (
                <>
                  <div className={`mb-4 h4 geist-600`}>
                    Change Password
                  </div>
                  <Form.Group className="mb-4 user-select-none">
                    <Form.Label className="mb-1 body-text2 geist-500">
                      Old Password<sup style={{ color: "red" }}>*</sup>
                    </Form.Label>
                    <InputGroup style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}>
                      <InputGroup.Text
                        className="cursor-pointer bg-white border-0"
                        style={{ borderRadius: 3 }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <LuEye size={20} /> : <LuEyeOff size={20} />}
                      </InputGroup.Text>
                      <Form.Control
                        className="border-0 body-text2 py-2"
                        name="old_password"
                        type={showPassword ? "text" : "password"}
                        placeholder="**********"
                        required
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-4 user-select-none">
                    <Form.Label className="mb-1 body-text2 geist-500">
                      New Password<sup style={{ color: "red" }}>*</sup>
                    </Form.Label>
                    <InputGroup style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}>
                      <InputGroup.Text
                        className="cursor-pointer bg-white border-0"
                        style={{ borderRadius: 3 }}
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      >
                        {showNewPassword ? <LuEye size={20} /> : <LuEyeOff size={20} />}
                      </InputGroup.Text>
                      <Form.Control
                        className="border-0 body-text2 py-2"
                        name="password"
                        type={showNewPassword ? "text" : "password"}
                        placeholder="**********"
                        required
                        pattern="^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$"
                        title="Password must contain at least 1 uppercase letter, 1 digit, and be at least 8 characters long"
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-4 user-select-none">
                    <Form.Label className="mb-1 body-text2 geist-500">
                      Confirm Password<sup style={{ color: "red" }}>*</sup>
                    </Form.Label>
                    <InputGroup style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}>
                      <InputGroup.Text
                        className="cursor-pointer bg-white border-0"
                        style={{ borderRadius: 3 }}
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      >
                        {showConfirmPassword ? <LuEye size={20} /> : <LuEyeOff size={20} />}
                      </InputGroup.Text>
                      <Form.Control
                        className="border-0 body-text2 py-2"
                        name="confirm_password"
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="**********"
                        required
                        pattern="^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$"
                        title="Password must contain at least 1 uppercase letter, 1 digit, and be at least 8 characters long"
                      />
                    </InputGroup>
                  </Form.Group>
                </>
              )}
            </Col>
          </Row>
          {isPasswordEdit && (
            <div className="px-2 my-2 d-flex">
              <Button
                className="bg-gray text-gray-900 me-3"
                onClick={() => setIsPasswordEdit(false)}
              >
                Cancel
              </Button>
              <Button
                className="text-white d-flex justify-content-center align-items-center"
                type="submit"
              >
                <FaLock size={18} className="me-2" />
                Change
              </Button>
            </div>
          )}
        </Form>
        :
        <div className="p-2 px-md-4">
          <Button
            className="text-white"
            onClick={() => setIsPasswordEdit(true)}
          >
            Change Password
          </Button>
        </div>
      }
    </>
  );
}

export default AccountEditForm;
