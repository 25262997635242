import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const TopInspirations = ({ recommendedProjects }) => {
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const xxlarge = useMediaQuery({ query: "(min-width: 1400px)" });

  const [project1Index, setProject1Index] = useState(0);
  const [project2Index, setProject2Index] = useState(1);
  const [project3Index, setProject3Index] = useState(2);
  const [activeRoomIndex, setActiveRoomIndex] = useState(0);

  //   const onClickProject1 = () => {};
  const onClickProject2 = () => {
    const temp = project1Index;
    setProject1Index(project2Index);
    setProject2Index(temp);
  };
  const onClickProject3 = () => {
    const temp = project1Index;
    setProject1Index(project3Index);
    setProject3Index(temp);
  };

  useEffect(() => {
    setActiveRoomIndex(0);
  }, [project1Index, project2Index, project3Index]);

  useEffect(() => {
    console.log(recommendedProjects[project1Index]?.rooms?.length);
    const timer = setInterval(() => {
      setActiveRoomIndex((prevIndex) =>
        prevIndex < recommendedProjects[project1Index]?.rooms?.length - 1
          ? prevIndex + 1
          : 0
      );
    }, 5000);

    // Cleanup the interval on component unmount
    return () => clearInterval(timer);
  }, [activeRoomIndex, recommendedProjects]);

  return (
    <>
      {recommendedProjects?.length > 0 && (
        <Row className="w-100">
          <Col
            md={12}
            lg={8}
            className="cursor-pointer rounded overflow-hidden"
            style={{ position: "relative" }}
            // onClick={onClickProject1}
          >
            <div className="rounded overflow-hidden mb-2">
              <img
                src={
                  recommendedProjects[project1Index]?.rooms[activeRoomIndex]
                    ?.media[0]?.file
                }
                alt={
                  recommendedProjects[project1Index]?.rooms[activeRoomIndex]
                    ?.project_type_data?.title
                }
                className="w-100"
                style={{ height: large ? 530 : 260 }}
              />
            </div>
            {/* <Row className="position-absolute geist" style={{ top: "10px", left: "20px", width: "80%" }}>
                <Col xl={3}>
                  <div className="img-absolute-btn mb-2 mb-xl-0" style={{ width: `${!xlarge ? "30%" : ""}` }}>
                    Living Room
                  </div>
                </Col>
              </Row> */}
            <div
              className="d-flex overflow-scroll scrollbar-none position-absolute"
              style={{ top: 10, left: 20, width: "95%" }}
            >
              {recommendedProjects[project1Index]?.rooms?.map((item, index) => (
                <div
                  key={index}
                  className={`rounded px-2 py-1 py-md-2 me-2 ${
                    index === activeRoomIndex
                      ? "bg-brown geist-600 text-white"
                      : "bg-gray"
                  }`}
                  style={{ background: "#FFFFFFBF" }}
                  onClick={() => {
                    setActiveRoomIndex(index);
                  }}
                >
                  {item.room_type_data?.title}
                </div>
              ))}
            </div>
            <Button
              className="body-title position-absolute bg-gray text-gray-900 geist-600 text-start py-2"
              style={{
                bottom: large ? 30 : 15,
                left: 20,
                width: xxlarge
                  ? "96%"
                  : xlarge
                  ? "95%"
                  : medium
                  ? "93%"
                  : "90%",
                background: "#FFFFFFBF",
              }}
            >
              {`${
                recommendedProjects[project1Index]?.bhk
                  ? `${recommendedProjects[project1Index]?.bhk},`
                  : ""
              } ${
                recommendedProjects[project1Index]?.property_data?.property_name
              }`}{" "}
            </Button>
          </Col>
          <Col md={12} lg={4} className="ps-2">
            <Row className="mb-3">
              {recommendedProjects?.length > 1 && (
                <Col
                  lg={12}
                  md={6}
                  className="cursor-pointer rounded overflow-hidden"
                  style={{
                    position: "relative",
                    display: "inline-block",
                    opacity: 0.6,
                  }}
                  onClick={onClickProject2}
                >
                  <div className="rounded overflow-hidden mb-2">
                    <img
                      src={
                        recommendedProjects[project2Index]?.rooms[0]?.media[0]
                          ?.file
                      }
                      alt={
                        recommendedProjects[project2Index]?.rooms[0]
                          ?.project_type_data?.title
                      }
                      className="w-100"
                      style={{ height: 260 }}
                    />
                  </div>
                  <Button
                    className="body-title position-absolute bg-gray text-gray-900 geist-600 text-start py-2"
                    style={{
                      top: "77%",
                      left: 20,
                      width: xxlarge ? "92%" : "90%",
                      background: "#FFFFFFBF",
                    }}
                  >
                    {`${
                      recommendedProjects[project2Index]?.bhk
                        ? `${recommendedProjects[project2Index]?.bhk},`
                        : ""
                    } ${
                      recommendedProjects[project2Index]?.property_data
                        ?.property_name
                    }`}{" "}
                  </Button>
                </Col>
              )}
              {recommendedProjects?.length > 2 && (
                <Col
                  lg={12}
                  md={6}
                  className="cursor-pointer rounded overflow-hidden"
                  style={{
                    position: "relative",
                    display: "inline-block",
                    opacity: 0.6,
                  }}
                  onClick={onClickProject3}
                >
                  <div className="rounded overflow-hidden mb-2">
                    <img
                      src={
                        recommendedProjects[project3Index]?.rooms[0]?.media[0]
                          ?.file
                      }
                      alt={
                        recommendedProjects[project3Index]?.rooms[0]
                          ?.project_type_data?.title
                      }
                      className="w-100"
                      style={{ height: 260 }}
                    />
                  </div>
                  <Button
                    className="body-title position-absolute bg-gray text-gray-900 geist-600 text-start py-2"
                    style={{
                      top: "77%",
                      left: 20,
                      width: xxlarge ? "92%" : "90%",
                      background: "#FFFFFFBF",
                    }}
                  >
                    {`${
                      recommendedProjects[project3Index]?.bhk
                        ? `${recommendedProjects[project3Index]?.bhk},`
                        : ""
                    } ${
                      recommendedProjects[project3Index]?.property_data
                        ?.property_name
                    }`}{" "}
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default TopInspirations;
