import React from 'react';
import { Form } from 'react-bootstrap';

const PropertyDetailsForm = ({ propertyDetails, projectTypes }) => {

    return (
        <>
            <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 geist-500">
                    Property Name<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <Form.Control
                    required
                    className="py-2 body-text2"
                    type="text"
                    name="property_name"
                    placeholder="Enter property name"
                    defaultValue={propertyDetails?.property_name}
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 geist-500">
                    Number of Flats<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <Form.Control
                    required
                    className="py-2 body-text2"
                    type="text"
                    placeholder="No. of flats"
                    name="flats"
                    defaultValue={propertyDetails?.flats}
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 geist-500">
                    Property Year<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <Form.Control
                    required
                    className="py-2 body-text2"
                    type="text"
                    placeholder="Property year"
                    name="property_year"
                    defaultValue={propertyDetails?.property_year}
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 geist-500">
                    Location<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <Form.Control
                    required
                    className="py-2 body-text2"
                    type="text"
                    placeholder="Enter location"
                    name="location"
                    defaultValue={propertyDetails?.location}
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 geist-500">
                    Project Type<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <Form.Select
                    required
                    className="py-2 body-text2"
                    type="text"
                    placeholder="Select Project Type"
                    name="project_type"
                    defaultValue={propertyDetails?.project_type}
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                >
                    {projectTypes?.map((type, index) => (
                        <option key={index} value={type.id}>
                            {type.title}
                        </option>
                    ))}
                </Form.Select>
            </Form.Group>
        </>
    )

}

export default PropertyDetailsForm