import { Card } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

const ProjectCategoryCard = ({ title, image, onClick }) => {
  const navigate = useNavigate();
  const small = useMediaQuery({ query: "(min-width: 576px)" });

  return (
    <Card
      className="card-primary-hover p-0 bg-light text-start mb-2"
      style={{ width: 128 }}
      onClick={onClick}
    >
      <Card.Img
        className="img-fluid h-100 w-100"
        variant="top"
        src={image}
        style={{ width: 128, maxHeight: "100px", minHeight: "100px" }}
      />
      <Card.Body className="d-flex justify-content-center overflow-scroll scrollbar-none p-2">
        <Card.Title
          className="body-text2 m-0"
          style={{ textOverflow: "ellipsis" }}
        >
          {title}
        </Card.Title>
      </Card.Body>
    </Card>
  );
};

export default ProjectCategoryCard;
