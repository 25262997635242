import {
  apartment,
  office,
  residence,
  showrooms,
  space_switch_card,
  villa,
} from "assets";
import * as authActions from "reduxFiles/actions/authActions";
import ProjectCard from "components/Cards/ProjectCard";
import ProjectCategoryCard from "components/Cards/ProjectCategoryCard";
import Pagination from "components/Pagination";
import { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import { notify } from "utilities/notifications/Notify";
import ShareModal from "components/General/ShareModal";

const ProjectList = () => {
  const navigate = useNavigate();
  const { slug } = useParams();

  const xsmall = useMediaQuery({ query: "(min-width: 400px)" });
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const xxlarge = useMediaQuery({ query: "(min-width: 1400px)" });
  const xxxlarge = useMediaQuery({ query: "(min-width: 1600px)" });

  const [layout, setLayout] = useState("");
  const [theme, setTheme] = useState("");
  const [budget, setBudget] = useState(1000000);
  const [status, setStatus] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [projects, setProjects] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showShareProject, setShowShareProject] = useState(false);

  const fetchProjects = async (page) => {
    setLoading(true);
    try {
      const response = await authActions.fetchProjects(
        page,
        layout,
        budget,
        theme,
        status,
        sortBy
      );
      if (response.data) {
        setProjects(response.data?.results);
        setTotalPages(Math.ceil(response.data.count / 12));
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchProjectTypes = async (page) => {
    setLoading(true);
    try {
      const response = await authActions.fetchProjectTypes();
      if (response.data) {
        setProjectTypes(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchProjectsBySlug = async (slug) => {
    setLoading(true);
    console.log(slug);
    try {
      const response = await authActions.fetchProjectsBySlug(slug);
      if (response.data) {
        setProjects(response.data?.results);
        setTotalPages(Math.ceil(response.data.count / 12));
      } else {
        notify("Error while fetching projects. Try again later!");
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchProjects();
  }, [layout, budget, theme, status, sortBy]);

  useEffect(() => {
    fetchProjects(currentPage);
  }, [currentPage]);

  useEffect(() => {
    fetchProjectTypes();
  }, []);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + " custom-next-arrow"}
        style={{ ...style, display: "flex", right: -25, zIndex: 1 }}
        onClick={onClick}
      >
        <MdKeyboardArrowRight size={30} />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + " custom-prev-arrow"}
        style={{ ...style, display: "flex", left: -25, zIndex: 1 }}
        onClick={onClick}
      >
        <MdKeyboardArrowLeft size={30} />
      </div>
    );
  }
  const settings = {
    dots: false,
    infinite: false,
    speed: 200,
    // slidesToShow: xxlarge ? 8.5 : xlarge ? 7 : large ? 6 : medium ? 4 : small ? 2.5 : 1,
    slidesToShow: xxxlarge
      ? 5
      : xxlarge
      ? 4.5
      : xlarge
      ? 4
      : large
      ? 3
      : medium
      ? 4
      : small
      ? 2.5
      : 1.5,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  if (slug) {
    return <Outlet />;
  }

  return (
    <Container className="page-padding">
      <section className="d-flex flex-column mb-3">
        <div className="body-title mb-3">
          <span className="geist-700">{projects?.length} real projects</span>{" "}
          found in your city
        </div>
        <div className="d-flex mb-4">
          <div className="mb-3 mb-lg-0">
            {/* <div className={`${medium ? "border-end border-2" : ""} pe-3`}> */}
            <div className="pe-3">
              <Card
                className="border-0 p-0 text-start bg-primary text-white"
                style={{ width: "130px", cursor: "pointer" }}
                onClick={() => navigate("/spaces")}
              >
                <Card.Img
                  className="img-fluid"
                  variant="top"
                  src={space_switch_card}
                  style={{ maxHeight: 100, minHeight: 100 }}
                />
                <Card.Body className="px-0 py-2 text-center">
                  <Card.Title className="body-text2 m-0">Projects</Card.Title>
                </Card.Body>
              </Card>
            </div>
          </div>
          <div
            className={`${
              large ? "w-50" : medium ? "w-80" : small ? "w-80" : "w-60"
            } ps-3`}
          >
            <Slider {...settings} style={{ maxHeight: 104 }}>
              {projectTypes.map((type, index) => (
                <ProjectCategoryCard
                  key={index}
                  {...type}
                  onClick={() => {
                    navigate(`/projects?project-type=${type.slug}`);
                    fetchProjectsBySlug(type.slug);
                  }}
                />
              ))}
            </Slider>
          </div>
        </div>
        <Row>
          <Col lg={8} xl={6}>
            <Row>
              <Col xs={6} md={3} className="mb-2">
                <Form.Select
                  defaultValue=""
                  className="bg-light"
                  onChange={(e) => setLayout(e.target.value)}
                >
                  <option value="" disabled>
                    Layout
                  </option>
                  <option value="2BHK">2 BHK</option>
                  <option value="2.5BHK">2.5 BHK</option>
                  <option value="3BHK">3 BHK</option>
                  <option value="3.5BHK">3.5 BHK</option>
                  <option value="4BHK">4 BHK</option>
                  <option value="5BHK">5 BHK</option>
                </Form.Select>
              </Col>
              <Col xs={6} md={3} className="mb-2">
                <Form.Select
                  defaultValue=""
                  className="bg-light"
                  // onChange={(e) => setTheme(e.target.value)}
                >
                  <option value="" disabled>
                    Theme
                  </option>
                  <option value="modern">Modern</option>
                  <option value="contemporary">Contemporary</option>
                  <option value="traditional">Traditional</option>
                  <option value="neo_classical">Neo-Classical</option>
                  <option value="eclectic">Eclectic</option>
                  <option value="tropical">Tropical</option>
                  <option value="mediterranean">Mediterranean</option>
                </Form.Select>
              </Col>
              <Col md={6} className="mb-2">
                <Form.Group className="d-flex align-items-center px-2 pt-1 bg-light border rounded">
                  <Form.Label className="me-2">{`Budget: Rs.${budget} `}</Form.Label>
                  {/* <Form.Range /> */}
                  <div className="">
                    <input
                      type="range"
                      min="0"
                      max="1000000"
                      step={500}
                      value={budget}
                      onChange={(event) => setBudget(event.target.value)}
                      // className="w-100"
                      style={{ color: "#d95656" }}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col lg={4} xl={6}>
            <Row className="justify-content-lg-end">
              <Col xs={6} md={3} lg={4} className="mb-2">
                <Form.Select
                  defaultValue=""
                  className="bg-light"
                  // onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="" disabled>
                    Status
                  </option>
                  <option value="completed">Complete</option>
                  <option value="ongoing">Ongoing</option>
                  <option value="upcoming">Upcoming</option>
                </Form.Select>
              </Col>
              <Col xs={6} md={3} lg={4} className="mb-2">
                <Form.Select
                  defaultValue=""
                  className="bg-light"
                  onChange={(e) => setSortBy(e.target.value)}
                >
                  <option value="" disabled>
                    Sort By
                  </option>
                  {/* <option value="popularity">Popularity (High to Low)</option>
                  <option value="cost_high_low">Cost (High to Low)</option>
                  <option value="cost_low_high">Cost (Low to High)</option> */}
                  <option value="created_at">Featured (New to Old)</option>
                  <option value="-created_at">Featured (Old to New)</option>
                </Form.Select>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
      <section className="mb-3 w-100">
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center w-100"
            style={{ minHeight: "50vh" }}
          >
            <Spinner />
          </div>
        ) : projects?.length > 0 ? (
          <div className="d-flex flex-column align-items-center w-100">
            <Row md={2} lg={3} className="w-100">
              {projects?.map((item, index) => (
                <Col
                  key={index}
                  md={6}
                  lg={4}
                  className="d-flex flex-column my-1 p-2 flex-grow"
                >
                  <ProjectCard
                    {...item}
                    id={item.id}
                    project_name={item?.project_name}
                    image={item?.rooms[0]?.media[0]?.file}
                    // image={item?.image}
                    // type={`${item?.bhk}, Block ${item?.block}, ${item?.project_area}`}
                    type={`${item?.bhk}, ${item?.project_area}`}
                    architect={item?.company_name}
                    location={item?.property_data?.location}
                    builders={item?.property_data?.builder_data?.company_name}
                    className="mx-0 flex-grow"
                  />
                  <ShareModal
                    show={showShareProject}
                    onHide={() => setShowShareProject(false)}
                    // id={id}
                    // image={image}
                    // title={`${project_name} / ${type}`}
                    urlPath={
                      item?.slug !== "undefined"
                        ? `/projects/${item?.slug}`
                        : null
                    }
                  />
                </Col>
              ))}
            </Row>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
            />
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center bg-faint rounded w-100"
            style={{ height: 160 }}
          >
            No projects available
          </div>
        )}
      </section>
    </Container>
  );
};

export default ProjectList;
