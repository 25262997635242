import { Card } from "react-bootstrap";

const TestimonialCard = ({
  testimonial,
  fixedHeight = false,
  className,
  style,
}) => {
  return (
    <Card
      className={`p-0 text-start geist overflow-hidden ${className}`}
      style={{ width: "100%", height: "100%", borderRadius: 14, ...style }}
    >
      <Card.Img className="img-fluid" variant="top" src={testimonial?.image} />
      <Card.Body>
        <div
          className="body-text2 h-100 d-flex flex-column justify-content-between"
          style={{ color: "#303030" }}
        >
          <div className="mb-4 text-center">{testimonial?.testimonial}</div>
          <div className="d-flex flex-column justify-content-end">
            <div
              className="geist-700 text-center text-secondary"
              style={{ fontSize: "18  px" }}
            >
              {testimonial?.name}
            </div>
            <div className="body-text3 geist-600 text-center text-secondary">
              {testimonial?.title}
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default TestimonialCard;
