import React from "react";
import { Button, Card, Container, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { choose_inspiration, book_consultation, give_requirements, execute_project } from "assets";

const HowItWorks = () => {

    const navigate = useNavigate();
    const medium = useMediaQuery({ query: "(min-width: 768px)" });

    const stepsToWork = [
        {
            id: 1,
            title: "Choose Inspiration",
            image: choose_inspiration,
            description: "Check our inspiration library to find your dream interior ideas"
        },
        {
            id: 2,
            title: "Book Consultation",
            image: book_consultation,
            description: "Select your service type and book a free consultation"
        },
        {
            id: 3,
            title: "Give Requirements",
            image: give_requirements,
            description: "Give your requirement and we will tailor the design accordingly"
        },
        {
            id: 4,
            title: "Execution or Final Drawings",
            image: execute_project,
            description: "You will be given final files or we will execute project as per your request"
        },
    ]

    return (
        <Container fluid style={{ background: "#F8F9FA" }}>
            <Container className="d-flex justify-content-center align-items-center my-5 py-5">
                <div className="text-center w-100">
                    <div className="h2 geist-600">How it works!</div>
                    <div className={`my-5 d-flex ${medium ? "" : "flex-column align-items-center"}`}>
                        {stepsToWork.map((step) => {
                            return (
                                <Card
                                    className="border shadow-none py-3 mx-2 mb-3 bg-transparent d-flex justify-content-center align-items-center"
                                    style={medium ? { maxWidth: "358px" } : { width: "100%" }}
                                >
                                    <div className="body-text2 geist-600">{step.id + ". " + step.title}</div>
                                    <Image className="my-3" src={step.image} alt={step.title} style={{ maxWidth: 120, maxHeight: 120 }} />
                                    <div className="body-text2 text-gray-800 geist-400">{step.description}</div>
                                </Card>
                            )
                        })}
                    </div>
                    <Button
                        className="body-title geist-600 text-white py-2 px-4"
                        style={{ borderRadius: 3 }}
                        onClick={() => navigate("/consultation")}
                    >
                        Get Started with Interiokeys!
                    </Button>
                </div>
            </Container>
        </Container>
    )
}

export default HowItWorks