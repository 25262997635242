import { Button } from "react-bootstrap";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import axios from "axios";
import { useDispatch } from "react-redux";
import * as actions from "reduxFiles/actions/authActions";

import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa6";

const FacebookGoogleLogin = ({ userType, type = null }) => {
  const dispatch = useDispatch();
  const responseFacebook = async (response) => {
    // console.log(response);

    const { email, name } = response;
    try {
      const resp1 = await axios.post("/user/social_login/", {
        username: email,
        name: name,
        sign_up_type: "FACEBOOK",
        user_type: userType,
      });
      if (resp1.status === 200) {
        // console.log(resp1.data);
        dispatch(actions.login(resp1.data));
      }
    } catch (err) {
      // console.log({ err });
      // actions.notify(err?.response?.data?.msg, "error");
    }
    //   {
    //     "name": "Bhushan Patil",
    //     "email": "patilbhushanr3838@gmail.com",
    //     "picture": {
    //         "data": {
    //             "height": 50,
    //             "is_silhouette": false,
    //             "url": "https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=1387296245426476&height=50&width=50&ext=1683372217&hash=AeRQiEh7hCjrfREjKIY",
    //             "width": 50
    //         }
    //     },
    //     "id": "1387296245426476",
    //     "accessToken": "EAAHeBHWHlBMBABgjlT96KLxjgDcXFexeadi4pW2NpLuZBDFDL1D24oaJBCJIYsBHSaobf7N0fCVopTmS83DjfKmEZAWx4c0WD6vYy3kiylSCZCI7CmkuRRNuJjJet4gZBpEmvpZAYbJkH7PXDkXDbQ92hRxHC9uXIZCZBeKQIztDZCaZCQfxIPaW4PY8zsiAtZBNtZCosc72ZCSwcGLtenRulXZBM",
    //     "userID": "1387296245426476",
    //     "expiresIn": 5784,
    //     "signedRequest": "M8bAUqhpujLNDSxDo3Rn7KdS7npBbKuUqyl0vFZMl7M.eyJ1c2VyX2lkIjoiMTM4NzI5NjI0NTQyNjQ3NiIsImNvZGUiOiJBUUFPT016NTRqQjFGdl9fdGZiRTkwX1RZekR3QThvRkp3cGdDVmFYU09nSkZDeldON1ZmYU1vcWJGdG55ZXA3bmRiRW9KTFZCSFZtZXpPbUVsMVRhUEdTekt1ZnZjWm0wZUNHSzR5MDM5NEdIc3pPdHFJd29mZ3dsSEtSVlVQVkdqYmplX0VrNUtKMFVGVGlzVmw2bGNraUZLTmRwU3NMNEhIQ3F4U2FmUFVfdDFQLTA3MEt0LXRudmI5WXFFd3c5VThMR0xHc2xUYUhaYjhJR3NMQXZrQlRDZUN0RE5SSVZzY2Nxd3FNSm9xZVFieThjRnd6ZXJ1NndhWFJaWWR0RzRhb0lzam1FY3Q4Y3Njdnp1dXpSal9aNGNxOG9hVUI1TWR4VkgxcG5acThBaF85RnJrQ0hSa0Uyc2NlMjZYbDFhYlU1QlYyNnhwVWRjelZabUZ4SURzUCIsImFsZ29yaXRobSI6IkhNQUMtU0hBMjU2IiwiaXNzdWVkX2F0IjoxNjgwNzgwMjE2fQ",
    //     "graphDomain": "facebook",
    //     "data_access_expiration_time": 1688556216
    // }
  };

  const onGoogleLoginSuccess = async (codeResponse) => {
    // console.log(codeResponse);
    const { access_token } = codeResponse;

    const response = await axios.get(
      "https://www.googleapis.com/oauth2/v1/userinfo/",
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    if (response.status === 200) {
      // console.log(response.data);
      const { email, name } = response.data;
      try {
        const resp1 = await axios.post("/user/social_login/", {
          username: email,
          name: name,
          sign_up_type: "GOOGLE",
          user_type: userType,
        });
        if (resp1.status === 200) {
          // console.log(resp1.data);
          dispatch(actions.login(resp1.data));
        }
      } catch (err) {
        // console.log({ err });
        // actions.notify(err?.response?.data?.msg, "error");
      }
      // {
      //   "id": "117768989357044211691",
      //   "email": "tejas@teson.in",
      //   "verified_email": true,
      //   "name": "Tejas Ingale",
      //   "given_name": "Tejas",
      //   "family_name": "Ingale",
      //   "picture": "https://lh3.googleusercontent.com/a/AGNmyxaFfT8x8Ye3oJWEUnPsuGMksT_rn5RVnvCBmW6c=s96-c",
      //   "locale": "en",
      //   "hd": "teson.in"
      // }
      // alert();
    }
  };
  const googleLogin = useGoogleLogin({
    onSuccess: onGoogleLoginSuccess,
  });

  if (type === "mobile") {
    return (
      <>
        <div className="row justify-content-center">
          <Button
            onClick={async () => await googleLogin()}
            className="bg-grey border-0"
          >
            <div className="d-flex justify-content-center align-items-center">
              <FcGoogle size={30} />
            </div>
          </Button>
          <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            autoLoad={false}
            fields="name,email,picture"
            onClick={() => {
              // console.log("clicked");
              // console.log(process.env.REACT_APP_FACEBOOK_APP_ID);
            }}
            callback={responseFacebook}
            render={(renderProps) => (
              <Button
                onClick={renderProps.onClick}
                className=" col-5  border-0 text-white mx-2 py-3"
                style={{ backgroundColor: "#1877F2" }}
              >
                <div className="d-flex justify-content-center align-items-center">
                  <div className="me-2">
                    {/* <Image loading="lazy" src={facebookButtonIcon} /> */}
                    <FaFacebook />
                  </div>
                  <div>FACEBOOK</div>
                </div>
              </Button>
            )}
          />
          <div className="col-5 mx-2"></div>
        </div>

        <div className="d-flex align-items-center my-4" style={{ height: 30 }}>
          <div
            className="w-100"
            style={{ height: 1, backgroundColor: "#666666" }}
          ></div>
          <div className="text-white mx-2 fs-10px">OR</div>
          <div
            className="w-100"
            style={{ height: 1, backgroundColor: "#666666" }}
          ></div>
        </div>
      </>
    );
  }

  return (
    <div>
      <div className="d-flex justify-content-center mt-4">
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          autoLoad={false}
          fields="name,email,picture"
          callback={responseFacebook}
          render={(renderProps) => (
            <Button
              onClick={renderProps.onClick}
              className="border-0 d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: "#E8EAED",
                width: 60, height: 60,
                borderRadius: 30
              }}
            >
              <div className="bg-white" style={{ width: 30, height: 30, borderRadius: 15 }}>
                <FaFacebook size={30} color="#1877f2" />
              </div>
            </Button>
          )}
        />
        <Button
          onClick={async () => await googleLogin()}
          className="border-0 ms-3"
          style={{
            backgroundColor: "#E8EAED",
            width: 60, height: 60,
            borderRadius: 30
          }}
        >
          <div className="">
            <FcGoogle size={30} />
          </div>
        </Button>
      </div>
      <div
        className="w-100 mt-4 mb-4"
        style={{ height: 1, backgroundColor: "#E8EAED" }}
      ></div>
    </div >
  );
};

export default FacebookGoogleLogin;
