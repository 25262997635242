import ShareModal from "components/General/ShareModal";
import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { FaRegHeart, FaStar } from "react-icons/fa6";
import { FiShare2 } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import { notify } from "utilities/notifications/Notify";

const ProfessionalCard = ({
  id,
  name,
  slug,
  company_name,
  user_data,
  type,
  location,
  rating,
  project_count,
  expertise,
  project_names,
  project_img,
  profile_img,
  onClick,
}) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const [showShare, setShowShare] = useState(false);
  const [isLiked, setIsLiked] = useState(false);

  const checkProfessionalIfSaved = async () => {
    try {
      const response = await authActions.checkProfessionalIfSaved(user.id, id);
      if (response) {
        setIsLiked(true);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveProfessional = async () => {
    try {
      const response = await authActions.saveProfessional(user.id, id);
      if (response.data) {
        setIsLiked(true);
        notify("Professional saved successfully", "success");
      } else {
        notify("Failed to save professional, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUnsaveProfessional = async () => {
    try {
      const response = await authActions.unsaveProfessional(user.id, id);
      if (response) {
        setIsLiked(false);
        notify("Professional unsaved successfully", "success");
      } else {
        notify("Failed to unsave professional, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkProfessionalIfSaved();
  }, []);

  // const navigateToProfessional = () => {
  //   // navigate(`/professionals?type=${slug}`);
  //   navigate(`/professionals/${slug}`);
  // };

  return (
    <Card
      className="card-primary-hover d-flex flex-column flex-lg-row p-2 p-md-3 flex-row text-start my-4 border w-100"
      style={{ boxShadow: "none" }}
      onClick={onClick}
    >
      {medium && (
        <div
          className="bg-light border me-4 rounded overflow-hidden"
          style={{
            width: large ? 400 : medium ? 300 : 200,
            height: large ? 200 : medium ? 150 : 100,
          }}
        >
          <Card.Img
            variant="top"
            src={project_img}
            className="border-0 rounded"
          />
        </div>
      )}
      <Card.Body className="w-100 p-2">
        <div>
          <div className="d-flex mb-3">
            <div className="me-3">
              <img
                src={profile_img ?? user_data?.profile_picture}
                alt=""
                width={100}
                height={100}
                className="bg-light border rounded-circle"
                style={{ objectFit: "cover" }}
              />
            </div>
            <div className="flex-grow pt-1">
              <div className="geist-600">{name ?? user_data?.full_name}</div>
              <div className="geist-700">{company_name}</div>
              <div className="geist-600" style={{ color: "#666666" }}>
                {type}
              </div>
              <div className="geist-600">{location}</div>
              <div className="geist-600 d-flex align-items-center">
                Ratings:{" "}
                {rating ? (
                  <div className="d-flex align-items-center">
                    {rating} <FaStar color="#D95656" className="ms-1" />
                  </div>
                ) : (
                  "Unavailable"
                )}
                {/* {rating ? ( <span>{rating} <FaStar color="#D95656" /></span> ) : ( "Unavailable" )} */}
              </div>
            </div>
            <div className="d-flex ms-auto">
              <Button
                className="d-flex justify-content-center align-items-center social-btn py-2"
                // style={{ zIndex: 1000 }}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowShare(true);
                }}
              >
                <FiShare2 size={18} />
              </Button>
              {user &&
                user?.user_type !== "builder" &&
                user?.user_type !== "architect" &&
                user?.user_type !== "brand" && (
                  <Button
                    className={`d-flex justify-content-center align-items-center social-btn py-2 ms-2 ${
                      isLiked ? "bg-primary text-white" : ""
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      isLiked
                        ? handleUnsaveProfessional()
                        : handleSaveProfessional();
                    }}
                  >
                    <FaRegHeart size={18} />
                  </Button>
                )}
            </div>
          </div>
          <div className="">
            <span className="geist-600">Project Completed: </span>
            {project_count ? `${project_count}+ projects` : "-"}
          </div>
          <div>
            <span className="geist-600">Projects: </span>
            {project_names ?? "-"}
          </div>
          <div>
            <span className="geist-600">Expertise: </span>
            {expertise ?? "-"}
          </div>
        </div>
      </Card.Body>
      <ShareModal
        show={showShare}
        onHide={() => setShowShare(false)}
        id={id}
        image={profile_img}
        title={company_name}
        urlPath={`/professionals/${slug}`}
      />
    </Card>
  );
};

export default ProfessionalCard;
