import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Button, Col, Container, Form, Image, InputGroup, Row, Spinner } from "react-bootstrap";
import RangeSlider from "components/Forms/RangeSlider";
import ReactSelect from "react-select";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { FaCircleMinus, FaXmark } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa";

import { notify } from "utilities/notifications/Notify";
import { formatNumber } from "utilities/numberFormatter";
import AddPropertyModal from "./AddPropertyModal";
import LoadingOverlay from "components/General/LoadingOverlay";
import DropFileInput from "components/DragInput/DropFileInput";
import AddProductsModal from "./AddProductsModal";
import * as authActions from "reduxFiles/actions/authActions";
import { CiEdit } from "react-icons/ci";

function AddProject() {
  const navigate = useNavigate();
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const { propertyId } = useParams();
  const { status } = useParams();
  const user = useSelector((state) => state.auth.user);

  const projectInfoRef = useRef();
  const [brandInfo, setBrandInfo] = useState(null);
  const [architectInfo, setArchitectInfo] = useState(null);
  const [projectDetails, setProjectDetails] = useState({});
  const [properties, setProperties] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [roomTypes, setRoomTypes] = useState([]);
  const [availableLayouts, setAvailableLayouts] = useState([
    { id: 1, name: "1BHK" },
    { id: 2, name: "2BHK" },
    { id: 3, name: "2.5BHK" },
    { id: 4, name: "3BHK" },
    { id: 5, name: "3.5BHK" },
    { id: 6, name: "4BHK" },
  ]);
  const [rangeValue, setRangeValue] = useState({
    min: 5000,
    max: 1000000,
  });
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedRoomtypes, setSelectedRoomtypes] = useState([]);
  const [roomsToDisplay, setRoomsToDisplay] = useState(null);
  const [roomtypeIndex, setRoomTypeIndex] = useState(0);
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [showPropertyModal, setShowPropertyModal] = useState(false);
  const [customProperty, setCustomProperty] = useState(null);
  const [showAddProperty, setShowAddProperty] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  // for modal (sidebar rooms)
  const [activeRoomIndex, setActiveRoomIndex] = useState(0);

  const settings = {
    dots: true,
    infinite: false,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: null,
    prevArrow: null,
  };

  const fetchProperties = async () => {
    try {
      const response = await authActions.fetchAllProperties();
      console.log(response);
      if (response?.data) {
        setProperties(response.data);
        return response.data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  // const fetchPropertyById = async (propertyId) => {
  //   try {
  //     const response = await authActions.fetchPropertyById(propertyId);
  //     if (response.data) {
  //       return response.data;
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const fetchProjectTypes = async () => {
    const response = await authActions.fetchProjectTypes();
    try {
      if (response.data) {
        setProjectTypes(response.data?.results);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRoomTypes = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchRoomTypes();
      console.log(response?.data);
      if (response.data) {
        setRoomTypes(response?.data);
        const masterBedroom = response.data?.filter(
          (item) => item?.title?.toLowerCase() === "master bedroom"
        )[0];
        console.log(masterBedroom);
        if (masterBedroom) {
          addSelectedRoomtype([masterBedroom]);
        }
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const fetchArchitectByUser = async () => {
    try {
      const response = await authActions.fetchArchitectByUser(user.id);
      if (response.data) {
        if (response.data?.results[0]) {
          setArchitectInfo(response.data?.results[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBrandByUser = async () => {
    try {
      const response = await authActions.fetchBrandByUser(user.id);
      if (response.data) {
        if (response.data?.results[0]) {
          setBrandInfo(response.data?.results[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const fetchProducts = async () => {
  //   try {
  //     const response = await authActions.fetchProducts();
  //     if (response.data) {
  //       setProducts(response.data?.results);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const addSelectedRoomtype = (types) => {
    setProcessing(true);
    if (selectedRoomtypes.length < types.length) {
      if (types?.length > 0) {
        const item = types[types.length - 1];
        const newRoomtype = {
          id: item.id,
          title: item.title,
          image: item.image,
          rooms: [],
        };
        setSelectedRoomtypes([...selectedRoomtypes, newRoomtype]);
      }
    } else {
      const rtypesIds = new Set(types.map((item) => item.id));
      console.log(selectedRoomtypes);
      // const temp = selectedRoomtypes.filter((item) => rtypesIds.has(item.id));
      const temp = [];
      for (const item of selectedRoomtypes) {
        if (rtypesIds.has(item.id)) {
          temp.push(item);
        }
      }
      console.log(rtypesIds, temp);
      setSelectedRoomtypes(temp);
    }
    setProcessing(false);
  };

  const onSelectRoomFiles = (files, index = roomtypeIndex) => {
    console.log(files);
    setProcessing(true);
    const currentFiles = new Set(
      selectedRoomtypes[index].rooms.map((item) => item.file)
    );

    const result = files.filter((file) => !currentFiles.has(file));
    const temp = result?.map((item) => {
      return { file: item, products: [] };
    });
    // console.log(selectedRoomtypes[index].rooms, temp);
    // console.log([...selectedRoomtypes[index].rooms, ...temp]);
    setSelectedRoomtypes([
      ...selectedRoomtypes.slice(0, index),
      {
        ...selectedRoomtypes[index],
        rooms: [...selectedRoomtypes[index].rooms, ...temp],
      },
      ...selectedRoomtypes.slice(index + 1),
    ]);
    setProcessing(false);
  };

  const onRemoveRoomFile = (index) => {
    const temp = [...selectedRoomtypes];

    const updatedRoomtype = {
      ...temp[roomtypeIndex],
      rooms: [
        ...temp[roomtypeIndex].rooms.slice(0, index),
        ...temp[roomtypeIndex].rooms.slice(index + 1),
      ],
    };

    const updatedRoomtypes = [
      ...temp.slice(0, roomtypeIndex),
      updatedRoomtype,
      ...temp.slice(roomtypeIndex + 1),
    ];

    setSelectedRoomtypes(updatedRoomtypes);
  };

  const handleGoToPreview = () => {
    if (selectedRoomtypes?.length > 0) {
      let allRoomsHaveProducts = true;
      selectedRoomtypes.forEach((item) => {
        if (item?.rooms.length > 0) {
          item?.rooms.forEach((r) => {
            if (r.products.length === 0) {
              allRoomsHaveProducts = false;
              notify(
                "At least one product should be selected for each room",
                "error"
              );
            }
          });
        } else {
          allRoomsHaveProducts = false;
          notify("Atleast one room is required in each room type", "error");
        }
      });
      if (allRoomsHaveProducts) {
        setCurrentStep(3);
      }
    } else {
      notify("Atleast one room type is required", "error");
    }
  };

  const handleFileButtonClick = (roomId) => {
    if (roomId) {
      document.getElementById(`fileInput-${roomId}`).click();
    } else {
      // document.getElementById("fileInput").click();
    }
  };

  const handleProjectDetails = () => {
    const formData = new FormData(projectInfoRef.current);
    formData.append("project_cost_minimum", rangeValue.min);
    formData.append("project_cost_maximum", rangeValue.max);
    let isError;
    let data = {};
    formData.forEach((value, key) => {
      if (!value) {
        isError = true;
      } else {
        data[key] = value;
      }
    });
    if (isError) {
      notify("Please enter in all the required fields!");
      return;
    }
    console.log(data);
    setProjectDetails(data);
    setCurrentStep(2);
  };

  const handleProjectSubmit = async () => {
    // const projectData = { project: projectDetails, rooms: rooms };
    console.log(projectDetails, projectDetails.project_cost_minimum);
    let data = {
      is_new_project: true,
      // architect: user.id,
      project_property: customProperty?.id
        ? customProperty.id
        : selectedProperty.id,
      project_name: projectDetails.project_name,
      project_type: projectDetails.project_property.project_type_data?.Id,
      project_description: projectDetails.project_description,
      bhk: projectDetails.bhk,
      block: projectDetails.block,
      project_area: projectDetails.project_area,
      project_year: projectDetails.project_year,
      // location: projectDetails.location,
      project_cost_minimum: parseFloat(projectDetails.project_cost_minimum),
      project_cost_maximum: parseFloat(projectDetails.project_cost_maximum),
    };
    console.log(data);
    if (architectInfo?.id) {
      data = { ...data, architect: architectInfo.id };
    } else if (brandInfo?.id) {
      data = { ...data, brand: brandInfo.id };
    }
    if (data?.architect || data?.brand) {
      console.log(data);
      const formData = new FormData();
      let count = 1;
      selectedRoomtypes.forEach((roomType, i) => {
        selectedRoomtypes[i]?.rooms.forEach((room, j) => {
          formData.append(`fileInput-${count}`, room.file);
          count += 1;
        });
      });
      formData.append("project_data", JSON.stringify(data));
      formData.append("room_types", JSON.stringify(selectedRoomtypes));
      formData.append("user", user.id);
      try {
        const response = await authActions.createProject(formData);
        if (response.status === 201) {
          notify("Project Created!", "success");
          navigate("/");
        }
      } catch (error) {
        notify(error.response.data.msg || "An error occured!", "error", 1);
      }
    } else {
      notify(`Failed to identify this ${user?.user_type ?? "user"}`, "error");
    }
  };

  // const handleInputChange = (layoutId, field, value) => {
  //   setLayouts(
  //     layouts.map((layout) =>
  //       layout.id === layoutId ? { ...layout, [field]: value } : layout
  //     )
  //   );
  // };
  // const joinLayoutString = () => {
  //   let formattedProperties = [];
  //   layouts.forEach((layout) =>
  //     formattedProperties.push(`${layout.layout} (${layout.area})`)
  //   );
  //   console.log(formattedProperties);
  //   return formattedProperties.join(", ");
  // };

  useEffect(() => {
    console.log(selectedRoomtypes);
    let temp = [];
    if (selectedRoomtypes?.length > 0) {
      selectedRoomtypes.forEach((rt) => {
        if (rt?.rooms.length > 0) {
          rt?.rooms.forEach((r) => {
            temp.push(r);
          });
        }
      });
    }
    setRoomsToDisplay(temp);
  }, [selectedRoomtypes]);

  useEffect(() => {
    if (status) {
      setCurrentStep(2);
    }
  }, [status]);

  // useEffect(() => {
  //   console.log("Project details: ", projectDetails);
  // }, [currentStep]);

  useEffect(() => {
    if (propertyId && properties?.length > 0) {
      const temp = {
        ...projectDetails,
        property: propertyId,
        // property: { ...projectDetails?.property, id: propertyId },
      };
      setProjectDetails(temp);
      setSelectedProperty(properties.find((item) => item?.id === propertyId));
    } else {
      setProjectDetails({});
    }
  }, [properties, propertyId]);

  useEffect(() => {
    if (user?.user_type === "architect") {
      fetchArchitectByUser();
    } else if (user?.user_type === "brand") {
      fetchBrandByUser();
    }
  }, [user]);

  useEffect(() => {
    fetchProperties();
    fetchProjectTypes();
    fetchRoomTypes();
    // fetchProducts();
  }, []);

  return (
    <>
      <AddProductsModal
        show={showProductsModal}
        setShow={setShowProductsModal}
        selectedRoomtypes={selectedRoomtypes}
        setSelectedRoomtypes={setSelectedRoomtypes}
        roomtypeIndex={roomtypeIndex}
        activeRoomIndex={activeRoomIndex}
        setActiveRoomIndex={setActiveRoomIndex}
      />
      <Container fluid className="mt-5 pt-4 px-0 d-flex flex-column justify-content-center align-items-center" style={{ minHeight: "60vh" }}>
        <div className="w-100 bg-info d-flex justify-content-center align-items-center">
          <h1 className="h2 text-primary geist-600 py-3">
            Add a new Project
          </h1>
        </div>
        {processing && (
          <LoadingOverlay isLoading={processing} setLoading={setProcessing} />
        )}
        <div className="d-flex flex-column flex-lg-row my-md-4" style={large ? { maxWidth: 1000, width: '100%' } : { width: '100%' }}>
          <div className="d-flex justify-content-center" style={{ minWidth: 250 }}>
            <div className="d-flex flex-lg-column mt-2 mt-md-4 mt-lg-3 ml-2 mb-1 px-3 px-lg-0">
              <div className="d-flex align-items-center mb-4 me-2">
                <div
                  className={`p-1 text-center rounded-btn ${currentStep === 1
                    ? "bg-black text-white"
                    : currentStep === 2 || currentStep === 3
                      ? "bg-success text-white"
                      : "bg-faint"
                    }`}
                >
                  {currentStep === 1 ? "1" : <FaCheck size={12} />}
                </div>
                <div
                  className={`ms-2 geist-500 body-text1 ${currentStep !== 1 ? "text-success" : ""
                    }`}
                >
                  Project Details
                </div>
              </div>
              <div className="d-flex align-items-center mb-4 me-2">
                <div
                  className={`p-1 text-center rounded-btn ${currentStep === 2
                    ? "bg-black text-white"
                    : currentStep === 3
                      ? "bg-success text-white"
                      : "text-gunmetal border"
                    }`}
                >
                  {currentStep === 1 || currentStep === 2 ? (
                    "2"
                  ) : (
                    <FaCheck size={12} />
                  )}
                </div>
                <div
                  className={`ms-2 geist-500 body-text1 ${currentStep !== 1 && currentStep !== 2 ? "text-success" : currentStep === 2 ? "" : "text-gunmetal"
                    }`}
                >
                  Project Images
                </div>
              </div>
              <div className="d-flex align-items-center mb-4 me-2">
                <div
                  className={`p-1 text-center rounded-btn ${currentStep === 3 ? "bg-black text-white" : "text-gunmetal border"
                    }`}
                >
                  3
                </div>
                <div className={`ms-2 geist-500 body-text1 ${currentStep === 3 ? "text-gray-900" : "text-gunmetal"}`}>Project Review</div>
              </div>
            </div>
          </div>
          <div className="p-3">
            <div className="p-3 border" style={{ borderRadius: 6 }}>
              {currentStep === 1 && (
                <div style={large ? { minWidth: 600 } : {}}>
                  <h4 className="fw-bold">{currentStep}. Project Details</h4>
                  <div className="mt-4">
                    <Form ref={projectInfoRef}>
                      <Form.Group className="mb-3">
                        <Form.Label className="mb-1 body-text2 geist-500">
                          Project Name<sup style={{ color: "red" }}>*</sup>
                        </Form.Label>
                        <Form.Control
                          required
                          className="py-2 body-text2"
                          type="text"
                          name="project_name"
                          defaultValue={projectDetails?.project_name}
                          style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="mb-1 body-text2 geist-500">
                          Property Name<sup style={{ color: "red" }}>*</sup>
                        </Form.Label>
                        {projectDetails && properties?.length > 0 ? (
                          <ReactSelect
                            name="project_property"
                            isMulti={false}
                            isDisabled={customProperty != null}
                            options={properties}
                            getOptionLabel={(e) => e.property_name}
                            getOptionValue={(e) => e.id}
                            defaultValue={
                              properties.find(
                                (e) => e.id === projectDetails?.property
                              )
                            }
                            onChange={(e) => {
                              if (e?.id) {
                                console.log(e);
                                setShowAddProperty(false);
                                setSelectedProperty(e);
                              } else {
                                setShowAddProperty(true);
                              }
                            }}
                            isClearable
                            placeholder="Select Property"
                            className="body-text2"
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                border: "1px solid #BDC1C6",
                                borderRadius: 3
                              }),
                            }}
                          />
                        ) : (
                          <div>
                            Please wait while we area loading the
                            properties...
                          </div>
                        )}
                        {customProperty != null && (
                          <div className="mt-2 body-text2 geist-500">
                            Property: {customProperty?.property_name}{" "}
                          </div>
                        )}
                        {showAddProperty && (
                          <div className="d-flex align-items-center body-text2 mt-2">
                            If your property is not listed in above options,
                            <div
                              className="cursor-pointer text-decoration-underline ms-2 text-primary"
                              onClick={() => setShowPropertyModal(true)}
                            >
                              Add Property
                            </div>
                          </div>
                        )}
                        <AddPropertyModal
                          show={showPropertyModal}
                          setShow={setShowPropertyModal}
                          setCustomProperty={setCustomProperty}
                          setShowAddProperty={setShowAddProperty}
                        />
                      </Form.Group>
                      {/* <Form.Group className="mb-3">
                        <Form.Label> Project Type<sup style={{ color: "red" }}>*</sup> </Form.Label>
                        <Form.SelectclassName="border-dark" placeholder="Enter location" name="project_type" defaultValue={projectDetails?.project_type}
                        >
                          <option value="">Select</option>
                          {projectTypes?.map((type, index) => (<option key={index} value={type.id}>{type.title}</option>))}
                        </Form.SelectclassName=>
                      </Form.Group> */}
                      <div>
                        <Form.Label className="mb-1 body-text2 geist-500">
                          Project Cost<sup style={{ color: "red" }}>*</sup>
                        </Form.Label>
                        <div className="d-flex body-text2 geist-500">
                          <div>
                            <span className="geist-600">Min: </span>
                            {formatNumber(rangeValue.min)}
                          </div>
                          <div className="ms-auto">
                            <span className="geist-600">Max: </span>
                            {formatNumber(rangeValue.max)}
                          </div>
                        </div>
                        <div className="ps-2">
                          <RangeSlider
                            min={0}
                            max={3000000}
                            step={1000}
                            value={rangeValue}
                            onChange={setRangeValue}
                          />
                        </div>
                      </div>
                      <div className="d-flex w-100">
                        <Form.Group className="mb-3 w-50">
                          <Form.Label className="mb-1 body-text2 geist-500">
                            Layout<sup style={{ color: "red" }}>*</sup>
                          </Form.Label>
                          <Form.Select
                            required
                            className="py-2 body-text2"
                            type="text"
                            placeholder="Select Layout"
                            name="bhk"
                            defaultValue={projectDetails?.bhk}
                            style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                          >
                            <option value="">Select</option>
                            {availableLayouts?.map((type, index) => (
                              <option key={index} value={type.name}>
                                {type.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                        <Form.Group className="ps-3 mb-3 w-50">
                          <Form.Label className="mb-1 body-text2 geist-500">
                            Project Year<sup style={{ color: "red" }}>*</sup>
                          </Form.Label>
                          <Form.Control
                            required
                            className="py-2 body-text2"
                            type="number"
                            name="project_year"
                            placeholder="2010"
                            defaultValue={projectDetails?.project_year}
                            style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                          />
                        </Form.Group>
                      </div>
                      <div className="d-flex w-100">
                        <Form.Group className="mb-3 w-50">
                          <Form.Label className="mb-1 body-text2 geist-500">
                            Project Area<sup style={{ color: "red" }}>*</sup>
                          </Form.Label>
                          <InputGroup style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}>
                            <Form.Control
                              required
                              className="py-2 body-text2 border-0"
                              type="number"
                              name="project_area"
                              placeholder="600"
                              defaultValue={projectDetails?.project_area}
                              style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                            />
                            <InputGroup.Text className="body-text2 border-0">
                              Sq Ft
                            </InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                        <Form.Group className="ps-3 mb-3 w-50">
                          <Form.Label className="mb-1 body-text2 geist-500">
                            Block<sup style={{ color: "red" }}>*</sup>
                          </Form.Label>
                          <Form.Control
                            required
                            className="py-2 body-text2"
                            type="text"
                            name="block"
                            placeholder="Block/Wing A"
                            defaultValue={projectDetails?.block}
                            style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                          />
                        </Form.Group>
                      </div>
                      <Form.Group className="mb-3">
                        <Form.Label className="mb-1 body-text2 geist-500">
                          Project Description<sup style={{ color: "red" }}>*</sup>
                        </Form.Label>
                        <Form.Control
                          required
                          className="py-2 body-text2"
                          as="textarea"
                          rows={6}
                          name="project_description"
                          placeholder="Describe the project..."
                          defaultValue={projectDetails?.project_description}
                          style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                        />
                      </Form.Group>
                    </Form>
                    <div className="d-flex">
                      <Button
                        className="d-flex align-items-center text-white body-text2 me-2"
                        style={{ borderRadius: 3 }}
                        onClick={handleProjectDetails}
                      >
                        Continue
                      </Button>
                      <Button
                        className="bg-transparent border-gray-200 text-gray-900 body-text2"
                        style={{ borderRadius: 3 }}
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {currentStep === 2 && (
                <div style={large ? { minWidth: 600 } : {}}>
                  <h4 className="fw-bold">{currentStep}. Add Project Images</h4>
                  {!loading ? (
                    <div style={{ minHeight: 300 }}>
                      <div className="my-3">
                        <ReactSelect
                          name="type_of_space"
                          defaultValue={[
                            roomTypes.filter(
                              (item) =>
                                item.title.toLowerCase() ===
                                "master bedroom"
                            )[0],
                          ]}
                          options={roomTypes}
                          isMulti
                          getOptionLabel={(e) => e.title}
                          getOptionValue={(e) => e.id}
                          onChange={(e) => addSelectedRoomtype(e)}
                          isClearable
                          placeholder="Select type of space"
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: '#BDC1C6',
                            }),
                          }}
                        />
                      </div>
                      <div className="d-flex align-items-start mb-3 flex-wrap" style={{ width: '100%' }}>
                        {selectedRoomtypes.length > 0 &&
                          selectedRoomtypes?.map((item, index) => {
                            return (
                              <div key={index}>
                                <Button
                                  className={`cursor-pointer text-center body-text2 geist-500 ${index == roomtypeIndex
                                    ? ""
                                    : "bg-white border-dark text-dark"
                                    } me-2`}
                                  style={{
                                    flexBasis: "calc(33.33% - 10px)",
                                    whiteSpace: "nowrap",
                                    marginBottom: 10,
                                    borderRadius: 3,
                                  }}
                                  onClick={() => setRoomTypeIndex(index)}
                                >
                                  {item.title} ({item.rooms.length})
                                </Button>
                              </div>
                            );
                          })}
                      </div>
                      {selectedRoomtypes.length > 0 &&
                        selectedRoomtypes?.map((item, index) => {
                          return (
                            <div key={index} className="mb-3">
                              <DropFileInput
                                allowMultiple
                                showFiles={false}
                                className={`bg-white border-dark ${index === roomtypeIndex
                                  ? "d-block"
                                  : "d-none"
                                  }`}
                                files={() =>
                                  item?.rooms?.map((item) => item.file)
                                }
                                onFileChange={(files) =>
                                  onSelectRoomFiles(files)
                                }
                              />
                            </div>
                          );
                        })}
                    </div>
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ minHeight: 240 }}
                    >
                      <Spinner />
                    </div>
                  )}
                  {selectedRoomtypes[roomtypeIndex]?.rooms.length > 0 && (
                    <div>
                      <Row
                        xs={2}
                        sm={2}
                        md={3}
                        lg={3}
                        xl={4}
                        className="pt-2 pb-2"
                      >
                        {selectedRoomtypes[roomtypeIndex]?.rooms?.map(
                          (item, index) => {
                            return (
                              <Col key={index} className="p-2 mb-2">
                                <div
                                  className="d-flex flex-column bg-white w-100 h-100 border border-grey position-relative"
                                  style={{ height: 160, borderRadius: 3 }}
                                >
                                  <div className="d-flex justify-content-center align-items-center flex-grow">
                                    {item?.file && (
                                      <Image
                                        src={item?.file ? URL.createObjectURL(item?.file) : null}
                                        alt=""
                                        className="img-fluid w-100"
                                        style={{ objectFit: "cover" }}
                                      />
                                    )}
                                  </div>
                                  <div
                                    className="cursor-pointer text-center text-primary text-decoration-underline body-text2 py-1"
                                    onClick={() => {
                                      setRoomTypeIndex(roomtypeIndex);
                                      setActiveRoomIndex(index);
                                      setShowProductsModal(!showProductsModal);
                                    }}
                                  >
                                    {item.products.length} Products Added
                                  </div>
                                  <div
                                    className="d-flex justify-content-center align-items-center rounded-circle overflow-hidden position-absolute"
                                    style={{ top: -16, right: -16, width: 40, height: 40 }}
                                  >
                                    <div
                                      className="d-flex justify-content-center align-items-center bg-white rounded-circle cursor-pointer"
                                      onClick={() =>
                                        onRemoveRoomFile(index)
                                      }
                                    >
                                      <FaCircleMinus className="text-primary" size={32} />
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            );
                          }
                        )}
                      </Row>
                    </div>
                  )}
                  <div className="mt-4 d-flex">
                    <Button
                      className="d-flex align-items-center text-white body-text2 me-2"
                      style={{ borderRadius: 3 }}
                      onClick={() => {
                        handleGoToPreview();
                      }}
                    >
                      Go to Preview
                    </Button>
                    <Button
                      className="bg-transparent border-gray-200 text-gray-900 body-text2"
                      style={{ borderRadius: 3 }}
                      onClick={() => setCurrentStep(1)}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              )}
              {currentStep === 3 && (
                <div style={large ? { width: 600 } : {}}>
                  <h4 className="fw-bold">{currentStep}. Project Preview</h4>
                  <div className="mb-4">
                    {!status && (
                      <div className="mb-4 body-text2">
                        <div className="d-flex justify-content-between">
                          <h4 className="fw-bold">Project Details</h4>
                          <div className="cursor-pointer" onClick={() => setCurrentStep(1)}>
                            <CiEdit size={20} />
                          </div>
                        </div>
                        <div className="my-1">Project Name: {projectDetails.project_name}</div>
                        <div className="my-1">Project Layout: {projectDetails.bhk}</div>
                        <div className="my-1">Project Year: {projectDetails.project_year}</div>
                        <div className="my-1">Flat Area: {projectDetails.project_area}</div>
                        {projectDetails?.project_property?.location &&
                          <div className="my-1">Location: {projectDetails.project_property.location}</div>
                        }
                        {projectDetails?.project_property.builder_data &&
                          <div className="my-1">Builder: {projectDetails.builder_data?.name ? projectDetails.builder_data?.name : "-"}</div>
                        }
                        <div className="my-1">Architect: {user.full_name}</div>
                        <div className="my-1">Project Description: {projectDetails?.project_description ? projectDetails?.project_description : "-"}</div>
                      </div>
                    )}
                    <Slider className="w-100" {...settings}>
                      {roomsToDisplay?.map((room, index) => {
                        return (
                          <div
                            key={index}
                            className="position-relative overflow-hidden"
                          >
                            <div
                              className="bg-gunmetal rounded p-2 position-absolute"
                              style={{ top: 16, left: 16 }}
                            >
                              {room?.products?.length} Products Added
                            </div>
                            <div className="w-100">
                              <img
                                key={index}
                                className="img-fluid w-100 rounded-3"
                                src={URL.createObjectURL(room.file)}
                                style={{
                                  height: xlarge ? 500 : large ? 500 : medium ? 400 : small ? 300 : 200
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                  <div className="d-flex">
                    <Button
                      className="d-flex align-items-center text-white body-text2 me-2"
                      style={{ borderRadius: 3 }}
                      onClick={handleProjectSubmit}
                    >
                      Save
                    </Button>
                    <Button
                      className="bg-transparent border-gray-200 text-gray-900 body-text2"
                      style={{ borderRadius: 3 }}
                      onClick={() => setCurrentStep(2)}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default AddProject;
