import React, { useEffect, useState } from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import Slider from "react-slick";
import { IoIosArrowDown } from "react-icons/io";
import ProjectCard from "./ProjectCard";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import * as authActions from "reduxFiles/actions/authActions";
import { BsThreeDots } from "react-icons/bs";
import { CiEdit } from "react-icons/ci";

function PreviewPropertyCard({
  property_name,
  builder,
  project_type,
  flats,
  layout_types,
  location,
  property_year,
  construction_status,
  property_img,
  setCurrentStep,
}) {
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  return (
    <Card className="p-0 d-flex flex-column text-start my-4 shadow-none border-0">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="fw-bold">Property Details</h4>
        <div className="cursor-pointer" onClick={() => setCurrentStep(1)}>
          <CiEdit size={20} />
        </div>
      </div>
      <Card.Body className="w-100 p-0 body-text2">
        <div className="my-1">Property Name: {property_name} ({builder})</div>
        <div className="my-1">Property Type: {project_type}</div>
        <div className="my-1">Number of Flats: {flats}</div>
        <div className="my-1">
          <span>Layout Types:</span>
          <ul className="m-0">
            {layout_types?.map((lay) => {
              return <li>{lay['layout']}, {lay['area']}</li>
            })}
          </ul>
        </div>
        <div className="my-1">Location: {location}</div>
        <div className="my-1">Property Year: {property_year}</div>
        <div className="my-1">Construction Status: {construction_status}</div>
      </Card.Body>
      <Image
        className="mt-3"
        src={property_img}
        style={{ height: 300, borderRadius: 6 }}
      />
    </Card>
  );
}

export default PreviewPropertyCard;
