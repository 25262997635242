import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Container,
  Spinner,
} from "react-bootstrap";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
  Navigate,
  useLocation,
} from "react-router-dom";
// import { Home } from "pages";
import { Navbar, Footer } from "components";

import axios from "axios";

import Home from "pages/Home/Home";
import PartnerSignup from "pages/SignUp/PartnerSignup";
import Signup from "pages/SignUp/Signup";

import Consultation from "pages/Consultaion/Consultation";

import ProjectList from "pages/Projects/ProjectList";
import WhyChooseUs from "pages/WhyChooseUs/WhyChooseUs";
import BrandsList from "pages/Professionals/BrandsList";
import SpacesList from "pages/Projects/SpacesList";
import ProjectDetails from "pages/Projects/ProjectDetails";
import BrandDetails from "pages/Professionals/BrandDetails";
import ProfessionalDetails from "pages/Professionals/ProfessionalDetails";
import LoginHomepage from "pages/Home/LoginHomepage";
import UserProfile from "pages/User/UserProfile";
import ProjectTracker from "pages/User/ProjectTracker";
import Library from "pages/User/Library";
import BuilderProperties from "pages/Builder/Property/BuilderProperties";
import AddProperty from "pages/Builder/Property/AddProperty";
import BuilderPersonalProfile from "pages/Builder/Account/PersonalProfile";
import ArchitectProjects from "pages/ProfessionalSpecific/ArchitectProjects";
import ArchitectPublicProfile from "pages/ProfessionalSpecific/ArchitectPublicProfile";
import ProductLibrary from "pages/ProfessionalSpecific/ProductLibrary";
import AddProject from "pages/ProfessionalSpecific/AddProject";
import BuilderDetails from "pages/Professionals/BuilderDetails";
import BuilderPublicProfile from "pages/Builder/Account/PublicProfile";
import ArchitectsList from "pages/Professionals/ArchitectsList";
import BuildersList from "pages/Professionals/BuildersList";
import ProfessionalsList from "pages/Professionals/ProfessionalsList";
import ProfessionalPersonalProfile from "pages/ProfessionalSpecific/ProfessionalPersonalProfile";
import PageNotFound from "pages/Errors/PageNotFound";
import AddProduct from "pages/ProfessionalSpecific/AddProducts";

// import { Breakpoint, useCurrentWidth } from "react-socks";
// import { ToastContainer } from "react-toastify";
// import { persistor } from "store";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Routers = () => {
  const token = useSelector((state) => state.auth.token);
  // const user = useSelector((state) => state.authReducer.user);
  // const isAuthenticated = useSelector(
  //   (state) => state.authReducer.isAuthenticated
  // );
  // const isAdmin = useSelector((state) => state?.authReducer?.user?.is_admin);
  // const isSuperuser = useSelector((state) => state?.authReducer?.user?.is_superuser);
  // const deviceWidth = useCurrentWidth();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);

  console.log("isAuthenticated, user:", isAuthenticated, user);

  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  if (token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
  } else {
    axios.defaults.headers.common["Authorization"] = null;
  }

  if (loading) {
    return (
      <Container
        fluid
        className="d-flex justify-content-center align-items-center position-fixed w-100 h-100"
      >
        <Spinner animation="border" style={{ color: "#555" }}>
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <Router>
      <ScrollToTop />
      <Container fluid className="px-0 poppins">
        <Navbar />
        <Routes>
          {isAuthenticated && user?.user_type === "customer" ? (
            <Route path="" element={<LoginHomepage />} />
          ) :
            user?.user_type === "builder" ? (
              <Route>
                <Route path="" element={<Navigate to="/builder/properties" />} />
                <Route path="builder" element={<Outlet />}>
                  <Route path="properties" element={<BuilderProperties />} />
                  <Route path="addproperty" element={<AddProperty />} />
                  <Route path="account" element={<Outlet />}>
                    <Route path="settings" element={<BuilderPersonalProfile />} />
                    <Route path="profile" element={<BuilderPublicProfile />} />
                  </Route>
                </Route>
              </Route>
            ) : user?.user_type === "architect" ? (
              <Route>
                <Route path="" element={<Navigate to="/architect/projects" />} />
                <Route path="architect" element={<Outlet />}>
                  <Route path="projects" element={<ArchitectProjects />} />
                  <Route path="addproject" element={<AddProject />}>
                    <Route path=":propertyId" element={<AddProject />}>
                      <Route path=":status" element={<AddProject />} />
                    </Route>
                  </Route>
                  <Route path="product-library" element={<ProductLibrary />} />
                  <Route path="account" element={<Outlet />}>
                    <Route
                      path="settings"
                      element={<ProfessionalPersonalProfile />}
                    />
                    <Route path="profile" element={<ArchitectPublicProfile />} />
                  </Route>
                </Route>
              </Route>
            ) : user?.user_type === "brand" ? (
              <Route>
                <Route path="" element={<Navigate to="/brand/projects" />} />
                <Route path="brand" element={<Outlet />}>
                  <Route path="projects" element={<ArchitectProjects />} />
                  <Route path="addproject" element={<AddProject />}>
                    <Route path=":propertyId" element={<AddProject />}>
                      <Route path=":status" element={<AddProject />} />
                    </Route>
                  </Route>
                  <Route path="addproduct" element={<AddProduct />} />
                  <Route path="product-library" element={<ProductLibrary />} />
                  <Route path="account" element={<Outlet />}>
                    <Route
                      path="settings"
                      element={<ProfessionalPersonalProfile />}
                    />
                    <Route path="profile" element={<ArchitectPublicProfile />} />
                  </Route>
                </Route>
              </Route>
            ) : (
              <Route path="" element={<Home />} />
            )}
          {isAuthenticated && (
            <Route path="user" element={<Outlet />}>
              {/* <Route path="" element={<LoginHomepage />} /> */}
              <Route path="profile" element={<UserProfile />} />
              <Route path="projecttracker" element={<ProjectTracker />} />
              <Route path="library" element={<Library />} />
            </Route>
          )}
          <Route path="partner-signup" element={<PartnerSignup />} />
          <Route path="signup" element={<Signup />} />
          <Route path="consultation" element={<Consultation />}>
            {/* <Route path="architect/:professionalId" element={<Consultation />} /> */}
            <Route path=":refType/:refId" element={<Consultation />} />
          </Route>
          <Route path="properties" element={<ProjectList />}>
            <Route path=":propertyId" element={<ProjectDetails />} />
          </Route>
          <Route path="projects" element={<ProjectList />}>
            <Route path=":slug" element={<ProjectDetails />} />
          </Route>
          <Route path="spaces" element={<SpacesList />} />
          <Route path="brands" element={<BrandsList />}>
            <Route path=":slug" element={<BrandDetails />} />
          </Route>
          <Route path="professionals" element={<Outlet />}>
            {/* <Route path="builders" element={<BuildersList />} /> */}
            <Route path="builders" element={<BuildersList />}>
              <Route path=":detailedSlug" element={<BuilderDetails />} />
            </Route>
            <Route path="architects" element={<ArchitectsList />}>
              <Route path=":detailedSlug" element={<ProfessionalDetails />} />
            </Route>
            <Route path=":slug" element={<ProfessionalsList />}>
              <Route path=":detailedSlug" element={<ProfessionalDetails />} />
            </Route>
          </Route>
          {/* <Route path="builders" element={<Outlet />}>
            <Route path=":builderId" element={<BuilderDetails />} />
          </Route> */}
          <Route path="/why-choose-us" element={<WhyChooseUs />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Container>
      <Footer />
    </Router>
  );
};

export default Routers;
