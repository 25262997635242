import { FaCheck, FaStar } from "react-icons/fa6";

const Review = ({ id, name, rating, type, content, image }) => {
  return (
    <div className="my-3 p-4" style={{ backgroundColor: "#FFFAFA" }}>
      <div className="d-flex mb-2">
        <div
          className="geist-700 me-3 bg-dark text-white"
          style={{
            fontSize: "30px",
            borderRadius: "50%",
            padding: "5px 18px",
          }}
        >
          {name}
        </div>
        <div>
          <div className="h4 geist-600"> {name}</div>
          <div className="d-flex align-items-center geist-600">
            {`Rating:  ${rating ?? 5} `}
            <FaStar color="#d95656" className="ms-1" />
          </div>
        </div>
      </div>
      <div className="text-primary geist-600 mb-2">{type}</div>
      <div className="geist mb-2">{content}</div>
      <div className="d-flex body-text3 geist-600">
        <div className="text-primary me-3">
          <FaCheck /> Helpful
        </div>
        <div>25 June 2023</div>
      </div>
    </div>
  );
};

export default Review;
