import { default_pfp } from "assets";
import AccountEditForm from "components/Forms/AccountEditForm";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";

const BuilderPersonalProfile = () => {
  const user = useSelector((state) => state.auth.user);

  return (
    <Container fluid className="mt-5 pt-4 px-0">
      <div className="d-flex flex-column bg-faint border-0">
        <Container className="d-flex align-items-center ps-5 py-4">
          <div>
            <img
              style={{
                border: "1px solid black",
                borderRadius: "50%",
                objectFit: "cover",
              }}
              width={150}
              height={150}
              src={user?.profile_picture || default_pfp}
              alt="avatar"
            />
          </div>
          <div className="geist-600 ms-5" style={{ fontSize: "30px" }}>
            {user?.full_name}
          </div>
        </Container>
      </div>
      <Container className="py-4">
        <AccountEditForm />
      </Container>
    </Container>
  );
};

export default BuilderPersonalProfile;
