import React from "react";
import { ad_1, ad_2 } from "assets";
import { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import ProfessionalCard from "components/Cards/ProfessionalCard";
import Pagination from "components/Pagination";
import * as authActions from "reduxFiles/actions/authActions";

const BuildersList = () => {
  const navigate = useNavigate();
  const { detailedSlug } = useParams();

  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const xxlarge = useMediaQuery({ query: "(min-width: 1400px)" });

  const [builders, setBuilders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchBuilders = async (page) => {
    setLoading(true);
    try {
      // const response = await authActions.fetchBuildersBySlug(userSubType, page);
      const response = await authActions.fetchBuildersPagewise(page);
      if (response.data) {
        setBuilders(response.data?.results);
        setTotalPages(Math.ceil(response.data.count / 12));
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchBuilders(currentPage);
  }, [currentPage]);

  if (detailedSlug) {
    return <Outlet />;
  }

  // useEffect(() => {
  //   if (detailedSlug) {
  //     return <Outlet />;
  //   }
  // }, [detailedSlug]);

  return (
    <Container className="page-padding">
      <section className="d-flex flex-column alignt-items-center">
        <div>
          <div className="h2 geist-600">Our Builders</div>
          <div className="geist">
            We have builders with 15+ years of experience and with 500+
            completed projects
          </div>
        </div>
        <Row className="mb-4">
          <Col md={8}>
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "50vh" }}
              >
                <Spinner />
              </div>
            ) : builders?.length > 0 ? (
              <div className="d-flex flex-column align-items-center w-100">
                {builders?.map((builder, index) => (
                  <ProfessionalCard
                    key={index}
                    {...builder}
                    type={"Builder"}
                    profile_img={builder.user_data?.profile_picture}
                    onClick={() =>
                      navigate(`/professionals/builders/${builder.slug}`)
                    }
                  />
                ))}
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center bg-faint rounded"
                style={{ height: 160 }}
              >
                No builders available
              </div>
            )}
          </Col>
          <Col md={4}>
            <div className="my-4">
              <img className="img-fluid" src={ad_1} alt="Ads" />
            </div>
            <div>
              <img className="img-fluid" src={ad_2} alt="Ads" />
            </div>
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default BuildersList;
