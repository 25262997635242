import { ad_1, ad_2 } from "assets";
import * as authActions from "reduxFiles/actions/authActions";
import BrandCard from "components/Cards/BrandCard";
import Pagination from "components/Pagination";
import { useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { Outlet, useParams } from "react-router-dom";

const BrandsList = () => {
  const { slug } = useParams();
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchBrands = async (page) => {
    setLoading(true);
    try {
      const response = await authActions.fetchBrandsPagewise(page);
      if (response.data) {
        setBrands(response.data);
        setTotalPages(Math.ceil(response.data.count / 12));
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchBrands(currentPage);
  }, [currentPage]);

  if (slug) {
    return <Outlet />;
  }

  return (
    <Container className="page-padding">
      <section className="d-flex flex-column alignt-items-center">
        <div>
          <div className="h2 geist-600">Our Brand Partners</div>
          <div className="geist">
            We only collaborate with the best brands for your interiors
          </div>
        </div>
        <Row>
          <Col lg={8} xl={9}>
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "50vh" }}
              >
                <Spinner />
              </div>
            ) : brands?.length > 0 ? (
              <div className="d-flex flex-column align-items-center w-100">
                {brands?.map((brand, index) => {
                  return (
                    <BrandCard
                      id={brand.user}
                      companyName={brand.company_name}
                      image={brand?.image}
                      // image={brand.user_data?.profile_picture}
                      brandCategory={brand?.brand_category_data?.name}
                      warranty={brand?.warranty}
                      rating={brand?.rating}
                      average_pricing={brand?.average_pricing}
                    />
                  );
                })}
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center bg-faint rounded"
                style={{ height: 160 }}
              >
                No brands available
              </div>
            )}
          </Col>
          <Col lg={4} xl={3}>
            <div className="my-4 position-relative">
              <div className="w-100">
                <img className="img-fluid w-100" src={ad_1} alt="Ads" />
              </div>
              <div className="position-absolute" style={{ top: 10, right: 10 }}>
                <Button variant="info" className="text-secondary fw-bold">
                  AD
                </Button>
              </div>
            </div>
            <div className="position-relative">
              <div className="w-100">
                <img className="img-fluid w-100" src={ad_1} alt="Ads" />
              </div>
              <div className="position-absolute" style={{ top: 10, right: 10 }}>
                <Button variant="info" className="text-secondary fw-bold">
                  AD
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default BrandsList;
