import { default_pfp } from "assets";
import * as authActions from "reduxFiles/actions/authActions";
import AccountEditForm from "components/Forms/AccountEditForm";
import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { LuCheck, LuPencil } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { userUpdate } from "reduxFiles/reducer/authSlices";
import { notify } from "utilities/notifications/Notify";

const UserProfile = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [profilePic, setProfilePic] = useState();
  const [isPfpEdit, setIsPfpEdit] = useState(false);

  const handleProfileImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfilePic(file);
    }
  };
  const handleButtonClick = () => {
    document.getElementById("profileUpload").click();
    setIsPfpEdit(true);
  };

  const handleProfilePicUpdate = async () => {
    const formData = new FormData();
    formData.append("profile_picture", profilePic);
    try {
      const response = await authActions.updateProfilePic(user.id, formData);
      if (response.data) {
        notify("Profile Picture Updated!", "success");
        setIsPfpEdit(false);
        dispatch(userUpdate(response.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container
      fluid
      className="page-padding px-0"
      style={{ minHeight: "100vh" }}
    >
      <div className="bg-faint border-bottom">
        <Container className="d-flex flex-column p-3 p-md-4">
          <Row className="d-flex align-items-center">
            <Col lg={{ order: 2, span: 3 }}>
              <div className="d-flex justify-content-center justify-content-md-end ms-auto position-relative mb-3 mb-md-0">
                <div className="d-flex justify-content-end">
                  <img
                    style={{
                      border: "1px solid black",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                    width={150}
                    height={150}
                    src={
                      profilePic
                        ? URL.createObjectURL(profilePic)
                        : user?.profile_picture || default_pfp
                    }
                    alt="avatar"
                  />
                  <input
                    type="file"
                    id="profileUpload"
                    style={{ display: "none" }}
                    onChange={handleProfileImageChange}
                  />
                  <div className="position-absolute bottom-0">
                    {isPfpEdit && profilePic ? (
                      <Button
                        variant="success"
                        className="d-flex align-items-center rounded-btn text-white"
                        style={{ bottom: "0px", right: "5px" }}
                        onClick={handleProfilePicUpdate}
                      >
                        <LuCheck />
                      </Button>
                    ) : (
                      <Button
                        className="position-absolute d-flex align-items-center rounded-btn text-white"
                        style={{ bottom: "0px", right: "5px" }}
                        onClick={handleButtonClick}
                      >
                        <LuPencil />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col
              lg={{ order: 1, span: 9 }}
              className="geist-600"
              style={{ fontSize: "30px" }}
            >
              <div className="text-center text-md-start">{user?.full_name}</div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="py-4">
        <AccountEditForm />
      </Container>
    </Container>
  );
};

export default UserProfile;
