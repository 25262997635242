import React, { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import Pagination from "components/Pagination";
import { ad_1 } from "assets";
import ListedPropertyCard from "components/Cards/ListedPropertyCard";
import * as authActions from "reduxFiles/actions/authActions";
import { FaBuildingColumns, FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

function ListedProperties({ companyName, properties, setProperties, setPropertyCount }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchProperties = async (page) => {
    try {
      setLoading(true);
      const response = await authActions.fetchProperties(page);
      if (response.data) {
        setProperties(response.data?.results);
        setPropertyCount(response.data.count);
        setTotalPages(Math.ceil(response.data.count / 12));
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const notInterestedAction = (index) => {
    const temp = [...properties];
    setProperties([
      ...temp.slice(0, index),
      ...temp.slice(index + 1),
      temp[index],
    ]);
  };

  useEffect(() => {
    fetchProperties(currentPage);
  }, [currentPage]);

  return (
    <Row>
      <Col lg={9}>
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "50vh" }}
          >
            <Spinner />
          </div>
        ) : (
          <>
            {properties.length > 0 ?
              <>
                {properties?.map((property, index) => (
                  <ListedPropertyCard
                    key={index}
                    index={index}
                    {...property}
                    builder={property?.builder_data?.company_name}
                    project_type={property?.project_type_data?.title}
                    notInterestedAction={notInterestedAction}
                  />
                ))}
                {(currentPage !== totalPages) && <div className="d-flex justify-content-center">
                  <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                  />
                </div>}
              </>
              :
              <div className="py-4 d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '70vh' }}>
                <div className="h2 geist-600">Welcome, {companyName}!</div>
                <div
                  className="d-flex justify-content-center align-items-center my-3"
                  style={{ backgroundColor: "#E8EAED", width: 100, height: 86, borderRadius: 6 }}
                >
                  <FaBuildingColumns size={30} />
                </div>
                <div className="h3 geist-600">There are no properties listed yet.</div>
                <div className="body-title text-gray-600">
                  Add your first custom project to start building an inspiration library.
                </div>
                <div className="mt-3">
                  <Button
                    className="d-flex align-items-center text-white"
                    style={{ borderRadius: 3 }}
                    onClick={() => navigate("/architect/addproject")}
                  >
                    <FaPlus className="me-2" /> Add Project
                  </Button>
                </div>
              </div>
            }
          </>
        )}
      </Col>
      <Col lg={3}>
        <div className="my-4 position-relative">
          <div className="w-100">
            <img className="img-fluid w-100" src={ad_1} alt="Ads" />
          </div>
          <div className="position-absolute" style={{ top: 10, right: 10 }}>
            <Button variant="info" className="text-secondary fw-bold">
              AD
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default ListedProperties;
