import FileLink from "components/FileLink";
import React, { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import { BiChevronUp, BiDislike, BiHeart } from "react-icons/bi";
import { FiDownload } from "react-icons/fi";
import { property_replacement1 } from "assets";
import { IoMdDocument } from "react-icons/io";
import { notify } from "utilities/notifications/Notify";
import axios from "axios";

function ListedPropertyCard({
  id,
  index,
  slug,
  property_name,
  property_img,
  flats,
  layout_types,
  project_type,
  location,
  builder,
  total_rooms,
  media_files,
  // recommended,
  // new_property,
  notInterestedAction,
}) {
  // const small = useMediaQuery({ query: "(max-width: 576px)" });
  const [display, setDisplay] = useState(false);
  const navigate = useNavigate();
  const [layoutData, setLayoutData] = useState([]);

  // const fetchResources = async (propertyId) => {
  //   const response = await authActions.fetchResourcesByProperty(propertyId);
  //   if (response.data) {
  //     setResources(response.data?.results);
  //     setDisplay(true);
  //   }
  // };

  const getFileName = (string) => {
    const parts = string.split("/");
    if (parts && parts?.length > 0) {
      return parts[parts.length - 1];
    } else {
      return "";
    }
  };

  const handleDownloadAllMedia = async () => {
    if (media_files && media_files?.length > 0) {
      try {
        const mediaIds = [];
        media_files.forEach((file) => mediaIds.push(file.id));
        const response = await axios.post(
          "/property/properties/download_media_zip/",
          { ids: mediaIds.join(",") },
          { responseType: "blob" }
        );
        if (response.data) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'media_files.zip');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      notify("Files not found, try again later");
    }
  };

  // const handleDownloadAllMedia = () => {
  //   const mediaIds = [];
  //   media_files.forEach((file) => mediaIds.push(file.id));
  //   const url = "/property/properties/download_media_zip/";

  //   fetch(url, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       // "Content-Type": "application/x-www-form-urlencoded", // or 'application/json'
  //       "X-CSRFToken": getCookie("csrftoken"), // Include CSRF token if necessary
  //     },
  //     body: new URLSearchParams({ ids: mediaIds.toString }),
  //   })
  //     .then((response) => response.blob()) // Response is in blob format for file download
  //     .then((blob) => {
  //       const link = document.createElement("a");
  //       link.href = window.URL.createObjectURL(blob);
  //       link.download = "media_files.zip";
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     })
  //     .catch((error) => console.error("Error:", error));
  // };

  // // Function to get CSRF token from cookies
  // const getCookie = (name) => {
  //   const cookieValue = null;
  //   if (document.cookie && document.cookie !== "") {
  //     const cookies = document.cookie.split(";");
  //     for (let i = 0; i < cookies.length; i++) {
  //       const cookie = cookies[i].trim();
  //       if (cookie.substring(0, name.length + 1) === name + "=") {
  //         return decodeURIComponent(cookie.substring(name.length + 1));
  //       }
  //     }
  //   }
  //   return cookieValue;
  // };

  return (
    <Card
      className="p-0 d-flex flex-column text-start my-4 shadow-none"
      style={{ border: "1px solid #E8EAED", minHeight: 200, borderRadius: 6 }}
    >
      <Row>
        <Col md={4} lg={5} xl={4} xxl={3} className="mb-2 mb-md-0" style={{ flexGrow: 1 }}>
          <Card.Img
            className="bg-faint border-0"
            variant="top"
            src={property_img ?? property_replacement1}
            style={{ height: 226, borderRadius: "6px 0px 0px 6px" }}
          />
        </Col>
        <Col md={8} lg={7} xl={8} xxl={9}>
          <Card.Body className="w-100 p-2 p-md-3">
            <div className="mb-2">
              <div className="d-flex flex-row justify-content-between">
                <Card.Title className="h4 geist-700">
                  {property_name} {builder && `/ ${builder}`}
                </Card.Title>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="d-flex justify-content-center align-items-center me-2" style={{ width: 35, height: 35, borderRadius: 17.5, backgroundColor: "#F1F3F4" }}>
                    <BiHeart size={18} />
                  </div>
                  <div
                    className="d-flex justify-content-center align-items-center" style={{ width: 35, height: 35, borderRadius: 17.5, backgroundColor: "#F1F3F4" }}
                    onClick={() => notInterestedAction(index)}
                  >
                    <BiDislike size={18} />
                  </div>
                </div>
              </div>
              {/* <Stack
                direction={!xsmall && "horizontal"}
                gap={2}
                className="mb-2"
              >
                {recommended && (
                  <Badge
                    bg="info text-primary"
                    className="d-flex align-items-center"
                    style={{ height: 30 }}
                  >
                    <CgScreenMirror size={20} className="me-1" />
                    <span className="body-text2"> Interiokeys Recommended</span>
                  </Badge>
                )}
                {new_property && (
                  <Badge
                    bg="info text-primary"
                    className="d-flex align-items-center"
                    style={{ height: 30 }}
                  >
                    <BsBuildingAdd size={18} className="me-1" />
                    <span className="body-text2"> New Property!</span>
                  </Badge>
                )}
              </Stack> */}
              <div className="body-text2 mb-2">
                <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                  <span className="">Number of Flats: {flats}</span>
                </Card.Text>
                <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                  <span className="">Layout Types: {layout_types ? layout_types : "-"}</span>
                </Card.Text>
                <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                  <span className="">Project Type: {project_type}</span>
                </Card.Text>
                <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                  <span className="">Location: {location}</span>
                </Card.Text>
                {total_rooms && (
                  <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                    <span className="">Total Inspirations Created: {total_rooms}</span>
                  </Card.Text>
                )}
              </div>
            </div>
            {!display ? (
              <div className="d-flex justify-content-end">
                <Button
                  className="text-white body-text2 mt-2"
                  style={{ borderRadius: 3 }}
                  onClick={() => setDisplay(!display)}
                >
                  View Project Details
                </Button>
              </div>
            ) : (
              <div className="d-flex justify-content-end">
                <Button
                  className="bg-transparent border-gray-200 text-gray-900 body-text2 mt-2"
                  style={{ borderRadius: 3 }}
                  onClick={() => {
                    setDisplay(false);
                  }}
                >
                  Hide Details
                  <BiChevronUp size={18} className="ms-1" />
                </Button>
              </div>
            )}
          </Card.Body>
        </Col>
      </Row>
      {
        display && (
          <div className="p-3">
            {/* <div>
            {resources?.map((resource, index) => (
              <Button key={index} className="text-white me-2" onClick={() => setLayoutData(resource.block_layout_data)}>
                Block-{resource.block}
              </Button>
            ))}
          </div> */}
            <div>
              {media_files?.length > 0 && (
                <div>
                  {media_files.map((file, index) => (
                    <div
                      key={index}
                      className="d-flex align-items-lg-center w-100 p-2 mt-2 mt-md-3 bg-gray rounded body-text2"
                    >
                      <IoMdDocument size={20} className="text-gray me-2" />
                      {getFileName(file.file)}
                    </div>
                  ))}
                </div>
              )}
            </div>
            {/* <div className="mt-3">
              {layoutData.map((layout, index) => (
                <FileLink
                  key={index}
                  fileName={`Layout: ${layout.layout}.png`}
                  fileType="zip"
                  fileUrl={layout.layout_file}
                />
              ))}
            </div> */}
            <div className="d-flex justify-content-end mt-3">
              {media_files?.length > 0 && (
                <Button
                  className="bg-transparent border-gray-200 text-gray-900 body-text2 me-2"
                  style={{ borderRadius: 3 }}
                  onClick={handleDownloadAllMedia}
                >
                  Download All Files <FiDownload size={18} className="ms-2" />
                </Button>
              )}
              <Button
                className="d-flex align-items-center text-white body-text2"
                style={{ borderRadius: 3 }}
                onClick={() => navigate(`/architect/addproject/${id}`)}
              >
                Create Inspiration
              </Button>
            </div>
          </div>
        )
      }
    </Card >
  );
}

export default ListedPropertyCard;
