import { project_only_service, turnkey_complete_service } from "assets";
import { Button, Card, Col, Row } from "react-bootstrap";
import { FaCheck, FaStar } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import { MdOutlineFileDownload } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const ServiceCard = () => {
  const navigate = useNavigate();
  return (
    <Row className="my-4 justify-content-around">
      <Col md={6} className="mb-md-0 px-4">
        <Card
          className="p-3 position-relative"
          style={{ border: "2px solid #E78282" }}
        >
          <div className="recommended-img-label">
            <FaStar size={20} className="me-2" /> Recommended
          </div>
          <img src={turnkey_complete_service} alt="Turnkey Service" />
          <div className="h4 geist-700 text-start mt-3">
            Turnkey (Complete) Interior Solution
          </div>
          <div className="geist-400 text-start">
            Complete hassle-free execution of your home interiors from ideation
            to handover, while maintaining transparency every step of the way.
          </div>
          <div className="text-start geist-600">
            <div className="d-flex align-items-center border-bottom py-2">
              <FaCheck className="me-2" color="#5DC067" />
              Step 1: Interior Inspiration
            </div>
            <div className="d-flex align-items-center border-bottom py-2">
              <FaCheck className="me-2" color="#5DC067" />
              Step 2: First Free Consultation
            </div>
            <div className="d-flex align-items-center border-bottom py-2">
              <FaCheck className="me-2" color="#5DC067" />
              Step 3: Architect Assistance
            </div>
            <div className="d-flex align-items-center border-bottom py-2">
              <FaCheck className="me-2" color="#5DC067" />
              Step 4: Product Details and Estimates
            </div>
            <div className="d-flex align-items-center border-bottom py-2">
              <FaCheck className="me-2" color="#5DC067" />
              Step 5: Customised Interior Drawings
            </div>
            <div className="py-2">
              <div className="d-flex align-items-center">
                <FaCheck className="me-2" color="#5DC067" />
                Step 6: Site Execution (Included)
              </div>
              <div className="geist-400 ms-4">
                <div>a. Material Supply</div>
                <div>b. Vendors and Suppliers</div>
                <div>c. Finishing and Handover</div>
              </div>
            </div>
          </div>
          <Button
            className="body-title geist-600 text-white mt-4 p-2"
            style={{ borderRadius: 3 }}
            onClick={() =>
              navigate("/consultation", { state: { servicetype: "turnkey" } })
            }
          >
            Book Free Consultation
          </Button>
        </Card>
      </Col>
      <Col md={6} className="px-4">
        <Card className="p-3 h-100">
          <img src={project_only_service} alt="Project Service" />
          <div className="h4 geist-700 text-start mt-3">
            Project Drawings Only
          </div>
          <div className="geist-400 text-start">
            Final construction drawings and architect support till release of
            final drawings. Project execution not included.
          </div>
          <div className="text-start geist-600">
            <div className="d-flex align-items-center border-bottom py-2">
              <FaCheck className="me-2" color="#5DC067" />
              Step 1: Interior Inspiration
            </div>
            <div className="d-flex align-items-center border-bottom py-2">
              <FaCheck className="me-2" color="#5DC067" />
              Step 2: First Free Consultation
            </div>
            <div className="d-flex align-items-center border-bottom py-2">
              <FaCheck className="me-2" color="#5DC067" />
              Step 3: Architect Assistance
            </div>
            <div className="d-flex align-items-center border-bottom py-2">
              <FaCheck className="me-2" color="#5DC067" />
              Step 4: Product Details and Estimates
            </div>
            <div className="d-flex align-items-center border-bottom py-2">
              <FaCheck className="me-2" color="#5DC067" />
              Step 5: Customised Interior Drawings
            </div>
            <div className="d-flex align-items-center py-2">
              <FaXmark className="me-2" color="#F04438" size={20} />
              Step 6: Site Execution{"     "}
              <span style={{ color: "#F04438", marginLeft: "5px" }}>
                {" "}
                (Not Included)
              </span>
            </div>
          </div>
          <Row className="justify-content-between text-white mt-4 mt-auto">
            <Col md={6} className="mb-3 mb-lg-0">
              <Button
                variant="info"
                className="body-title w-100 geist-600 text-secondary p-2"
                style={{ borderRadius: 3 }}
                onClick={() =>
                  navigate("/consultation", {
                    state: { servicetype: "customised" },
                  })
                }
              >
                Book Free Consultation
              </Button>
            </Col>
            <Col md={6}>
              <Button
                className="body-title bg-transparent text-gray-600 w-100 geist-600 p-2"
                style={{ borderRadius: 3, border: "1px solid #D7DFE9" }}
              >
                <MdOutlineFileDownload size={24} /> Sample Estimate and Plans
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default ServiceCard;
