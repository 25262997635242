import {
  nav_architects,
  nav_builders,
  nav_interior_desgn,
  nav_kitchen_desgn,
} from "assets";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const ProfessionalMegaMenu = ({ professionalTypes }) => {
  const navigate = useNavigate();

  return (
    <div className="body-text2 geist pt-2" style={{ padding: "0px 50px 30px" }}>
      <div className="d-flex px-0 align-items-center geist-600 mb-2 nav-link">
        <a className="nav-link geist-600 fw-bold p-0" href="/professionals">
          All Professionals <MdKeyboardArrowRight size={24} />
        </a>
      </div>
      <div className="d-flex overflow-scroll scrollbar-none">
        {professionalTypes?.map((item, index) => {
          return (
            <div
              key={index}
              className="d-flex flex-column align-items-center cursor-pointer me-4"
              onClick={() => navigate(`/professionals/${item.slug}`)}
            >
              <img src={nav_architects} alt="" />
              <div className="body-text3 nav-link">{item.title}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProfessionalMegaMenu;
