import { Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ConsultaionCard = () => {
  const navigate = useNavigate();
  return (
    <Card className="w-100">
      <Card.Body className="d-flex flex-column justify-content-between">
        <Card.Title className="h3 geist-700" style={{ color: "#344054" }}>
          Looking for an architect to make your dream home into a reality?
        </Card.Title>
        <div>
          <Button
            className="mt-auto text-white w-100"
            onClick={() => navigate("/consultation")}
          >
            Book Free Consultation
          </Button>
          <div className="body-text3 text-center">
            We currently provide services only in{" "}
            <span className="geist-700">Jaipur, Rajasthan</span>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ConsultaionCard;
