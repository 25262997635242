import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

const InteriorRequirement = ({
  setRequirement,
  floorPlan,
  setFloorPlan,
  setSelectedRoomType,
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [checkboxes, setCheckBoxes] = useState([]);

  // fetch rooms from backend if needed
  let checkboxesData = [
    { id: 1, label: "Bedroom", value: "bedroom", isChecked: false },
    { id: 2, label: "Bathrooms", value: "bathrooms", isChecked: false },
    { id: 3, label: "kitchen", value: "kitchen", isChecked: false },
    { id: 4, label: "Dining room", value: "diningroom", isChecked: false },
    { id: 5, label: "Living room", value: "livingroom", isChecked: false },
  ];

  const handleCheckboxChange = (value) => {
    let updatedCheckboxes = checkboxes.map((checkbox) =>
      checkbox.value === value
        ? { ...checkbox, isChecked: !checkbox.isChecked }
        : checkbox
    );
    setCheckBoxes(updatedCheckboxes);
    let selectedValues = updatedCheckboxes
      .filter((checkbox) => checkbox.isChecked)
      .map((checkbox) => checkbox.value);
    console.log(selectedValues);
    setSelectedRoomType(selectedValues);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setRequirement(event.target.value);
  };

  useEffect(() => {
    setCheckBoxes(checkboxesData);
  }, []);

  return (
    <div>
      <div className="mb-3">
        <div className="geist-600 mb-2">Your Requirement</div>
        <div className="bg-gray p-2 rounded geist-600">
          <Form.Check
            type="radio"
            label={`Complete Home Interiors`}
            name="requirement"
            value="complete_home"
            onClick={handleOptionChange}
          />
        </div>
        <div className="bg-gray p-2 rounded-top geist-600 mt-2">
          <Form.Check
            type="radio"
            label={`Only Selected Rooms`}
            name="requirement"
            value="selected_rooms"
            onClick={handleOptionChange}
          />
        </div>
        {selectedOption === "selected_rooms" && (
          <div className="geist-400 text-gray-900 bg-light p-3">
            {checkboxes.map((checkbox, index) => (
              <div key={index} className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={checkbox.value}
                  id={checkbox.value}
                  checked={checkbox.isChecked}
                  onChange={(e) => handleCheckboxChange(checkbox.value)}
                />
                <label className="form-check-label" for={checkbox.value}>
                  {checkbox.label}
                </label>
              </div>
            ))}
          </div>
        )}
      </div>
      <div>
        <div className="geist-600 mb-2">Your Floor Plan</div>
        <Row className="mb-3">
          <Col xs={6} sm={4} className="mb-2 px-2">
            <Button
              className={`w-100 ${
                floorPlan == "2BHK" ? "bg-primary" : "bg-gray"
              }`}
              onClick={() => setFloorPlan("2BHK")}
            >
              2 BHK
            </Button>
          </Col>
          <Col xs={6} sm={4} className="mb-2 px-2">
            <Button
              className={`w-100 ${
                floorPlan == "2.5BHK" ? "bg-primary" : "bg-gray"
              }`}
              onClick={() => setFloorPlan("2.5BHK")}
            >
              2.5 BHK
            </Button>
          </Col>
          <Col xs={6} sm={4} className="mb-2 px-2">
            <Button
              className={`w-100 ${
                floorPlan == "3BHK" ? "bg-primary" : "bg-gray"
              }`}
              onClick={() => setFloorPlan("3BHK")}
            >
              3 BHK
            </Button>
          </Col>
          <Col xs={6} sm={4} className="mb-2 px-2">
            <Button
              className={`w-100 ${
                floorPlan == "3.5BHK" ? "bg-primary" : "bg-gray"
              }`}
              onClick={() => setFloorPlan("3.5BHK")}
            >
              3.5 BHK
            </Button>
          </Col>
          <Col xs={6} sm={4} className="mb-2 px-2">
            <Button
              className={`w-100 ${
                floorPlan == "4BHK" ? "bg-primary" : "bg-gray"
              }`}
              onClick={() => setFloorPlan("4BHK")}
            >
              4 BHK
            </Button>
          </Col>
          <Col xs={6} sm={4} className="mb-2 px-2">
            <Button
              className={`w-100 ${
                floorPlan == "5BHK" ? "bg-primary" : "bg-gray"
              }`}
              onClick={() => setFloorPlan("5BHK")}
            >
              5 BHK
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default InteriorRequirement;
