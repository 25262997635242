import { ad_1, ad_2 } from "assets";
import * as authActions from "reduxFiles/actions/authActions";
import ProfessionalCard from "components/Cards/ProfessionalCard";
import Pagination from "components/Pagination";
import { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const ProfessionalsList = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const { detailedSlug } = useParams();
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const userSubType = queryParams.get("type");

  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const xxlarge = useMediaQuery({ query: "(min-width: 1400px)" });

  const [professionals, setProfessionals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchArchitectsByTypeSlug = async (page) => {
    setLoading(true);
    try {
      const response = await authActions.fetchArchitectsByTypeSlug(slug, page);
      if (response.data) {
        setProfessionals(response.data?.results);
        setTotalPages(Math.ceil(response.data.count / 12));
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchProfessionals = async (page) => {
    setLoading(true);
    try {
      const response = await authActions.fetchProfessionalsPagewise(page);
      if (response.data) {
        setProfessionals(response.data?.results);
        setTotalPages(Math.ceil(response.data.count / 12));
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (slug) {
      console.log(slug, currentPage);
      fetchArchitectsByTypeSlug(currentPage);
    } else {
      fetchProfessionals(currentPage);
    }
  }, [currentPage, slug]);

  if (detailedSlug) {
    return <Outlet />;
  }

  return (
    <Container className="page-padding">
      <section className="d-flex flex-column alignt-items-center">
        <div>
          <div className="h2 geist-600 text-capitalize">
            Our {slug?.split("-").join(" ")}
            {/* Our {userSubType.split("-").join(" ")} */}
          </div>
          <div className="geist">
            We have professionals with 15+ years of experience and with 500+
            completed projects
          </div>
        </div>
        <Row>
          <Col md={8}>
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "50vh" }}
              >
                <Spinner />
              </div>
            ) : professionals?.length > 0 ? (
              <div className="d-flex flex-column align-items-center w-100">
                {professionals?.map((architect, index) => (
                  <ProfessionalCard
                    key={index}
                    {...architect}
                    type={"Architect"}
                    profile_img={architect.user_data?.profile_picture}
                    onClick={() =>
                      navigate(`/professionals/${slug}/${architect.slug}`)
                    }
                  />
                ))}
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center bg-faint rounded my-3"
                style={{ height: 160 }}
              >
                {slug
                  ? `No ${slug?.split("-").join(" ")} availble`
                  : "No data available"}
              </div>
            )}
          </Col>
          <Col md={4}>
            <div className="my-4">
              <img className="img-fluid" src={ad_1} alt="Ads" />
            </div>
            <div>
              <img className="img-fluid" src={ad_2} alt="Ads" />
            </div>
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default ProfessionalsList;
