import { useEffect, useState } from "react";
import SpaceCard from "components/Cards/SpaceCard";
import { Card, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { room_switch_card } from "assets";
import ProjectCategoryCard from "components/Cards/ProjectCategoryCard";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SpacesSliderView from "./SpacesSliderView";
import Slider from "react-slick";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import * as authActions from "reduxFiles/actions/authActions";
import Pagination from "components/Pagination";

const SpacesList = () => {
  const navigate = useNavigate();
  // const { slug } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const categorySlug = queryParams.get("room-caterogry");
  const roomTypeSlug = queryParams.get("room-type");

  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const xxlarge = useMediaQuery({ query: "(min-width: 1400px)" });

  const [layout, setLayout] = useState("");
  const [theme, setTheme] = useState("");
  const [budget, setBudget] = useState(1000000);
  const [status, setStatus] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [rooms, setRooms] = useState([]);
  const [roomData, setRoomData] = useState(null);
  const [roomTypes, setRoomTypes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [showSpaceView, setShowSpaceView] = useState(false);
  const [activeSpaceIndex, setActiveSpaceIndex] = useState(0);
  const [modalData, setModalData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchSpaces = async (page) => {
    setLoading(true);
    const response = await authActions.fetchRooms(
      page,
      layout,
      budget,
      theme,
      status,
      sortBy
    );
    try {
      if (response.data) {
        setRooms(response.data?.results);
        setTotalPages(Math.ceil(response.data.count / 12));
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchRoomsByCateogrySlug = async (page) => {
    setLoading(true);
    const response = await authActions.fetchRoomsByCategorySlug(
      roomTypeSlug,
      page
    );
    console.log(response.data);
    try {
      if (response.data) {
        setRooms(response.data?.results);
        setTotalPages(Math.ceil(response.data.count / 12));
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchRoomsByRoomTypeSlug = async (page) => {
    setLoading(true);
    const response = await authActions.fetchRoomsByRoomTypeSlug(
      roomTypeSlug,
      page
    );
    console.log(response.data);
    try {
      if (response.data) {
        setRooms(response.data?.results);
        setTotalPages(Math.ceil(response.data.count / 12));
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchRoomTypes = async (page) => {
    // setLoading(true);
    try {
      const response = await authActions.fetchRoomTypes();
      if (response.data) {
        setRoomTypes(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    console.log(sortBy);
    if (layout || budget || theme || status || sortBy) {
      fetchSpaces();
    }
  }, [layout, budget, theme, status, sortBy]);

  useEffect(() => {
    if (categorySlug) {
      fetchRoomsByCateogrySlug(currentPage);
    } else if (roomTypeSlug) {
      fetchRoomsByRoomTypeSlug(currentPage);
    } else {
      fetchSpaces(currentPage);
    }
  }, [currentPage, categorySlug, roomTypeSlug]);

  useEffect(() => {
    fetchRoomTypes();
  }, []);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + " custom-next-arrow"}
        style={{ ...style, display: "flex", right: -25, zIndex: 1 }}
        onClick={onClick}
      >
        <MdKeyboardArrowRight size={30} />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + " custom-prev-arrow"}
        style={{ ...style, display: "flex", left: -25, zIndex: 1 }}
        onClick={onClick}
      >
        <MdKeyboardArrowLeft size={30} />
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 200,
    slidesToShow: xxlarge
      ? 8.5
      : xlarge
      ? 7
      : large
      ? 6
      : medium
      ? 4
      : small
      ? 2.5
      : 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <Container className="mt-5 pt-5">
      <div className="d-flex flex-column">
        <div className="body-title mb-3">
          <span className="geist-700">{rooms?.length} real projects</span> found
          in your city
        </div>
        <div className="d-flex mb-4">
          <div className="mb-3 mb-lg-0">
            {/* <div className={`${medium ? "border-end border-2" : ""} pe-3`}> */}
            <div className="pe-3">
              <Card
                className="border-0 p-0 text-start bg-primary text-white"
                style={{ width: "130px", cursor: "pointer" }}
                onClick={() => navigate("/projects")}
              >
                <Card.Img
                  className="img-fluid"
                  vadivriant="top"
                  src={room_switch_card}
                  style={{ maxHeight: "100px", minHeight: "100px" }}
                />
                <Card.Body className="px-0 py-2 text-center">
                  <Card.Title className="body-text2 m-0">Rooms</Card.Title>
                </Card.Body>
              </Card>
            </div>
          </div>
          {roomTypes?.length > 0 && (
            <div
              className={`${
                large ? "w-90" : medium ? "w-80" : small ? "w-80" : "w-50"
              } ps-3`}
            >
              <Slider {...settings}>
                {roomTypes?.map((room, index) => (
                  <ProjectCategoryCard
                    key={index}
                    {...room}
                    onClick={() => navigate(`/spaces?room-type=${room.slug}`)}
                  />
                ))}
              </Slider>
            </div>
          )}
        </div>
        <Row>
          <Col lg={8} xl={6}>
            <Row>
              <Col xs={6} md={3} className="mb-2">
                <Form.Select
                  defaultValue=""
                  className="bg-light"
                  onChange={(e) => setLayout(e.target.value)}
                >
                  <option value="" disabled>
                    Layout
                  </option>
                  <option value="2BHK">2 BHK</option>
                  <option value="2.5BHK">2.5 BHK</option>
                  <option value="3BHK">3 BHK</option>
                  <option value="3.5BHK">3.5 BHK</option>
                  <option value="4BHK">4 BHK</option>
                  <option value="5BHK">5 BHK</option>
                </Form.Select>
              </Col>
              <Col xs={6} md={3} className="mb-2">
                <Form.Select
                  defaultValue=""
                  className="bg-light"
                  // onChange={(e) => setTheme(e.target.value)}
                >
                  <option value="" disabled>
                    Theme
                  </option>
                  <option value="modern">Modern</option>
                  <option value="contemporary">Contemporary</option>
                  <option value="traditional">Traditional</option>
                  <option value="neo_classical">Neo-Classical</option>
                  <option value="eclectic">Eclectic</option>
                  <option value="tropical">Tropical</option>
                  <option value="mediterranean">Mediterranean</option>
                </Form.Select>
              </Col>
              <Col md={6} className="mb-2">
                <Form.Group className="d-flex align-items-center px-2 pt-1 bg-light border rounded">
                  <Form.Label className="me-2">{`Budget: Rs.${budget} `}</Form.Label>
                  {/* <Form.Range /> */}
                  <div className="">
                    <input
                      type="range"
                      min="0"
                      max="1000000"
                      step={500}
                      value={budget}
                      onChange={(event) => setBudget(event.target.value)}
                      // className="w-100"
                      style={{ color: "#d95656" }}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col lg={4} xl={6}>
            <Row className="justify-content-lg-end">
              <Col xs={6} md={3} lg={4} className="mb-2">
                <Form.Select
                  defaultValue={status}
                  className="bg-light"
                  // onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="" disabled>
                    Status
                  </option>
                  <option value="completed">Complete</option>
                  <option value="ongoing">Ongoing</option>
                  <option value="upcoming">Upcoming</option>
                </Form.Select>
              </Col>
              <Col xs={6} md={3} lg={4} className="mb-2">
                <Form.Select
                  defaultValue={sortBy}
                  className="bg-light"
                  onChange={(e) => setSortBy(e.target.value)}
                >
                  <option value="" disabled>
                    Sort By
                  </option>
                  {/* <option value="popularity">Popularity (High to Low)</option>
                  <option value="cost_high_low">Cost (High to Low)</option>
                  <option value="cost_low_high">Cost (Low to High)</option> */}
                  <option value="created_at">Featured (New to Old)</option>
                  <option value="-created_at">Featured (Old to New)</option>
                </Form.Select>
              </Col>
            </Row>
          </Col>
        </Row>
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "50vh" }}
          >
            <Spinner />
          </div>
        ) : rooms?.length > 0 ? (
          <div>
            <Row>
              {rooms?.map((room, index) => (
                <Col
                  key={index}
                  lg={4}
                  md={6}
                  sm={6}
                  className="d-flex justify-content-around my-3"
                >
                  <SpaceCard
                    {...room?.room_data}
                    id={room.id}
                    projectId={room.project}
                    project={room.project_data}
                    image={room.media[0]?.file}
                    title={`${room.room_type_data?.title}/${room.project_data?.property_name}`}
                    // type={`${room.project_data.bhk}, Block ${room.project_data.block}, ${room.project_data.project_area}`}
                    type={`${room.project_data.bhk}, ${room.project_data.project_area}`}
                    setShow={setShowSpaceView}
                    setRoomData={setRoomData}
                    onClick={() => {
                      console.log(room);
                      setRoomData({
                        id: room.id,
                        projectId: room.project,
                        roomImage: room?.media[0]?.file,
                      });
                      setShowSpaceView(true);
                    }}
                  />
                </Col>
              ))}
            </Row>
            <SpacesSliderView
              ativeRoomIndex={activeSpaceIndex}
              setActiveRoomIndex={setActiveSpaceIndex}
              show={showSpaceView}
              setShow={setShowSpaceView}
              roomData={roomData}
            />
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center bg-faint rounded"
            style={{ height: 160 }}
          >
            {categorySlug
              ? "No spaces available for this room category"
              : roomTypeSlug
              ? "No spaces available for this room type"
              : "No spaces available"}
          </div>
        )}
      </div>
      <div className="d-flex justify-content-center">
        <Pagination
          // itemCount={itemCount}
          currentPage={currentPage}
          totalPages={totalPages}
          setTotalPages={setTotalPages}
          onPageChange={handlePageChange}
          // nextUrl={nextUrl}
          // previousUrl={previousUrl}
          // fetchProperties={fetchProperties}
        />
      </div>
    </Container>
  );
};

export default SpacesList;
